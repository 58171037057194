/*
 * Copyright (C) 2020 Graylog, Inc.
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the Server Side Public License, version 1,
 * as published by MongoDB, Inc.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * Server Side Public License for more details.
 *
 * You should have received a copy of the Server Side Public License
 * along with this program. If not, see
 * <http://www.mongodb.com/licensing/server-side-public-license>.
 */
import * as React from 'react';
import { useCallback, useRef } from 'react';
import { union } from 'lodash';
import { useFormikContext } from 'formik';
import type * as Immutable from 'immutable';

import ParameterDeclarationForm from 'enterprise/parameters/components/ParameterDeclarationForm';
import { useStore } from 'stores/connect';
import { SearchMetadataStore } from 'views/stores/SearchMetadataStore';
import { Button } from 'components/bootstrap';
import type { QueryValidationState } from 'views/components/searchbar/queryvalidation/types';
import createParametersFromNames from 'enterprise/parameters/components/CreateParametersFromNames';
import type Parameter from 'views/logic/parameters/Parameter';

import { SearchParameterStore, SearchParameterActions } from '../stores/SearchParameterStore';

type Props = {
  validationState?: QueryValidationState;
};

const QueryValidationParameterDeclaration = ({ validationState }: Props) => {
  const existingParameters = useStore(SearchParameterStore);
  const { undeclared: undeclaredParameterNamesFromSearch } = useStore(SearchMetadataStore);
  const declarationFormRef = useRef<ParameterDeclarationForm | undefined>();

  const parameterValidationErrors = validationState?.explanations.filter((explanation) => explanation.errorType === 'UNDECLARED_PARAMETER');
  const undeclaredParameterNamesFromValidation = parameterValidationErrors?.flatMap(({ relatedProperty }) => relatedProperty).filter((paramName) => !!paramName);
  const undeclaredParameterNames = union(undeclaredParameterNamesFromSearch.toArray(), undeclaredParameterNamesFromValidation);

  const { submitForm } = useFormikContext();
  const onSaveParameters = useCallback((parameters: Immutable.Map<string, Parameter>) => {
    return SearchParameterActions.declare(parameters)
      .then(() => submitForm());
  }, [submitForm]);

  const openParameterDeclarationForm = () => {
    if (declarationFormRef.current) {
      declarationFormRef.current.open();
    }
  };

  if (!undeclaredParameterNames?.length) {
    return null;
  }

  const undeclaredParameters = createParametersFromNames(undeclaredParameterNames);

  return (
    <>
      <Button bsStyle="primary" bsSize="small" onClick={openParameterDeclarationForm}>Declare parameters</Button>
      <ParameterDeclarationForm ref={declarationFormRef}
                                existingParameters={existingParameters}
                                parameters={undeclaredParameters}
                                onSave={onSaveParameters} />
    </>
  );
};

QueryValidationParameterDeclaration.defaultProps = {
  validationState: undefined,
};

export default QueryValidationParameterDeclaration;
