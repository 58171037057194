import * as React from 'react';
import * as Immutable from 'immutable';
import * as PropTypes from 'prop-types';
import { ReadOnlyFormGroup } from 'components/common';
import { Alert } from 'components/bootstrap';
import Role from 'logic/roles/Role';

import GroupSyncBackend from '../../logic/directoryServices/GroupSyncBackend';
import LicenseCheck from '../../../license/LicenseCheck';

const getRolesList = (roles: Immutable.List<Role>, defaultRolesIds: Immutable.Set<string>) => {
  if (!defaultRolesIds || defaultRolesIds.size === 0) {
    return null;
  }

  const defaultRolesNames = defaultRolesIds.map((roleId) => roles.find((role) => role.id === roleId)?.name);

  return defaultRolesNames.join(', ');
};

const _readableSelectionType = (selectionType) => {
  switch (selectionType) {
    case 'all':
      return 'All matching groups';
    case 'include':
      return 'Include selected groups';
    case 'exclude':
      return 'Exclude selected groups';
    default:
      return selectionType;
  }
};

type Props = {
  excludedFields: {[ inputName: string ]: boolean },
  groupSyncBackend: GroupSyncBackend | null | undefined,
  roles: Immutable.List<Role>,
};

const GroupSyncDetails = ({ groupSyncBackend, roles, excludedFields }: Props) => {
  if (!groupSyncBackend) {
    return (
      <Alert>
        Group synchronization not configured for this authentication service.
      </Alert>
    );
  }

  const {
    defaultRoles,
    selectionType,
    selection = Immutable.Set(),
    config: {
      groupSearchBase,
      groupSearchPattern,
      teamNameAttribute,
      teamUniqueIdAttribute,
    },
  } = groupSyncBackend;
  const rolesList = (defaultRoles && defaultRoles.size >= 1) ? getRolesList(roles, defaultRoles) : null;

  return (
    <LicenseCheck>
      <ReadOnlyFormGroup label="Group Search Base DN" value={groupSearchBase} />
      <ReadOnlyFormGroup label="Group Search Pattern" value={groupSearchPattern} />
      <ReadOnlyFormGroup label="Team Name Attribute" value={teamNameAttribute} />
      {!excludedFields.teamUniqueIdAttribute && (
        <ReadOnlyFormGroup label="Team Id Attribute" value={teamUniqueIdAttribute} />
      )}
      <ReadOnlyFormGroup label="Team Default Roles" value={rolesList} />
      <ReadOnlyFormGroup label="Selection Type" value={_readableSelectionType(selectionType)} />
      {selectionType !== 'all' && (
        <ReadOnlyFormGroup label="Selected Groups" value={`${selection.size} group(s)`} />
      )}
    </LicenseCheck>
  );
};

GroupSyncDetails.defaultProps = {
  excludedFields: {},
};

GroupSyncDetails.propTypes = {
  excludedFields: PropTypes.object,
};

export default GroupSyncDetails;
