import Reflux from 'reflux';
import fetch from 'logic/rest/FetchProvider';
import { singletonStore } from 'logic/singleton';
import { qualifyUrl } from 'util/URLUtils';
import { Store } from 'stores/StoreTypes';

import FailureActions, { FailureConfig, FailureOverview } from '../actions/FailureActions';

const FailureStore: Store<void> = singletonStore('FailureProcessing', () => Reflux.createStore({
  listenables: [FailureActions],

  updateFailureConfig(config: FailureConfig): Promise<void> {
    const url = qualifyUrl('/plugins/org.graylog.plugins.failure/config');
    const promise = fetch('PUT', url, config);
    FailureActions.updateFailureConfig.promise(promise);

    return promise;
  },

  loadFailureConfig(): Promise<FailureConfig> {
    const url = qualifyUrl('/plugins/org.graylog.plugins.failure/config');
    const promise = fetch('GET', url);

    FailureActions.loadFailureConfig.promise(promise);

    return promise;
  },

  loadFailureProcessingOverview(): Promise<FailureOverview> {
    const url = qualifyUrl('/plugins/org.graylog.plugins.failure/failures/count');
    const promise = fetch('GET', url);

    FailureActions.loadFailureProcessingOverview.promise(promise);

    return promise;
  },
}));

export { FailureActions, FailureStore };
export default FailureStore;
