import PropTypes from 'prop-types';
import * as React from 'react';

import { Col, Row } from 'components/bootstrap';
import type { Report } from 'report/types';

import ReportLayout from './ReportLayout';
import ReportScheduling from './ReportScheduling';

type Props = {
  report: Report,
  reportLogo: string | undefined,
};
const ReportConfiguration = ({ report, reportLogo }: Props) => (
  <Row>
    <Col lg={5}>
      <ReportScheduling report={report} />
    </Col>
    <Col lg={6} lgOffset={1}>
      <ReportLayout report={report} reportLogo={reportLogo} />
    </Col>
  </Row>
);

ReportConfiguration.propTypes = {
  report: PropTypes.object.isRequired,
  reportLogo: PropTypes.string,
};

ReportConfiguration.defaultProps = {
  reportLogo: undefined,
};

export default ReportConfiguration;
