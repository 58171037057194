import Reflux from 'reflux';

import type { Notification, Notifications } from 'theme/types';
import type { ThemeColorModes } from 'theme/colors';
import fetch, { fetchPeriodically } from 'logic/rest/FetchProvider';
import { singletonStore, singletonActions } from 'logic/singleton';
import { qualifyUrl } from 'util/URLUtils';
import UserNotification from 'util/UserNotification';
import type { Status } from 'archive/types';

export type ActionsType = {
  updateTheme: (theme: {}) => Promise<{}>;
  loadTheme: () => Promise<unknown>;
  createPublicNotification: (notification: Notification) => Promise<Notification>;
  updatePublicNotification: (id: string, notification: Notification) => Promise<Notification>;
  loadAllPublicNotifications: () => Promise<Notifications>;
  loadPublicNotification: (id: string) => Promise<Notification>;
  deletePublicNotification: (id: string) => Promise<0 | 1>;
  getLicenseStatus: () => Promise<any>;
};

export const CustomizationActions = singletonActions('Customizations', () => Reflux.createActions<ActionsType>({
  updateTheme: { asyncResult: true },
  loadTheme: { asyncResult: true },
  createPublicNotification: { asyncResult: true },
  updatePublicNotification: { asyncResult: true },
  loadPublicNotification: { asyncResult: true },
  loadAllPublicNotifications: { asyncResult: true },
  deletePublicNotification: { asyncResult: true },
  getLicenseStatus: { asyncResult: true },
}));

type CustomizationStoreState = {
  status: Status,
  missing: boolean,
  loading: boolean,
};

export const CustomizationStore = singletonStore('Customization', () => Reflux.createStore<CustomizationStoreState>({
  listenables: [CustomizationActions],
  _state: {
    status: undefined,
    missing: false,
    loading: false,
  },

  getInitialState() {
    const { status, missing, loading } = this._state;

    return {
      status,
      missing,
      loading,
    };
  },

  updateTheme(theme: ThemeColorModes): Promise<void> {
    const url = qualifyUrl('/plugins/org.graylog.plugins.customization/theme');
    const promise = fetch('POST', url, theme);
    CustomizationActions.updateTheme.promise(promise);

    return promise;
  },

  loadTheme(): Promise<ThemeColorModes> {
    const url = qualifyUrl('/plugins/org.graylog.plugins.customization/theme');
    const promise = fetch('GET', url);

    CustomizationActions.loadTheme.promise(promise);

    return promise;
  },

  loadAllPublicNotifications(): Promise<Notifications> {
    const url = qualifyUrl('/plugins/org.graylog.plugins.customization/notifications');
    const promise = fetchPeriodically('GET', url);

    CustomizationActions.loadAllPublicNotifications.promise(promise);

    return promise;
  },

  loadPublicNotification(id: string): Promise<Notification> {
    const url = qualifyUrl(`/plugins/org.graylog.plugins.customization/notifications/${id}`);
    const promise = fetch('GET', url);

    CustomizationActions.loadPublicNotification.promise(promise);

    return promise;
  },

  createPublicNotification(notification: Notification): Promise<Notification> {
    const url = qualifyUrl('/plugins/org.graylog.plugins.customization/notifications');
    const promise = fetch('POST', url, notification);
    CustomizationActions.createPublicNotification.promise(promise);

    return promise;
  },

  updatePublicNotification(id: string, notification: Notification): Promise<Notification> {
    const url = qualifyUrl(`/plugins/org.graylog.plugins.customization/notifications/${id}`);
    const promise = fetch('PUT', url, notification);
    CustomizationActions.updatePublicNotification.promise(promise);

    return promise;
  },

  deletePublicNotification(id: string): Promise<0 | 1> {
    const url = qualifyUrl(`/plugins/org.graylog.plugins.customization/notifications/${id}`);
    const promise = fetch('DELETE', url);

    CustomizationActions.deletePublicNotification.promise(promise);

    return promise;
  },

  getLicenseStatus() {
    const url = qualifyUrl('/plugins/org.graylog.plugins.license/licenses/status/for-subject?subject=/license/enterprise/customization');
    const promise = fetch('GET', url);
    const errorHandler = this._errorHandler('Fetching customization license failed', 'Could not retrieve customization'
      + ' license');

    promise.then((response) => {
      this._updateState({ status: response.status, missing: false, loading: false });
      this.trigger({ status: response.status, missing: false, loading: false });
    }, (error) => {
      try {
        // We don't want to create a user notification for missing licenses
        if (error.additional.status !== 404) {
          errorHandler(error);
        }
      } catch (e) {
        errorHandler(error);
      }

      this._updateState({ status: null, missing: true, loading: false });
      this.trigger({ status: null, missing: true, loading: false });
    });

    CustomizationActions.getLicenseStatus.promise(promise);
  },

  _errorHandler(message, title) {
    return (error) => {
      let errorMessage;

      try {
        errorMessage = error.additional.body.message;
      } catch (e) {
        errorMessage = error.message;
      }

      UserNotification.error(`${message}: ${errorMessage}`, title);
    };
  },

  _updateState({ status, missing, loading }) {
    this._state = {
      status,
      missing,
      loading,
    };
  },
}));

export default CustomizationStore;
