import * as React from 'react';
import styled from 'styled-components';
import { useContext, useRef, useCallback } from 'react';

import { useStore } from 'stores/connect';
import { Alert, Button, Row } from 'components/bootstrap';
import QueryEditModeContext from 'views/components/contexts/QueryEditModeContext';
import type { QueryEditMode } from 'views/components/contexts/QueryEditModeContext';
import { SearchParameterStore, SearchParameterActions } from 'enterprise/parameters/stores/SearchParameterStore';
import { SearchMetadataStore } from 'views/stores/SearchMetadataStore';
import createParametersFromNames from 'enterprise/parameters/components/CreateParametersFromNames';

import ParameterDeclarationForm from './ParameterDeclarationForm';

const StyledAlert = styled(Alert)`
  margin-bottom: 6px;
`;

type DeclarationForm = {
  open: () => void,
};

type WrapperProps = {
  children: React.ReactNode,
};

const wrapperForMode = (mode: QueryEditMode): React.ComponentType<WrapperProps> => {
  switch (mode) {
    case 'query': return ({ children }: WrapperProps) => (
      <Row>
        {children}
      </Row>
    );
    // eslint-disable-next-line react/jsx-no-useless-fragment
    case 'widget': return ({ children }: WrapperProps) => <>{children}</>;
    default: throw new Error(`Invalid query edit mode: ${mode}`);
  }
};

const ParameterBar = () => {
  const existingParameters = useStore(SearchParameterStore);
  const { undeclared: undeclaredParameterNames } = useStore(SearchMetadataStore);
  const queryEditContext = useContext(QueryEditModeContext);
  const declarationForm = useRef<DeclarationForm | undefined>();

  const openParameterDeclarationForm = useCallback(() => {
    if (declarationForm.current) {
      declarationForm.current.open();
    }
  }, []);

  if (undeclaredParameterNames.size === 0) {
    return null;
  }

  const Wrapper = wrapperForMode(queryEditContext);
  const undeclaredParameters = createParametersFromNames(undeclaredParameterNames);

  return (
    <Wrapper>
      <StyledAlert bsStyle="danger">
        <h4><strong>Undeclared query parameters:</strong> {undeclaredParameterNames.join(', ')}</h4>
        <br />
        <p>All parameters used in the search query need to be declared before the query can be used:</p>
        <br />
        <Button bsStyle="primary" bsSize="small" onClick={openParameterDeclarationForm}>Declare parameters</Button>
      </StyledAlert>
      <ParameterDeclarationForm ref={(c: DeclarationForm | undefined | null) => { declarationForm.current = c; }}
                                existingParameters={existingParameters}
                                parameters={undeclaredParameters}
                                onSave={SearchParameterActions.declare} />
    </Wrapper>
  );
};

export default ParameterBar;
