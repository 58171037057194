import type View from 'views/logic/views/View';
import fetch from 'logic/rest/FetchProvider';
import { qualifyUrl } from 'util/URLUtils';
import type Widget from 'views/logic/widgets/Widget';
import fetchReports from 'report/logic/fetchReports';

const retrieveReportsReferencingWidget = (dashboardId: string, widgetId: string): Array<string> => fetch('GET',
  qualifyUrl(`/plugins/org.graylog.plugins.report/reports/byWidget/${dashboardId}/${widgetId}`));

const isWidgetUsedInReports = async (widget: Widget, view: View, title: string) => {
  const reports = await fetchReports();
  const reportIds = await retrieveReportsReferencingWidget(view.id, widget.id);

  if (reportIds?.length === 1) {
    const reportId = reportIds[0];
    const inaccessibleReport = !reports[reportId];
    const reportTitle = inaccessibleReport ? reportId : `"${reports[reportId].title}"`;

    // eslint-disable-next-line no-alert
    return window.confirm(`The widget "${title}" is used in ${inaccessibleReport ? 'inaccessible ' : ''}report ${reportTitle}.\n\n Do you really want to delete it?`);
  }

  if (reportIds?.length > 1) {
    const reportTitles = reportIds.map((reportId) => {
      const inAccessibleReport = !reports[reportId];
      const reportTitle = inAccessibleReport ? reportId : `"${reports[reportId].title}"`;

      return `    - ${reportTitle}${inAccessibleReport ? ' (not permitted to see title)' : ''}`;
    }).join('\n');

    // eslint-disable-next-line no-alert
    return window.confirm(`The widget "${title}" is used in reports:\n${reportTitles}.\n\n Do you really want to delete it?`);
  }

  return null;
};

export default isWidgetUsedInReports;
