import React, { useEffect } from 'react';
import { isEmpty } from 'lodash';

import { LinkContainer } from 'components/common/router';
import { Button, ButtonToolbar } from 'components/bootstrap';
import { DocumentTitle, PageHeader, Spinner } from 'components/common';
import Routes from 'routing/Routes';
import { useStore } from 'stores/connect';
import DocumentationLink from 'components/support/DocumentationLink';
import ForwarderDocs from 'forwarder/util/ForwarderDocs';

import ForwardersListComponent from './forwarders/ForwardersListComponent';
import InputProfilesStore, { InputProfilesActions } from './stores/InputProfilesStore';
import EmptyForwarderListComponent from './forwarders/EmptyForwarderListComponent';
import ForwardersContext from './forwarders/ForwardersContext';
import PaginatedForwarderListProvider from './forwarders/PaginatedForwarderListProvider';
import withIsPermitted from './util/withIsPermitted';
import ForwarderLicenseStatus from './ForwarderLicenseStatus';
import ForwarderLicenseStore, { ForwarderLicenseActions } from './stores/ForwarderLicenseStore';

const REQUIRED_PERMISSIONS = ['forwarders:read', 'inputprofiles:read'];

const ForwardersPage = () => {
  const { all: inputProfiles } = useStore(InputProfilesStore);
  const licenseStatus = useStore(ForwarderLicenseStore);

  useEffect(() => {
    InputProfilesActions.listAll();
    ForwarderLicenseActions.getLicenseStatus();
  }, []);

  return (
    <DocumentTitle title="Forwarders">
      <PageHeader title="Forwarders">
        List of Forwarders that have registered with this Graylog instance.
        <>Learn more about Graylog Forwarder in the&nbsp;
          <DocumentationLink text="documentation" page={ForwarderDocs.FORWARDER} />.
        </>
        <ButtonToolbar>
          <LinkContainer to={Routes.pluginRoute('SYSTEM_FORWARDERS')}>
            <Button bsStyle="info">Forwarders</Button>
          </LinkContainer>
          <LinkContainer to={Routes.pluginRoute('SYSTEM_INPUT_PROFILES')}>
            <Button bsStyle="info">Input Profiles</Button>
          </LinkContainer>
        </ButtonToolbar>
      </PageHeader>

      <ForwarderLicenseStatus licenseStatus={licenseStatus} />
      <PaginatedForwarderListProvider>
        <ForwardersContext.Consumer>
          {({ forwarders, pagination, changePagination, changeSort, changeStateFilter, isLoading }) => {
            if (forwarders && inputProfiles && pagination && changePagination && changeSort && changeStateFilter) {
              return forwarders.length === 0 && isEmpty(pagination.query) && pagination.stateFilter === 'any'
                ? <EmptyForwarderListComponent licenseStatus={licenseStatus} />
                : (
                  <ForwardersListComponent forwarders={forwarders}
                                           isLoading={isLoading}
                                           licenseStatus={licenseStatus}
                                           inputProfiles={inputProfiles}
                                           pagination={pagination}
                                           onQueryChange={changePagination}
                                           onSortChange={changeSort}
                                           onStateFilterChange={changeStateFilter} />
                );
            }

            return <Spinner />;
          }}
        </ForwardersContext.Consumer>
      </PaginatedForwarderListProvider>
    </DocumentTitle>
  );
};

export default withIsPermitted(ForwardersPage, REQUIRED_PERMISSIONS);
