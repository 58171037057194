import * as React from 'react';
import PropTypes from 'prop-types';
import { toSafeInteger, isInteger, merge } from 'lodash';
import styled, { css } from 'styled-components';
import { useState, useMemo } from 'react';

import { ReactGridContainer } from 'components/common';
import type { WidgetPositionJSON } from 'views/logic/widgets/WidgetPosition';
import type { ReportWidgetPosition, BackendReportWidget } from 'report/types';

import ReportingWidget from './ReportingWidget';
import ReportingWidgetContainer from './ReportingWidgetContainer';

const ROW_HEIGHT = 200 + 42; // Minimum widget height + padding + border
const MAX_HEIGHT = 2; // Do not use more than 2 rows, helping to move large widgets around

type WidgetDimension = { height: number, width: number };
type WidgetDimensions = { [widgetId: string]: { height: number, width: number }};

const StyledReportWidgetContainerWrapper = styled.div(({ theme }) => css`
  overflow: hidden;
  padding: 20px 40px;
  
  ::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 20px;
  }
  
  @media screen {
    border: 1px solid ${theme.colors.gray['80']};
    cursor: move;
  }
`);

const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-content: flex-start;
`;

// Return dimension if integer, but round up in other case. E.g. 0.8 columns should be 1 column, but 1 should stay 1.
const calculateDimension = (value: number) => (isInteger(value) ? value : toSafeInteger(value) + 1);

const pixelsToCoordinates = (widgetDimensions: WidgetDimension) => {
  return {
    height: Math.min(calculateDimension(widgetDimensions.height / ROW_HEIGHT), MAX_HEIGHT),
    width: 1, // There is only one column in the grid
  };
};

const handleDimensionsChange = (widgetId: string, setWidgetDimensions: (fn: (prev: WidgetDimensions) => WidgetDimensions) => void) => {
  return (dimensions) => {
    const nextWidgetDimensions = {};
    nextWidgetDimensions[widgetId] = pixelsToCoordinates(dimensions);
    setWidgetDimensions((prevState) => ({ ...prevState, ...nextWidgetDimensions }));
  };
};

// Format layout from [{ dashboard_widget_id: 'foo', row: 1, col 1 }] to { 'foo': { row: 1, col: 1 } }, and merge
// that with the widget sizes.
// This method will generate an object of positions that `ReactGridContainer` can understand.
const calculateLayout = (positions: Array<ReportWidgetPosition>, widgetDimensions: WidgetDimensions) => {
  const persistedPositions = positions || [];
  const formattedPositions = {};

  persistedPositions.forEach((position) => {
    formattedPositions[position.dashboard_widget_id] = {
      row: position.row,
      col: position.col,
    };
  });

  return merge({}, formattedPositions, widgetDimensions);
};

type Props = {
  onPositionsChange: (newPositions: Array<WidgetPositionJSON>) => void,
  locked: boolean,
  interactive: boolean,
  parameterValues: { [key: string]: any },
  positions: Array<ReportWidgetPosition>,
  widgets: Array<BackendReportWidget>,
}

const ReportPreview = ({
  onPositionsChange,
  locked,
  interactive,
  parameterValues,
  positions,
  widgets,
}: Props) => {
  const [widgetDimensions, setWidgetDimensions] = useState<WidgetDimensions>({});
  const layout = useMemo(() => calculateLayout(positions, widgetDimensions), [positions, widgetDimensions]);

  return (
    <StyledDiv>
      <ReactGridContainer positions={layout}
                          onPositionsChange={onPositionsChange}
                          rowHeight={ROW_HEIGHT}
                          isResizable={false}
                          locked={locked}
                          columns={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}>
        {widgets.map((widget) => (
          <StyledReportWidgetContainerWrapper key={widget.dashboard_widget_id}>
            <ReportingWidgetContainer onDimensionsChange={handleDimensionsChange(widget.dashboard_widget_id, setWidgetDimensions)}>
              <ReportingWidget dashboardId={widget.dashboard_id}
                               widget={widget}
                               width={0} // ReportWidgetContainer will take care of setting that
                               height={0} // ReportWidgetContainer will take care of setting that
                               interactive={interactive}
                               parameterValues={parameterValues}
                               showCaption={false} />
            </ReportingWidgetContainer>
          </StyledReportWidgetContainerWrapper>
        ))}
      </ReactGridContainer>
    </StyledDiv>
  );
};

ReportPreview.propTypes = {
  positions: PropTypes.arrayOf(PropTypes.object).isRequired,
  widgets: PropTypes.arrayOf(PropTypes.object).isRequired,
  parameterValues: PropTypes.object.isRequired,
  onPositionsChange: PropTypes.func,
  locked: PropTypes.bool,
  interactive: PropTypes.bool,
};

ReportPreview.defaultProps = {
  onPositionsChange: () => {},
  locked: false,
  interactive: false,
};

export default ReportPreview;
