import MessageDetailsAdditionalProvider from 'search/contexts/MessageDetailsAdditionalProvider';

import MessageSummary from './MessageSummary';

const messageTableBindings = {
  'views.components.widgets.messageTable.previewOptions': [
    {
      title: 'Show summary',
      isChecked: (config) => config.showSummary,
      isDisabled: () => false,
      help: 'Display a summary of the most relevant information of a message, based on the message event type. When a summary is available it will replace the message.',
      onChange: (config, onConfigChange) => {
        if (config.showSummary) {
          const newConfig = config.toBuilder().showSummary(false).build();

          return onConfigChange(newConfig);
        }

        const newConfig = config.toBuilder()
          .showMessageRow(true)
          .showSummary(true)
          .build();

        return onConfigChange(newConfig);
      },
      sort: 2,
    },
  ],
  'views.components.widgets.messageTable.messageRowOverride': [MessageSummary],
  'views.components.widgets.messageDetails.contextProviders': [MessageDetailsAdditionalProvider],
};

export default messageTableBindings;
