import Reflux from 'reflux';

import UserNotification from 'util/UserNotification';
import { qualifyUrl } from 'util/URLUtils';
import fetch from 'logic/rest/FetchProvider';
import { RefluxActions } from 'stores/StoreTypes';

import type { ArchiveConfiguration } from './types';

export const TYPES = {
  'fs-1': 'File system',
};

const urlPrefix = '/plugins/org.graylog.plugins.archive';

type ArchiveConfigurationActionsType = RefluxActions<{
  getConfig: () => Promise<ArchiveConfiguration>,
  saveConfig: (nextConfig: ArchiveConfiguration) => Promise<ArchiveConfiguration>,
}>;

export const ArchiveConfigurationActions: ArchiveConfigurationActionsType = Reflux.createActions({
  getConfig: { asyncResult: true },
  saveConfig: { asyncResult: true },
});

const ArchiveConfigurationStore = Reflux.createStore({
  listenables: [ArchiveConfigurationActions],
  filenamesExport: undefined,
  config: undefined,

  getInitialState() {
    return {
      config: undefined,
    };
  },

  _errorHandler(message: string, title: string, cb: (error: any) => void) {
    return (error) => {
      let errorMessage;

      try {
        errorMessage = error.additional.body.message;
      } catch (e) {
        errorMessage = error.message;
      }

      UserNotification.error(`${message}: ${errorMessage}`, title);

      if (cb) {
        cb(error);
      }
    };
  },

  _configUrl() {
    return qualifyUrl(`${urlPrefix}/config`);
  },

  _archivesUrl(path: string) {
    return qualifyUrl(`${urlPrefix}/cluster/archives${path}`);
  },

  _url(path: string) {
    return qualifyUrl(`${urlPrefix}${path}`);
  },

  getConfig() {
    const promise = fetch('GET', this._configUrl());

    promise.then((response: ArchiveConfiguration) => {
      this.config = response;
      this.trigger({ config: response });
    }, this._errorHandler('Fetching config failed', 'Could not retrieve archive config'));

    ArchiveConfigurationActions.getConfig.promise(promise);
  },

  saveConfig(config: ArchiveConfiguration) {
    const promise = fetch('PUT', this._configUrl(), config);

    promise.then((response: ArchiveConfiguration) => {
      this.config = response;
      this.trigger({ config: response });
      UserNotification.success('Archive configuration was updated successfully');
    }, this._errorHandler('Updating archive config failed', 'Unable to update archive config'));

    ArchiveConfigurationActions.saveConfig.promise(promise);
  },

});

export default ArchiveConfigurationStore;
