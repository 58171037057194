const HIGHLIGHT_COLOR_BASE = '139, 99, 166';

const securityContentThemeVariables = {
  measures: {
    SIDE_NAV_WIDTH: 180,
    CLOSED_NAV_LEFT: 400,
  },
  colors: {
    HIGHLIGHT_COLOR_RGB: `rgb(${HIGHLIGHT_COLOR_BASE})`,
    HIGHLIGHT_COLOR_SHADOW: `rgba(${HIGHLIGHT_COLOR_BASE}, .3)`,
  },
};

export default securityContentThemeVariables;
