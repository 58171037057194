import Reflux from 'reflux';
import { singletonActions } from 'logic/singleton';
import { RefluxActions } from 'stores/StoreTypes';

export type FailureConfig = {
  persistIndexingFailure: boolean,
  persistFailedMessage: boolean,
  keepFailedMessageDuplicate: boolean,
  submitProcessingFailure: boolean,
}

interface FailureData {
  enabled: boolean,
  count?: number,
  source?: 'elasticsearch' | 'mongodb',
}

export type FailureOverview = {
  indexing: FailureData,
  processing: FailureData,
}

export type ActionsType = {
  updateFailureConfig: (config: FailureConfig) => Promise<{}>;
  loadFailureConfig: () => Promise<FailureConfig>;
  loadFailureProcessingOverview: () => Promise<FailureOverview>;
};

const FailureActions: RefluxActions<ActionsType> = singletonActions('FailureProcessings', () => Reflux.createActions({
  updateFailureConfig: { asyncResult: true },
  loadFailureConfig: { asyncResult: true },
  loadFailureProcessingOverview: { asyncResult: true },
}));

export default FailureActions;
