import * as React from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { PluginStore } from 'graylog-web-plugin/plugin';
import { useState, useContext, useEffect, useRef, useMemo } from 'react';
import styled from 'styled-components';

import ColorMapper from 'views/components/visualizations/ColorMapper';
import Icon from 'components/common/Icon';
import searchTypeDefinition from 'views/logic/SearchType';
import FieldTypeMapping from 'views/logic/fieldtypes/FieldTypeMapping';
import RenderCompletionCallback from 'views/components/widgets/RenderCompletionCallback';
import ChartColorContext from 'views/components/visualizations/ChartColorContext';
import defaultWidgetTitle from 'views/components/defaultTitle';
import ReportingWidgetVisualization from 'report/common/ReportingWidgetVisualization';
import type { BackendReportWidget, ParameterValues, AvailableWidgetPreview } from 'report/types';
import type { WidgetExport } from 'views/types';
import { isReportBackendWidget } from 'report/typeGuards/reportingWidget';

import Caption from './Caption';
import Heading from './Heading';
import WidgetDescription, { FallbackWidgetDescription } from './WidgetDescription';
import ErrorBoundary from './ErrorBoundary';

import { MESSAGE_LIST_WIDGET_LIMIT } from '../Constants';
import { AvailableWidgetsStore } from '../AvailableWidgetsStore';

export const Container = styled.div`
  font-size: 90%;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;

  @media print {
    padding: 0;
    page-break-inside: avoid;
  }
`;

export const VisualizationContainer = styled.div`
  width: 100%;
  height: calc(100% - 50px);

  .quickvalues-table {
    margin: 0 auto;
    text-align: left;
    width: 80%;
  }

  .dc-chart {
    float: none;
  }

  @media print {
    /* This is the desired behaviour, but currently unimplemented in Chrome. It may be implemented in a future version, so we leave it here. */
    page-break-after: avoid;
    page-break-before: avoid;
    break-after: avoid;
    break-before: avoid;
    margin-bottom: 4rem;
  }
`;

const DragHandleIcon = styled(Icon)`
  font-size: 1.2em;
  position: absolute;
  left: 20px;
  top: 25px;

  @media print {
    display: none;
  }
`;

type State = {
  calculatedAt: string | undefined,
  computationTimeRange: string | undefined
  errorMessage: string | undefined,
  hasError: boolean,
  result: any,
  types: Immutable.List<FieldTypeMapping>,
}

const cleanState: State = {
  calculatedAt: undefined,
  computationTimeRange: undefined,
  errorMessage: undefined,
  hasError: false,
  result: undefined,
  types: undefined,
};

const _searchTypePlugin = (type: string) => {
  const typeDefinition = searchTypeDefinition(type);

  return typeDefinition && typeDefinition.handler
    ? searchTypeDefinition(type).handler
    : {
      convert: (result) => {
        // eslint-disable-next-line no-console
        console.error(`No search type handler for type '${type}' result:`, result);

        return result;
      },
    };
};

const _errorHandler = (
  response: { message: string },
  result: any,
  setState: (fn: (oldSate: State) => State) => void,
  renderComplete: () => void,
) => {
  const error = response.message;
  const newResult = result === undefined ? 'N/A' : result;

  setState((state) => ({
    ...state,
    result: newResult,
    hasError: true,
    errorMessage: `Error loading widget value: ${error}`,
  }));

  renderComplete();
};

const _loadValue = (
  result: any,
  calculatedAt: string | undefined,
  setState: (fn: (oldSate: State) => State) => void,
  renderComplete: () => void,
  widgetId: string,
  dashboardId: string,
  widget: BackendReportWidget | AvailableWidgetPreview,
  parameterValues,
) => {
  const globalOverride = widget.type === 'messages'
    ? { limit: MESSAGE_LIST_WIDGET_LIMIT, offset: 0 }
    : {};

  AvailableWidgetsStore.value(dashboardId, widgetId, parameterValues, globalOverride)
    .then((value) => {
      if (value.errors && value.errors.length > 0) {
        const message = value.errors.map((error) => error.description).join(' / ');
        _errorHandler({ message }, result, setState, renderComplete);

        return;
      }

      // Avoid updating state if the result didn't change
      if (value.calculated_at === calculatedAt) {
        return;
      }

      const mappedResults = value.result.map((searchType) => _searchTypePlugin(searchType.type).convert(searchType));
      const widgetPlugin: WidgetExport = PluginStore.exports('enterpriseWidgets').find((w) => w.type.toUpperCase() === widget.type.toUpperCase());
      const { searchResultTransformer = (x) => x } = widgetPlugin || {};
      let newState = {
        result: searchResultTransformer(mappedResults),
        calculatedAt: value.calculated_at,
        hasError: false,
        errorMessage: undefined,
        types: Immutable.List<FieldTypeMapping>(value.field_types.map((type) => FieldTypeMapping.fromJSON(type))),
        computationTimeRange: undefined,
      };

      if (value.computation_time_range) {
        newState = {
          ...newState,
          computationTimeRange: value.computation_time_range,
        };
      }

      setState((state) => ({ ...state, ...newState }));
    },
    (error: Error) => _errorHandler(error, result, setState, renderComplete),
    )
    .catch((error: Error) => _errorHandler(error, result, setState, renderComplete));
};

type Props = {
  dashboardId: string,
  widget: BackendReportWidget | AvailableWidgetPreview,
  showHeading?: boolean,
  showCaption?: boolean,
  showHandle?: boolean,
  height: number,
  width: number,
  interactive: boolean,
  limitHeight: boolean,
  parameterValues: ParameterValues,
}

const ReportingWidget = (props: Props) => {
  const { widget, showCaption, showHeading, showHandle, dashboardId, parameterValues, height, width, interactive, limitHeight } = props;
  const [state, setState] = useState(cleanState);
  const { calculatedAt, result, hasError, errorMessage, types } = state;
  const renderComplete = useContext(RenderCompletionCallback);
  const heading = useRef(null);
  const caption = useRef(null);
  const isBackendWidget = isReportBackendWidget(widget);
  const widgetId = isBackendWidget ? widget.dashboard_widget_id : widget.id;

  useEffect(() => {
    _loadValue(result, calculatedAt, setState, renderComplete, widgetId, dashboardId, widget, parameterValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [widgetId]);

  const Description = isBackendWidget ? (
    <ErrorBoundary FallbackComponent={FallbackWidgetDescription}>
      <WidgetDescription calculatedAt={calculatedAt}
                         widgetConfig={widget.config}
                         widgetTimerange={widget.timerange}
                         widgetQuery={widget.query}
                         widgetType={widget.type}
                         widgetStreams={widget.streams} />
    </ErrorBoundary>
  ) : null;

  const chartColors = widget?.config?.formattingSettings?.chartColors ?? {};
  const colorMap = ColorMapper.create(Immutable.Map(chartColors));
  const chartColorContext = useMemo(() => ({ colors: colorMap, setColor: () => Promise.resolve() }), [colorMap]);
  const header = (isBackendWidget ? widget.description : widget.title) ?? defaultWidgetTitle(widget);

  return (
    <ChartColorContext.Provider value={chartColorContext}>
      <Container>
        {showHandle && <DragHandleIcon name="sort" />}
        {showHeading && <Heading title={header} ref={heading} />}
        <VisualizationContainer>
          <ReportingWidgetVisualization widget={widget}
                                        widgetId={widgetId}
                                        result={result}
                                        hasError={hasError}
                                        errorMessage={errorMessage}
                                        types={types}
                                        captionHeight={caption.current?.scrollHeight ?? 0}
                                        headingHeight={heading.current?.scrollHeight ?? 0}
                                        height={height}
                                        width={width}
                                        interactive={interactive}
                                        limitHeight={limitHeight} />
        </VisualizationContainer>
        {showCaption && <Caption text={Description} ref={caption} />}
      </Container>
    </ChartColorContext.Provider>
  );
};

ReportingWidget.propTypes = {
  dashboardId: PropTypes.string.isRequired,
  widget: PropTypes.object.isRequired,
  showHeading: PropTypes.bool,
  showCaption: PropTypes.bool,
  showHandle: PropTypes.bool,
  height: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
  interactive: PropTypes.bool,
  limitHeight: PropTypes.bool,
  parameterValues: PropTypes.object,
};

ReportingWidget.defaultProps = {
  showHeading: true,
  showCaption: true,
  showHandle: true,
  interactive: true,
  limitHeight: false,
  parameterValues: {},
};

export default ReportingWidget;
