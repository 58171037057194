import * as React from 'react';
import { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { cloneDeep } from 'lodash';
import numeral from 'numeral';
import styled from 'styled-components';

import useUserDateTime from 'hooks/useUserDateTime';
import { getValueFromInput } from 'util/FormsUtils';
import { Alert, Button, ControlLabel, FormControl, FormGroup, HelpBlock, Input } from 'components/bootstrap';
import { ExpandableList, TimezoneSelect } from 'components/common';
import type { Report } from 'report/types';
import type { AvailableDashboard } from 'report/report-contents-page/useAvailableWidgets';
import Logo from 'report/common/ReportLogo';
import AvailableDashboardsList from 'report/report-contents-page/AvailableDashboardsList';

import ReportContentsToolbar from './ReportContentsToolbar';

const MAX_LOGO_SIZE = 1024 * 1024; // 1MB

const StyledExpandableList = styled(ExpandableList)`
  .header {
    display: inline-flex;
    align-items: center;
  }
`;

const RemoveLogo = styled.div`
  margin-bottom: 10px;
`;

type Props = {
  disabled?: boolean,
  report: Report,
  reportLogo: string,
  formElementId: string,
  dashboards: Array<AvailableDashboard>,
  onReportChange: (updatedReport: Report) => void,
  onReportLogoChange: (newLogo: string | ArrayBuffer | null) => void,
  onCancel: () => void,
  action: 'create' | 'edit',
  isLoading: boolean,
};

const updateProp = <R extends keyof Report> (report: Report, onReportChange: (updatedReport: Report) => void, key: R, value: Report[R]) => {
  const updatedReport = cloneDeep(report) as Report;
  updatedReport[key] = value;
  onReportChange(updatedReport);
};

const _updateReportLogo = (
  { target: { files } }: React.ChangeEvent<HTMLInputElement>,
  onReportLogoChange: (newLogo: string | ArrayBuffer | null) => void,
  setLogoError: (error: { message: string }) => void,
) => {
  if (files.length === 0) {
    return;
  }

  const file = files[0];

  if (file.size > MAX_LOGO_SIZE) {
    setLogoError({ message: `Image size is larger than ${numeral(MAX_LOGO_SIZE).format('0 b')}, please resize the image or pick a smaller one.` });

    return;
  }

  const reader = new FileReader();

  reader.onload = () => {
    setLogoError(undefined);
    onReportLogoChange(reader.result);
  };

  reader.readAsDataURL(file);
};

const ReportContentsSelection = ({
  action,
  disabled,
  formElementId,
  dashboards,
  report,
  reportLogo,
  onCancel,
  isLoading,
  onReportLogoChange,
  onReportChange,
}: Props) => {
  const [logoError, setLogoError] = useState<{ message: string }>();
  const { userTimezone } = useUserDateTime();

  const handleInputChange = useCallback((event) => {
    updateProp(report, onReportChange, event.target.name, getValueFromInput(event.target));
  }, [onReportChange, report]);

  const updateReportLogo = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => _updateReportLogo(event, onReportLogoChange, setLogoError),
    [onReportLogoChange],
  );

  const deleteReportLogo = useCallback(() => onReportLogoChange(null), [onReportLogoChange]);

  const updateReport = useCallback((reportChanges: Partial<Report>) => {
    onReportChange({ ...report, ...reportChanges });
  }, [onReportChange, report]);

  if (dashboards.length === 0) {
    return (
      <div>
        <Alert bsStyle="info">
          Reporting is based on dashboards, create the widgets you want to include in the report to get started.
        </Alert>
      </div>
    );
  }

  return (
    <div>
      <h3>Contents</h3>
      <p>
        Write a title and description for the report and select the widgets that will be include in it.
      </p>

      <Input id="title"
             name="title"
             type="text"
             label="Title"
             help="Set a title to use in the report's cover page."
             value={report.title}
             onChange={handleInputChange}
             required />
      <Input id="subtitle"
             name="subtitle"
             type="text"
             label={<span>Subtitle <small className="text-muted">(Optional)</small></span>}
             help="Set a subtitle to use in the report's cover page."
             value={report.subtitle || ''}
             onChange={handleInputChange} />
      <FormGroup controlId="logo" validationState={logoError ? 'error' : null}>
        <ControlLabel>Logo <small className="text-muted">(Optional)</small></ControlLabel>
        <div className="clearfix" />
        {reportLogo && (
          <>
            <Logo imageSrc={reportLogo} />
            <RemoveLogo>
              <Button bsSize="xsmall" bsStyle="primary" onClick={deleteReportLogo}>
                Remove logo
              </Button>
            </RemoveLogo>
          </>
        )}
        <FormControl id="logo"
                     type="file"
                     accept="image/png,image/jpeg"
                     onChange={updateReportLogo} />
        <HelpBlock>
          {logoError ? logoError.message : (
            <span>
              Choose an image to use as a logo in the report&#39;s cover page. The image must be in JPEG
              or PNG formats and cannot exceed {numeral(MAX_LOGO_SIZE).format('0 b')}.
            </span>
          )}
        </HelpBlock>
      </FormGroup>
      <Input id="description"
             name="description"
             type="textarea"
             label={<span>Description <small className="text-muted">(Optional)</small></span>}
             help="Add a description to include at the beginning of the report."
             value={report.description || ''}
             onChange={handleInputChange}
             rows={4} />

      <FormGroup>
        <ControlLabel htmlFor="timezone-select">Time Zone</ControlLabel>
        <TimezoneSelect value={report.timezone}
                        name="timezone"
                        clearable={false}
                        onChange={(newValue) => handleInputChange({ target: { name: 'timezone', value: newValue } })} />
        <HelpBlock>
          <span>
            The timezone setting will affect all displayed times in the report.
          </span>
        </HelpBlock>
      </FormGroup>

      <FormGroup>
        <ControlLabel>Widgets</ControlLabel>
        <HelpBlock>
          Select the widgets to include in the report. You can create new widgets and add them to the report later
          on. Times in the widget selection are displayed in the timezone of the current user ({userTimezone}).
          The actual report will display times in the timezone configured for this report.
        </HelpBlock>
      </FormGroup>

      <StyledExpandableList>
        <AvailableDashboardsList reportWidgets={report.widgets} dashboards={dashboards} onReportUpdate={updateReport} />
      </StyledExpandableList>

      <ReportContentsToolbar action={action}
                             disabled={disabled}
                             onCancel={onCancel}
                             formElementId={formElementId}
                             isLoading={isLoading} />
    </div>
  );
};

ReportContentsSelection.propTypes = {
  report: PropTypes.object.isRequired,
  reportLogo: PropTypes.string,
  formElementId: PropTypes.string.isRequired,
  dashboards: PropTypes.array.isRequired,
  onReportChange: PropTypes.func.isRequired,
  onReportLogoChange: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  action: PropTypes.oneOf(['create', 'edit']),
  isLoading: PropTypes.bool,
};

ReportContentsSelection.defaultProps = {
  disabled: false,
  reportLogo: null,
  action: 'create',
  isLoading: false,
};

export default ReportContentsSelection;
