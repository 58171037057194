import React from 'react';

function MongoDBDataAdapterFieldSet() {
  return (
    <fieldset />
  );
}

MongoDBDataAdapterFieldSet.propTypes = {
};

export default MongoDBDataAdapterFieldSet;
