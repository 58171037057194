import { GroupSyncActions } from 'authentication/stores/directoryServices/GroupSyncStore';
import { ConfigFormValues } from 'authentication/components/oidc/config/types';
import prepareOktaGroupSyncConfig from 'authentication/logic/okta/prepareOktaGroupSyncConfig';

import OidcCallbackPage from './oidc-authentication/OidcCallbackPage';
import OidcLogin from './oidc-authentication/OidcLogin';
import BackendCreate from './config/BackendCreate';
import BackendEdit from './config/BackendEdit';
import BackendConfig from './config/BackendConfig';
import { fromJson, toJson } from './parser';

export const onOidcBackendUpdate = (backendGroupSyncIsActive: boolean, formValues: ConfigFormValues, backendId: string, serviceType: string) => {
  if (formValues.synchronizeGroups) {
    const payload = {
      ...prepareOktaGroupSyncConfig(formValues, backendId, serviceType),
      auth_service_backend_id: backendId,
    };

    if (backendGroupSyncIsActive) {
      return GroupSyncActions.update(backendId, payload);
    }

    return GroupSyncActions.create(backendId, payload);
  }

  if (backendGroupSyncIsActive) {
    return GroupSyncActions.delete(backendId);
  }

  return Promise.resolve();
};

export default {
  routes: [
    { path: '/authorization-code/callback', component: OidcCallbackPage },
  ],

  'authentication.services': [
    {
      name: 'okta',
      displayName: 'Okta',
      createComponent: BackendCreate,
      editComponent: BackendEdit,
      configDetailsComponent: BackendConfig,
      configFromJson: fromJson,
      configToJson: toJson,
    },

    {
      name: 'oidc',
      displayName: 'OIDC',
      createComponent: BackendCreate,
      editComponent: BackendEdit,
      configDetailsComponent: BackendConfig,
      configFromJson: fromJson,
      configToJson: toJson,
    },
  ],
  loginProviderType: [
    {
      type: 'okta',
      formComponent: OidcLogin,
    },

    {
      type: 'oidc',
      formComponent: OidcLogin,
    },
  ],
};
