import * as React from 'react';

import { Col, Row } from 'components/bootstrap';
import { DocumentTitle, PageHeader, Spinner } from 'components/common';
import withParams from 'routing/withParams';
import { REPORT_TZ_FALLBACK } from 'report/Constants';
import useReportAndLogo from 'report/hooks/useReportAndLogo';
import ReportsMainNav from 'report/common/ReportsMainNav';

import ReportContents from './ReportContents';

import ReportSubNav from '../common/ReportSubNav';
import ReportValidations from '../common/ReportValidations';

type Props = {
  params: { reportId: string }
}

const EditReportContentsPage = ({ params: { reportId } }: Props) => {
  const { report, reportLogo } = useReportAndLogo(reportId);

  if (!report) {
    return <Spinner text="Loading report data, please wait..." />;
  }

  const reportWithTimezone = { ...report, timezone: report.timezone ?? REPORT_TZ_FALLBACK };

  return (
    <DocumentTitle title={`Edit report ${report.title}`}>
      <div>
        <PageHeader title={`Edit report ${report.title}`}
                    subactions={<ReportSubNav reportId={report.id} />}>
          <span>
            Decide which content should be part of the report while you see its preview. You can schedule and
            configure the report in the next screen.
          </span>
          {null}
          <ReportsMainNav />
        </PageHeader>

        <ReportValidations />

        <Row className="content">
          <Col md={12}>
            <ReportContents key={report.id} report={reportWithTimezone} reportLogo={reportLogo} action="edit" />
          </Col>
        </Row>
      </div>
    </DocumentTitle>
  );
};

export default withParams(EditReportContentsPage);
