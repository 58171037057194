import * as React from 'react';

import { Input } from 'components/bootstrap';

const toFormValue = (newMinute: string | number) => {
  let minute = newMinute;

  if (String(minute).length === 1) {
    minute = `0${minute}`;
  }

  return `00:${minute}`;
};

export const fromFormValue = (timeString: string) => {
  return timeString?.slice(-2) ?? '';
};

type Props = {
  id: string,
  value: string | undefined | null,
  disabled?: boolean,
  required?: boolean,
  onChange: (newDay: number | string) => void,
  min?: string,
  max?: string,
}

const MinuteOfHourInput = ({ id, value, disabled, required, onChange, min, max }: Props) => {
  const onTimeChange = ({ target: { value: newValue } }) => {
    onChange(toFormValue(newValue));
  };

  return (
    <Input id={id}
           name={id}
           type="number"
           formGroupClassName="no-bm"
           onChange={onTimeChange}
           min={min}
           max={max}
           disabled={disabled}
           required={required}
           value={fromFormValue(value)} />
  );
};

MinuteOfHourInput.defaultProps = {
  disabled: false,
  required: false,
  min: 0,
  max: 59,
};

export default MinuteOfHourInput;
