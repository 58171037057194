import styled from 'styled-components';

import securityContentThemeVariables from 'security-app/theme/constants';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;

  .react-grid-item.react-resizable {
    box-shadow: 2px 2px 5px 0px ${securityContentThemeVariables.colors.HIGHLIGHT_COLOR_SHADOW};
    transition: all 0.22s ease-in-out !important;
    &:hover {
      box-shadow:  2px 2px 5px 3px ${securityContentThemeVariables.colors.HIGHLIGHT_COLOR_SHADOW};
    }
  }
`;

export default Container;
