import * as React from 'react';
import { singleton } from 'logic/singleton';

import type { WatchlistStatus } from 'watchlist/actions/WatchlistActions';

export type WatchlistStatusContextType = {
  refetch: () => void,
  isLoading: boolean,
  status: WatchlistStatus,
}

const WatchlistStatusContext = React.createContext<WatchlistStatusContextType | undefined>(undefined);

export default singleton('contexts.WatchlistStatusContext', () => WatchlistStatusContext);
