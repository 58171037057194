import type View from 'views/logic/views/View';
import fetch from 'logic/rest/FetchProvider';
import { qualifyUrl } from 'util/URLUtils';
import fetchReports from 'report/logic/fetchReports';

const retrieveReportsReferencingDashboard = (dashboardId: string): Array<string> => fetch('GET',
  qualifyUrl(`/plugins/org.graylog.plugins.report/reports/byDashboard/${dashboardId}`));

const isDashboardUsedInReports = async (view: View) => {
  const reports = await fetchReports();
  const reportIds = await retrieveReportsReferencingDashboard(view.id);

  if (reportIds?.length === 1) {
    const reportId = reportIds[0];
    const inaccessibleReport = !reports[reportId];
    const reportTitle = inaccessibleReport ? reportId : `"${reports[reportId].title}"`;

    // eslint-disable-next-line no-alert
    return window.confirm(`One or more widget(s) from the dashboard "${view.title}" are used in ${inaccessibleReport ? 'inaccessible ' : ''}report ${reportTitle}.\n\n Do you really want to delete it?`);
  }

  if (reportIds?.length > 1) {
    const reportTitles = reportIds.map((reportId) => {
      const inAccessibleReport = !reports[reportId];
      const reportTitle = inAccessibleReport ? reportId : `"${reports[reportId].title}"`;

      return `    - ${reportTitle}${inAccessibleReport ? ' (not permitted to see title)' : ''}`;
    }).join('\n');

    // eslint-disable-next-line no-alert
    return window.confirm(`One or more widget(s) from the dashboard "${view.title}" are used in reports:\n${reportTitles}.\n\n Do you really want to delete it?`);
  }

  return null;
};

export default isDashboardUsedInReports;
