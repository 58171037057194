import PropTypes from 'prop-types';
import React from 'react';

import ArchiveLicenseStatusContainer from 'archive/components/ArchiveLicenseStatusContainer';

type Props = {
  config: {
    max_number_of_indices: number,
    index_action: string,
  },
};

const ArchiveRetentionStrategySummary = ({ config: { index_action, max_number_of_indices } }: Props) => {
  return (
    <div>
      <dl>
        <dt>Index retention strategy:</dt>
        <dd>Archive</dd>
        <dt>Max number of indices:</dt>
        <dd>{max_number_of_indices}</dd>
        <dt>Index action:</dt>
        <dd>{index_action}</dd>
      </dl>
      <ArchiveLicenseStatusContainer summary />
    </div>
  );
};

ArchiveRetentionStrategySummary.propTypes = {
  config: PropTypes.shape({
    max_number_of_indices: PropTypes.number.isRequired,
    index_action: PropTypes.string.isRequired,
  }).isRequired,
};

export default ArchiveRetentionStrategySummary;
