import * as Immutable from 'immutable';
import { $PropertyType } from 'utility-types';

type InternalState = {
  id: string,
  name: string,
  title: string,
  membersTotal: number,
  membersExcerpt: Immutable.List<string>,
};

export type GroupJSON = {
  id: string,
  name: string,
  title: string,
  members_total: number,
  members_excerpt: Array<string>,
};

export default class Group {
  _value: InternalState;

  // eslint-disable-next-line no-undef
  constructor(
    id: $PropertyType<InternalState, 'id'>,
    name: $PropertyType<InternalState, 'name'>,
    title: $PropertyType<InternalState, 'title'>,
    membersTotal: $PropertyType<InternalState, 'membersTotal'>,
    membersExcerpt: $PropertyType<InternalState, 'membersExcerpt'>,
  ) {
    this._value = {
      id,
      name,
      title,
      membersTotal,
      membersExcerpt,
    };
  }

  get id() {
    return this._value.id;
  }

  get name() {
    return this._value.name;
  }

  get title() {
    return this._value.title;
  }

  get membersTotal() {
    return this._value.membersTotal;
  }

  get membersExcerpt() {
    return this._value.membersExcerpt;
  }

  toBuilder() {
    const {
      id,
      name,
      title,
      membersTotal,
      membersExcerpt,
    } = this._value;

    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    return new Builder(Immutable.Map({
      id,
      name,
      title,
      membersTotal,
      membersExcerpt,
    }));
  }

  // eslint-disable-next-line no-undef
  static create(
    id: $PropertyType<InternalState, 'id'>,
    name: $PropertyType<InternalState, 'name'>,
    title: $PropertyType<InternalState, 'title'>,
    membersTotal: $PropertyType<InternalState, 'membersTotal'>,
    membersExcerpt: $PropertyType<InternalState, 'membersExcerpt'>,
  ) {
    return new Group(
      id,
      name,
      title,
      membersTotal,
      membersExcerpt,
    );
  }

  toJSON() {
    const {
      id,
      name,
      title,
      membersTotal,
      membersExcerpt,
    } = this._value;

    return {
      id,
      name,
      title,
      members_total: membersTotal,
      members_excerpt: membersExcerpt,
    };
  }

  static fromJSON(value: GroupJSON) {
    const {
      id,
      name,
      title,
      members_total: membersTotal,
      members_excerpt: membersExcerpt,
    } = value;

    return Group.create(
      id,
      name,
      title,
      membersTotal,
      Immutable.List(membersExcerpt),
    );
  }

  // eslint-disable-next-line no-use-before-define
  static builder(): Builder {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    return new Builder();
  }
}

type BuilderState = Immutable.Map<string, any>;

class Builder {
  value: BuilderState;

  constructor(value: BuilderState = Immutable.Map()) {
    this.value = value;
  }

  id(value: $PropertyType<InternalState, 'id'>) {
    return new Builder(this.value.set('id', value));
  }

  name(value: $PropertyType<InternalState, 'name'>) {
    return new Builder(this.value.set('name', value));
  }

  title(value: $PropertyType<InternalState, 'title'>) {
    return new Builder(this.value.set('title', value));
  }

  membersTotal(value: $PropertyType<InternalState, 'membersTotal'>) {
    return new Builder(this.value.set('membersTotal', value));
  }

  membersExcerpt(value: $PropertyType<InternalState, 'membersExcerpt'>) {
    return new Builder(this.value.set('membersExcerpt', value));
  }

  build() {
    const {
      id,
      name,
      title,
      membersTotal,
      membersExcerpt,
    } = this.value.toObject();

    return new Group(
      id,
      name,
      title,
      membersTotal,
      membersExcerpt,
    );
  }
}
