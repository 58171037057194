import * as React from 'react';

import LookupTableParameterForm from 'enterprise/parameters/components/lookup-table/LookupTableParameterForm';
import LookupTableParameter from 'views/logic/parameters/LookupTableParameter';
import LookupTableParameterPreview from 'enterprise/parameters/components/lookup-table/LookupTableParameterPreview';
import { useStore } from 'stores/connect';
import ParametersWithParameterBindings from 'enterprise/parameters/components/ParametersWithParameterBindings';
import { SearchExecutionStateActions } from 'views/stores/SearchExecutionStateStore';
import { SearchActions } from 'views/stores/SearchStore';
import ExecuteViewWithValue from 'enterprise/parameters/components/views/ExecuteViewWithValue';
import CreateParameterDialog from 'enterprise/parameters/creatoractions/CreateParameterDialog';
import ParameterCompletion from 'enterprise/parameters/components/searchbar/completions/ParameterCompletion';
import LicenseCheck from 'enterprise/parameters/components/LicenseCheck';
import { ViewsLicenseActions, ViewsLicenseStore } from 'enterprise/parameters/stores/ViewsLicenseStore';
import type { Completer } from 'views/components/searchbar/SearchBarAutocompletions';
import type { OverrideProps } from 'views/components/WidgetOverrideElements';
import type View from 'views/logic/views/View';
import GenericParameterForm from 'enterprise/parameters/components/generic/GenericParameterForm';
import GenericParameterInput from 'enterprise/parameters/components/generic/GenericParameterInput';
import ValueParameter from 'views/logic/parameters/ValueParameter';
import Parameter from 'views/logic/parameters/Parameter';
import OptionParameter from 'enterprise/parameters/components/option/OptionParameter';
import OptionParameterInput from 'enterprise/parameters/components/option/OptionParameterInput';
import OptionParameterForm from 'enterprise/parameters/components/option/OptionParameterForm';
import FieldSourceForm from 'enterprise/parameters/components/option/FieldSourceForm';
import StaticSourceForm from 'enterprise/parameters/components/option/StaticSourceForm';
import { FieldSource, StaticSource } from 'enterprise/parameters/components/option/OptionSource';
import validLicensePresent from 'license/ValidLicensePresent';

import bindParametersFromQuery from './hooks/BindParametersFromQuery';
import loadParametersFromView from './hooks/LoadParametersFromView';
import noUndeclaredOrMissingParameters from './hooks/NoUndeclaredOrMissingParameters';
import showParameterFormIfMissingValues from './hooks/ShowParameterFormIfMissingValues';
import MissingEnterpriseLicense from './components/MissingEnterpriseLicense';
import ParameterBar from './components/ParameterBar';
import WidgetEditModeParameterBar from './components/WidgetEditModeParameterBar';
import QueryValidationParameterDeclaration from './components/QueryValidationParameterDeclaration';
import CopyWidgetToDashboard from './hooks/CopyWidgetToDashboard';

const EnterpriseLicenseRequired = ({ children }: { children: React.ReactNode }) => (
  <LicenseCheck errorComponent={MissingEnterpriseLicense} hideOnMissing>
    {children}
  </LicenseCheck>
);

const ConnectedMissingEnterpriseLicense = () => {
  const license = useStore(ViewsLicenseStore);

  return <MissingEnterpriseLicense license={license} />;
};

const checkLicenseOrNoop = (fn, fallback = (...args: any[]) => Promise.resolve<React.ReactNode | boolean>(true)) => (...args: any) => (validLicensePresent() ? fn(...args) : fallback(...args));

class CheckLicenseOrNoop implements Completer {
  completer: Completer;

  constructor(completer: Completer) {
    this.completer = completer;
  }

  getCompletions(...args: Parameters<Completer['getCompletions']>) {
    return validLicensePresent() ? this.completer.getCompletions(...args) : [];
  }
}

Parameter.registerSubtype(OptionParameter.type, OptionParameter);

export default {
  'views.elements.header': [() => (
    <EnterpriseLicenseRequired>
      <ParametersWithParameterBindings onChange={SearchExecutionStateActions.bindParameterValue}
                                       onExecute={SearchActions.refresh} />
    </EnterpriseLicenseRequired>
  )],
  'views.elements.validationErrorExplanation': [(props) => (validLicensePresent()
    ? (
      <QueryValidationParameterDeclaration {...props} />
    )
    : null),
  ],
  'views.elements.queryBar': [() => (validLicensePresent() ? <ParameterBar /> : null)],
  'views.overrides.widgetEdit': [({ override }: OverrideProps) => (validLicensePresent()
    ? <WidgetEditModeParameterBar override={override} />
    : null)],
  'views.hooks.executingView': [
    checkLicenseOrNoop(showParameterFormIfMissingValues),
  ],
  'views.hooks.loadingView': [
    () => ViewsLicenseActions.status(),
    checkLicenseOrNoop(
      () => Promise.resolve(true),
      ({ view }: { view: View }): Promise<React.ReactNode | boolean> => {
        const usesParameters = !view.search.parameters.isEmpty();

        // eslint-disable-next-line prefer-promise-reject-errors
        return usesParameters ? Promise.reject<React.ReactNode>(<ConnectedMissingEnterpriseLicense />) : Promise.resolve(true);
      },
    ),
    checkLicenseOrNoop(loadParametersFromView),
    checkLicenseOrNoop(bindParametersFromQuery),
  ],
  'views.hooks.searchRefresh': [
    checkLicenseOrNoop(noUndeclaredOrMissingParameters, () => Promise.resolve(true)),
  ],
  'views.hooks.copyWidgetToDashboard': [
    CopyWidgetToDashboard,
  ],
  'views.completers': [
    new CheckLicenseOrNoop(new ParameterCompletion()),
  ],
  'views.requires.provided': [
    'parameters',
  ],
  valueActions: [{
    type: 'execute-view-with-value',
    title: 'Insert into view',
    component: ExecuteViewWithValue,
    condition: validLicensePresent,
  }],
  creators: [{
    type: 'generic',
    title: 'Parameter',
    component: CreateParameterDialog,
    condition: validLicensePresent,
  }],
  parameterDataTypes: [{
    type: ValueParameter.type,
    title: 'Generic',
    component: GenericParameterInput,
    editComponent: GenericParameterForm,
  }, {
    type: OptionParameter.type,
    title: 'Dropdown',
    component: OptionParameterInput,
    editComponent: OptionParameterForm,
  },
  {
    type: LookupTableParameter.type,
    title: 'Lookup Table',
    component: LookupTableParameterPreview,
    editComponent: LookupTableParameterForm,
    editPermissions: ['lookuptables:read'],
  },
  ],
  'views.parameters.option.sources': [{
    type: 'static',
    title: 'Static Values',
    editComponent: StaticSourceForm,
    factory: StaticSource.createDefault,
  }, {
    type: 'field',
    title: 'Values of field',
    editComponent: FieldSourceForm,
    factory: FieldSource.createDefault,
  }],
};
