import * as React from 'react';
import PropTypes from 'prop-types';
import { cloneDeep, keys, values } from 'lodash';
import { useState, useCallback, useEffect } from 'react';

import { Button, ButtonToolbar, Col, ControlLabel, FormGroup, HelpBlock, Row, Input } from 'components/bootstrap';
import { MultiSelect, Spinner } from 'components/common';
import { getValueFromInput } from 'util/FormsUtils';
import history from 'util/History';
import Routes from 'routing/Routes';
import { UsersActions } from 'stores/users/UsersStore';
import type { Report, ReportScheduling as TReportScheduling } from 'report/types';

import RecurringEventControl from '../common/RecurringEventControl';
import ReportsActions from '../ReportsActions';

type Props = {
  report: Report,
};

type SchedulingState = {
  dataTouched: boolean,
  scheduling: TReportScheduling,
};

type UserOption = { value: string, label: string };

const initialSchedulingState = (scheduling: Report['scheduling']): SchedulingState => ({
  scheduling: {
    active: scheduling.active,
    email_body: scheduling.email_body || '',
    email_subject: scheduling.email_subject || '',
    email_receivers: scheduling.email_receivers,
    frequency: scheduling.frequency,
    frequency_configuration: scheduling.frequency_configuration,
    user_receivers: scheduling.user_receivers,
  } as TReportScheduling,
  dataTouched: false,
});

const useLoadUsers = (setUsers: (users: Array<UserOption>) => void) => {
  useEffect(() => {
    UsersActions.loadUsers().then((newUsers) => {
      const userOptions = newUsers
        .map<UserOption>(({ username, fullName }) => ({
          value: username,
          label: `${username} (${fullName})`,
        }))
        .toArray();
      setUsers(userOptions);
    });
  }, [setUsers]);
};

const ReportScheduling = ({ report }: Props) => {
  const [{ scheduling, dataTouched }, setSchedulingState] = useState<SchedulingState>(initialSchedulingState(report.scheduling));
  const [users, setUsers] = useState<Array<UserOption>>();

  useLoadUsers(setUsers);

  useEffect(() => {
    setSchedulingState(initialSchedulingState(report.scheduling));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [report.id]);

  const saveScheduling = useCallback((event) => {
    event.preventDefault();

    ReportsActions.updateScheduling(report.id, scheduling).then(() => {
      history.push(Routes.pluginRoute('REPORTS'));
    });
  }, [report.id, scheduling]);

  const onCancel = useCallback(() => {
    // eslint-disable-next-line no-alert
    if (!dataTouched || window.confirm('Do you really want to abandon this page and lose your changes? This action cannot be undone.')) {
      history.push(Routes.pluginRoute('REPORTS'));
    }
  }, [dataTouched]);

  const updateReport = useCallback((key, value) => {
    const updatedScheduling = cloneDeep(scheduling);

    if (Array.isArray(key)) {
      key.forEach((k, idx) => {
        updatedScheduling[k] = value[idx];
      });
    } else {
      updatedScheduling[key] = value;
    }

    setSchedulingState((oldState) => ({ ...oldState, scheduling: updatedScheduling, dataTouched: true }));
  }, [scheduling]);

  const handleInputChange = useCallback((event) => {
    updateReport(event.target.name, getValueFromInput(event.target));
  }, [updateReport]);

  const handleMultiSelectChange = useCallback((name) => {
    return (value) => {
      updateReport(name, value === '' ? [] : value.split(','));
    };
  }, [updateReport]);

  const handleRecurringControlChange = useCallback((update) => {
    updateReport(keys(update), values(update));
  }, [updateReport]);

  if (!users) {
    return <Spinner />;
  }

  return (
    <Row>
      <Col md={12}>
        <h3>Scheduling</h3>
        <p>Choose the recipients for this report and when the report should be scheduled for delivery.</p>
        <form onSubmit={saveScheduling}>
          <Input id="active"
                 name="active"
                 type="checkbox"
                 label="Send this report automatically on a regular basis"
                 checked={scheduling.active}
                 onChange={handleInputChange} />
          <RecurringEventControl disabled={!scheduling.active}
                                 frequency={scheduling.frequency}
                                 frequency_configuration={scheduling.frequency_configuration}
                                 onChange={handleRecurringControlChange} />
          <Input id="email_subject"
                 name="email_subject"
                 type="text"
                 label="Email subject"
                 help="Set an email subject to use when sending the report."
                 value={scheduling.email_subject}
                 onChange={handleInputChange}
                 disabled={!scheduling.active}
                 required />
          <Input id="email_body"
                 name="email_body"
                 type="textarea"
                 label="Email body"
                 help="Add an email body to use when sending the report."
                 value={scheduling.email_body}
                 onChange={handleInputChange}
                 disabled={!scheduling.active}
                 rows={6}
                 required />
          <FormGroup id="user-recipients-select">
            <ControlLabel>User recipients</ControlLabel>
            <MultiSelect id="user_receivers"
                         value={Array.isArray(scheduling.user_receivers) ? scheduling.user_receivers.join(',') : ''}
                         placeholder="Type username"
                         options={users}
                         onChange={handleMultiSelectChange('user_receivers')}
                         disabled={!scheduling.active} />
            <HelpBlock>Select Graylog usernames that will receive this report.</HelpBlock>
          </FormGroup>
          <FormGroup id="email-recipients-select">
            <ControlLabel>Email recipients</ControlLabel>
            <MultiSelect id="email_receivers"
                         value={Array.isArray(scheduling.email_receivers) ? scheduling.email_receivers.join(',') : ''}
                         addLabelText={'Add email "{label}"?'}
                         placeholder="Type email address"
                         options={[]}
                         onChange={handleMultiSelectChange('email_receivers')}
                         disabled={!scheduling.active}
                         allowCreate />
            <HelpBlock>Add email addresses that will receive this report.</HelpBlock>
          </FormGroup>

          <ButtonToolbar>
            <Button type="submit" bsStyle="success">Update scheduling</Button>
            <Button className="cancel" onClick={onCancel}>Cancel</Button>
          </ButtonToolbar>
        </form>
      </Col>
    </Row>
  );
};

ReportScheduling.propTypes = {
  report: PropTypes.object.isRequired,
};

export default ReportScheduling;
