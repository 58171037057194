import Reflux from 'reflux';
import * as Immutable from 'immutable';
import fetch from 'logic/rest/FetchProvider';
import type { Store } from 'stores/StoreTypes';
import { singletonStore } from 'logic/singleton';
import { qualifyUrl } from 'util/URLUtils';

import GroupSyncBackend from '../../logic/directoryServices/GroupSyncBackend';
import type { GroupSyncBackendJSON } from '../../logic/directoryServices/GroupSyncBackend';
import GroupSyncActions from '../../actions/directoryServices/GroupSyncActions';
import type { LoadGroupsResponse, LoadGroupsPayload } from '../../actions/directoryServices/GroupSyncActions';
import Group from '../../logic/directoryServices/Group';

const apiPrefix = '/plugins/org.graylog.plugins.security/team-sync';
const ApiRoutes = {
  GroupsApiController: {
    loadGroups: () => ({ url: `${apiPrefix}/test/backend` }),
    load: (backendId: string) => ({ url: `${apiPrefix}/services/${backendId}/backend` }),
    update: (backendId: string) => ({ url: `${apiPrefix}/services/${backendId}/backend` }),
    create: (backendId: string) => ({ url: `${apiPrefix}/services/${backendId}/backend` }),
    delete: (backendId: string) => ({ url: `${apiPrefix}/services/${backendId}/backend` }),
  },
};

const GroupSyncStore: Store<void> = singletonStore(
  'enterprise.authentication.directoryService.GroupSync',
  () => Reflux.createStore({
    listenables: [GroupSyncActions],

    loadGroups(payload: LoadGroupsPayload): Promise<LoadGroupsResponse> {
      const promise = fetch('POST', qualifyUrl(ApiRoutes.GroupsApiController.loadGroups().url), payload).then(
        (response) => {
          if (response) {
            return ({
              success: response.success,
              message: response.message,
              errors: Immutable.List(response.errors),
              list: response.result.groups ? Immutable.List(response.result.groups.map((g) => Group.fromJSON(g))) : Immutable.List(),
            });
          }

          return null;
        },
      );

      GroupSyncActions.loadGroups.promise(promise);

      return promise;
    },

    create(backendId: string, payload: GroupSyncBackendJSON): Promise<LoadGroupsResponse> {
      const promise = fetch('POST', qualifyUrl(ApiRoutes.GroupsApiController.create(backendId).url), payload).then(
        (response: { backend: GroupSyncBackendJSON }) => ({
          backend: GroupSyncBackend.fromJSON(response.backend),
        }),
      );

      GroupSyncActions.create.promise(promise);

      return promise;
    },

    load(backendId: string): Promise<LoadGroupsResponse> {
      const promise = fetch('GET', qualifyUrl(ApiRoutes.GroupsApiController.create(backendId).url)).then(
        (response: { backend: GroupSyncBackendJSON }) => ({
          backend: GroupSyncBackend.fromJSON(response.backend),
        }),
      );

      GroupSyncActions.load.promise(promise);

      return promise;
    },

    update(backendId: string, payload: GroupSyncBackendJSON): Promise<LoadGroupsResponse> {
      const promise = fetch('PUT', qualifyUrl(ApiRoutes.GroupsApiController.update(backendId).url), payload).then(
        (response: { backend: GroupSyncBackendJSON }) => ({
          backend: GroupSyncBackend.fromJSON(response.backend),
        }),
      );

      GroupSyncActions.update.promise(promise);

      return promise;
    },

    delete(backendId: string): Promise<LoadGroupsResponse> {
      const promise = fetch('DELETE', qualifyUrl(ApiRoutes.GroupsApiController.create(backendId).url)).then(
        (response: { backend: GroupSyncBackendJSON }) => ({
          backend: GroupSyncBackend.fromJSON(response.backend),
        }),
      );

      GroupSyncActions.delete.promise(promise);

      return promise;
    },
  }),
);

export { GroupSyncActions, GroupSyncStore };
export default GroupSyncStore;
