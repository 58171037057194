import * as React from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import { useState } from 'react';

import UserDateTimeProvider from 'contexts/UserDateTimeProvider';
import { Col, Row } from 'components/bootstrap';
import withParams from 'routing/withParams';
import type { Report, ReportLayout } from 'report/types';
import ReportWidgets from 'report/report-render-page/ReportWidgets';
import { REPORT_TZ_FALLBACK, DEFAULT_LAYOUT } from 'report/Constants';
import useReportAndLogo from 'report/hooks/useReportAndLogo';

import ReportParameters from './ReportParameters';

const GlobalStyles = createGlobalStyle<ReportLayout>`
  html body {
    margin-top: 0;
    font-family: serif;
    font-size: 18px;
  }
  
  #footer {
    display: none;
  }

  @media print {
    @page {
      size: A4 ${({ orientation }) => (orientation === 'landscape' ? 'landscape' : '')};
      margin: 1in;
    }
  }
`;

const Container = styled.div`
  width: 210mm;
`;

const CoverPage = styled.div`
  padding-top: 30%;

  h1 {
    font-size: 2em;
    text-align: center;
    margin-bottom: 0.8em;
  }

  h2 {
    color: #555 !important;
    font-size: 1.6em;
    text-align: center;
  }
`;

const Logo = styled.div`
  margin-left: auto;
  margin-top: 100px;
  padding: 10px;
  text-align: center;

  img {
    max-height: 150px;
    max-width: 150px;
  }
`;

export const Description = styled.p`
  white-space: pre-wrap;
`;

const PageBreak = styled.div`
  page-break-after: always;
`;

type Props = {
  params: {
    reportId: string,
  }
}

const useUpdateRenderComplete = (widgets: Report['widgets'] = []) => {
  const [renderedWidgets, setRenderedWidgets] = useState<Array<string>>([]);

  const onWidgetRenderComplete = (widgetId: string) => () => {
    if (!renderedWidgets.includes(widgetId)) {
      setRenderedWidgets((prevWidgets) => ([...prevWidgets, widgetId]));
    }
  };

  return {
    renderComplete: widgets?.length === 0 || widgets.length === renderedWidgets.length,
    onWidgetRenderComplete,
  };
};

const ReportRenderPage = ({ params: { reportId } }: Props) => {
  const { report, reportLogo } = useReportAndLogo(reportId);
  const { onWidgetRenderComplete, renderComplete } = useUpdateRenderComplete(report?.widgets);

  if (!report) {
    return null;
  }

  const reportTz = report.timezone ?? REPORT_TZ_FALLBACK;
  const reportLayout = report.layout ?? DEFAULT_LAYOUT;

  return (
    <UserDateTimeProvider tz={reportTz}>
      <GlobalStyles orientation={reportLayout.orientation} height={reportLayout.height} width={reportLayout.width} />
      <Row>
        <Col md={12}>
          <Container>
            <CoverPage>
              <h1>{report.title}</h1>
              <h2 className="has-bm">{report.subtitle}</h2>
              <p className="text-center">All times are displayed in the time zone {reportTz}.</p>
              {reportLogo && <Logo><img src={reportLogo} alt="report-logo" /></Logo>}
            </CoverPage>
            <PageBreak />
            <Description>{report.description}</Description>
            {report.parameterValues && (
              <ReportParameters parameters={report.parameters}
                                parameterValues={report.parameterValues} />
            )}
            <ReportWidgets widgets={report.widgets}
                           positions={report.positions}
                           parameterValues={report.parameterValues}
                           onWidgetRenderComplete={onWidgetRenderComplete} />

            {renderComplete && <div id="render-complete" style={{ display: 'none' }} />}
          </Container>
        </Col>
      </Row>
    </UserDateTimeProvider>
  );
};

export default withParams(ReportRenderPage);
