import React, { ReactNode, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import ForwardersContext, { getForwarderContextById } from './ForwardersContext';

import { ForwardersActions, ForwarderResponse } from '../stores/ForwardersStore';
import { Forwarder, ForwardersContext as ForwardersContextType } from '../Types';

type Props = {
  children: ReactNode,
  forwarderId: string,
};

const SingleForwarderProvider = ({ forwarderId, children }: Props) => {
  const [forwarder, setForwarder] = useState<Forwarder | void>();
  const [forwarderContext, setForwarderContext] = useState<ForwardersContextType | void>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const fetchPromise = useRef<Promise<ForwarderResponse> | void>();
  const timeout = useRef<number | void>();

  useEffect(() => {
    const fetchForwarder = () => {
      if (fetchPromise.current) {
        return;
      }

      setIsLoading(true);

      fetchPromise.current = ForwardersActions.get(forwarderId)
        .then((response: ForwarderResponse) => {
          setForwarder(response.forwarder);
          setForwarderContext(response.forwarderContext);

          return response;
        })
        .finally(() => {
          fetchPromise.current = undefined;
          setIsLoading(false);
        });
    };

    fetchForwarder();

    timeout.current = window.setInterval(() => {
      fetchForwarder();
    }, 5000);

    return () => {
      fetchPromise.current = undefined;
      setIsLoading(false);

      if (timeout.current) {
        clearInterval(timeout.current);
      }
    };
  }, [forwarderId]);

  const effectiveForwardersContext = forwarderContext || {};

  return (
    <ForwardersContext.Provider value={{
      forwarders: forwarder ? [forwarder] : [],
      forwardersContext: effectiveForwardersContext,
      isLoading: isLoading,
      getForwarderContext: getForwarderContextById(effectiveForwardersContext),
    }}>
      {children}
    </ForwardersContext.Provider>
  );
};

SingleForwarderProvider.propTypes = {
  children: PropTypes.node.isRequired,
  forwarderId: PropTypes.string.isRequired,
};

export default SingleForwarderProvider;
