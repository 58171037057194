import * as React from 'react';
import { useState } from 'react';
import moment from 'moment';

import { isPermitted } from 'util/PermissionsMixin';
import LicenseStatusStore from 'license/LicenseStatusStore';
import connect from 'stores/connect';
import Store from 'logic/local-storage/Store';
import { CurrentUserStore } from 'stores/users/CurrentUserStore';
import type { UserJSON } from 'logic/users/User';

import LicenseViolationNotification from './LicenseViolationNotification';
import LicenseExpirationNotification from './LicenseExpirationNotification';
import LicenseExpirationWarningNotification from './LicenseExpirationWarningNotification';
import LicenseTrialNotification from './LicenseTrialNotification';
import LicenseTrafficViolationNotificationContainer from './LicenseTrafficViolationNotificationContainer';

type LicenseStatus = {
  violated: boolean,
  expired: boolean,
  expiredSince: number,
  expirationUpcoming: boolean,
  expiresIn: number,
  trial: boolean,
  trialExpiresIn: number,
  trafficViolation: boolean,
};
type ExpirationWarningStatus = {
  show: boolean,
  enableReminderButton: boolean,
  showTrialWarning: boolean,
};
type Props = {
  licenseStatus: LicenseStatus,
  currentUser: UserJSON,
};

const REMINDER_STORAGE_KEY = 'license:expiration-reminder';
const TRIAL_REMINDER_STORAGE_KEY = 'license:trial-reminder';

const _getExpirationWarningStatus = (licenseStatus: LicenseStatus): ExpirationWarningStatus => {
  const newStatus = { show: true, enableReminderButton: false, showTrialWarning: true };
  const { violated, expired, expiresIn } = licenseStatus;

  if (violated || expired) {
    // Shortcut to avoid localStorage access cost when we don't display the warning anyway
    return newStatus;
  }

  const value = Store.get(REMINDER_STORAGE_KEY);
  const expirationDate = moment().add(expiresIn, 'seconds');
  const almostExpired = moment().isAfter(expirationDate.subtract(5, 'days'));

  const trialMuteValue = Store.get(TRIAL_REMINDER_STORAGE_KEY);

  // If the user muted the trial warning, show it again after 1 day
  if (trialMuteValue && trialMuteValue.mutedAt) {
    newStatus.showTrialWarning = moment().isAfter(moment(trialMuteValue.mutedAt).add(1, 'days'));
  }

  // Always show the warning if the license has almost expired. Otherwise only show it if it hasn't been dismissed.
  newStatus.show = almostExpired || !value;
  // Do not allow the user to dismiss the warning if the license has almost expired.
  newStatus.enableReminderButton = !almostExpired;

  return newStatus;
};

const LicenseNotificationContainer = ({ licenseStatus, currentUser }: Props) => {
  const [expirationWarningStatus, setExpirationWarningStatus] = useState<ExpirationWarningStatus>(_getExpirationWarningStatus(licenseStatus));

  const handleExpirationReminder = () => {
    Store.set(REMINDER_STORAGE_KEY, { createdAt: moment().toISOString() });
    setExpirationWarningStatus(_getExpirationWarningStatus(licenseStatus));
  };

  const handleMuteTrialReminder = () => {
    Store.set(TRIAL_REMINDER_STORAGE_KEY, { mutedAt: moment().toISOString() });

    setExpirationWarningStatus(_getExpirationWarningStatus(licenseStatus));
  };

  const children = [];
  const { trial, trialExpiresIn, violated, expired, expiresIn, expiredSince, expirationUpcoming, trafficViolation } = licenseStatus;

  if (trial && expirationWarningStatus.showTrialWarning) {
    const duration = moment.duration(trialExpiresIn, 'seconds').humanize();

    children.push(<LicenseTrialNotification currentUser={currentUser}
                                            expiresIn={duration}
                                            key={1}
                                            onMuteClick={handleMuteTrialReminder} />);
  }

  if (violated) {
    children.push(<LicenseViolationNotification currentUser={currentUser} key={2} />);
  }

  if (trafficViolation && isPermitted(currentUser.permissions, ['notifications:read'])) {
    children.push(<LicenseTrafficViolationNotificationContainer currentUser={currentUser} key={3} />);
  }

  if (expired) {
    const duration = moment.duration(expiredSince, 'seconds').humanize();
    children.push(<LicenseExpirationNotification currentUser={currentUser} expiredSince={duration} key={4} />);
  } else if (expirationUpcoming && expiresIn > 0 && expirationWarningStatus.show) {
    const duration = moment.duration(expiresIn, 'seconds').humanize();

    children.push(<LicenseExpirationWarningNotification expiresIn={duration}
                                                        enableReminderButton={expirationWarningStatus.enableReminderButton}
                                                        onReminderClick={handleExpirationReminder}
                                                        key={5} />);
  }

  return (
    <div>
      {children}
    </div>
  );
};

export default connect(LicenseNotificationContainer,
  { licenseStatus: LicenseStatusStore, currentUser: CurrentUserStore },
  ({ currentUser, ...otherProps }) => ({
    currentUser: currentUser ? currentUser.currentUser : currentUser,
    ...otherProps,
  }));
