import * as React from 'react';
import * as Immutable from 'immutable';
import { isNil } from 'lodash';

import type { ViewHook } from 'views/logic/hooks/ViewHook';
import RequiredParametersForViewForm from 'enterprise/parameters/components/RequiredParametersForViewForm';
import ValueParameter from 'views/logic/parameters/ValueParameter';
import ParameterBinding from 'views/logic/parameters/ParameterBinding';
import SearchExecutionState from 'views/logic/search/SearchExecutionState';
import { SearchExecutionStateStore } from 'views/stores/SearchExecutionStateStore';
import Parameter from 'views/logic/parameters/Parameter';

const parameterHasValue = (parameter: ValueParameter, executionState: SearchExecutionState) => (
  executionState.parameterBindings.has(parameter.name)
  && !isNil(executionState.parameterBindings.get(parameter.name).value)
  && executionState.parameterBindings.get(parameter.name).value !== ''
);

const showParameterFormIfMissingValues: ViewHook = async ({ view: { search }, view, retry }) => {
  const executionState: SearchExecutionState = SearchExecutionStateStore.getInitialState();
  const { parameters }: { parameters: Immutable.Set<ValueParameter> } = search;
  const { requiredParameters }: { requiredParameters: Immutable.Set<ValueParameter> } = search;
  const requiresParameters = !requiredParameters
    .filter((p) => !parameterHasValue(p as ValueParameter, executionState))
    .isEmpty();

  if (requiresParameters) {
    const boundParameters = Immutable.Map<string, Parameter>(parameters
      .filter((p) => (!p.optional && p.needsBinding))
      .map((p: ValueParameter) => {
        const { value } = executionState.parameterBindings.get(p.name, ParameterBinding.empty());

        return value ? p.toBuilder().binding(ParameterBinding.forValue(value)).build() : p;
      })
      .map((p) => [p.name, p]));

    // eslint-disable-next-line no-throw-literal
    throw () => <RequiredParametersForViewForm parameters={boundParameters} onSubmit={retry} view={view} />;
  }

  return true;
};

export default showParameterFormIfMissingValues;
