/*
 * Copyright (C) 2020 Graylog, Inc.
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the Server Side Public License, version 1,
 * as published by MongoDB, Inc.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * Server Side Public License for more details.
 *
 * You should have received a copy of the Server Side Public License
 * along with this program. If not, see
 * <http://www.mongodb.com/licensing/server-side-public-license>.
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'components/common/router';
import { Alert, Col, Row } from 'components/bootstrap';
import { IfPermitted } from 'components/common';
import Routes from 'routing/Routes';
import AppConfig from 'util/AppConfig';

import { LicenseStatusPropType } from 'archive/propTypes';
import type { LicenseStatus as LicenseStatusType } from 'archive/types';

const isCloud = AppConfig.isCloud();

const StyledH4 = styled.h4`
  font-weight: bold;
  margin-bottom: 5px;
`;

const LicenseStatus = () => (
  <Alert bsStyle="danger">
    <StyledH4>Customization is disabled</StyledH4>
    <p>
      Customization is disabled because there is no valid Enterprise license.
    </p>
    {isCloud
      ? (<>Contact your Graylog account manager.</>)
      : (
        <IfPermitted permissions="licenses:create">
          <p>
            See <Link to={Routes.pluginRoute('SYSTEM_LICENSES')}>Licenses page</Link> for details.
          </p>
        </IfPermitted>
      )}
  </Alert>
);

type Props = {
  licenseStatus: LicenseStatusType,
  summary?: boolean,
  rowClass?: string,
  colWidth?: number,
};

const CustomizationLicenseStatus = ({ licenseStatus, summary, rowClass, colWidth }: Props) => {
  const { status, missing } = licenseStatus;

  if (!missing && (status && status.valid)) {
    return null;
  }

  if (summary) {
    return <LicenseStatus />;
  }

  return (
    <Row className={rowClass}>
      <Col md={colWidth}>
        <LicenseStatus />
      </Col>
    </Row>
  );
};

CustomizationLicenseStatus.propTypes = {
  licenseStatus: LicenseStatusPropType.isRequired,
  colWidth: PropTypes.number,
  rowClass: PropTypes.string,
  summary: PropTypes.bool,
};

CustomizationLicenseStatus.defaultProps = {
  colWidth: 12,
  rowClass: 'content',
  summary: false,
};

export default CustomizationLicenseStatus;
