import React, { useState } from 'react';
import PropTypes from 'prop-types';

import OidcBackendMetaContext from 'authentication/components/oidc/config/components/OidcBackendMetaContext';

type Props = {
  children: React.ReactNode,
  defaultValue: { backendId: string, backendGroupSyncIsActive: boolean, licenseIsValid: boolean},
};

const OidcBackendMetaProvider = ({ children, defaultValue }: Props) => {
  const [contextValue, setContextValue] = useState<{ backendId: string, backendGroupSyncIsActive: boolean, licenseIsValid: boolean } | undefined>(defaultValue);

  return (
    <OidcBackendMetaContext.Provider value={{ ...contextValue, setContextValue }}>
      {children}
    </OidcBackendMetaContext.Provider>
  );
};

OidcBackendMetaProvider.propTypes = {
  children: PropTypes.node.isRequired,
  defaultValue: PropTypes.shape({
    backendId: PropTypes.string,
    backendGroupSyncIsActive: PropTypes.bool,
    licenseIsValid: PropTypes.bool,
  }).isRequired,
};

export { OidcBackendMetaContext };
export default OidcBackendMetaProvider;
