import * as React from 'react';
import { useRef, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';

import useUpdateScrollPosition from './useUpdateScrollPosition';
import ScrollPositionContext from './ScrollPositionContext';
import type { LogViewState } from './LogViewStateProvider';

import { TableRef, PageRefs } from '../LogViewWidget';

const _setInitialScrollPosition = (tableRef) => {
  if (tableRef.current) {
    // eslint-disable-next-line no-param-reassign
    tableRef.current.scrollTop = tableRef.current.scrollHeight - tableRef.current.clientHeight;
  }
};

type Props = {
  children: React.ReactNode,
  listStateStatus: LogViewState['status'],
  pageRefs: PageRefs,
  scrollPositionUpdate: LogViewState['scrollPositionUpdate'],
  tableRef: TableRef,
}

const ScrollPositionContextProvider = ({ children, tableRef, pageRefs, scrollPositionUpdate, listStateStatus }: Props) => {
  // We update last position when we update the list state
  const lastPositionRef = useRef(0);
  const finishedScrollPositionUpdateRef = useRef(true);

  const setFinishedScrollPositionUpdate = (finished: boolean) => {
    finishedScrollPositionUpdateRef.current = finished;
  };

  const setLastPosition = (position: number) => {
    lastPositionRef.current = position;
  };

  useLayoutEffect(() => {
    if (listStateStatus === 'initial' || listStateStatus === 'reset') {
      _setInitialScrollPosition(tableRef);
    }
  }, [tableRef, listStateStatus]);

  useUpdateScrollPosition({
    lastPositionRef,
    pageRefs,
    tableRef,
    scrollPositionUpdate,
    setFinishedScrollPositionUpdate,
  });

  const contextValue = {
    finishedScrollPositionUpdateRef,
    lastPositionRef,
    setFinishedScrollPositionUpdate,
    setLastPosition,
  };

  return (
    <ScrollPositionContext.Provider value={contextValue}>
      {children}
    </ScrollPositionContext.Provider>
  );
};

ScrollPositionContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ScrollPositionContextProvider;
