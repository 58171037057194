import { isArray, isString } from 'lodash';

export const parseIfArrayValue = (fieldValue: any): Array<string> | undefined => {
  if (isArray(fieldValue)) {
    return fieldValue;
  }

  if (isString(fieldValue)) {
    try {
      const parsedFieldValue = JSON.parse(fieldValue);

      if (isArray(parsedFieldValue)) {
        return parsedFieldValue as Array<string>;
      }
      // eslint-disable-next-line no-empty
    } catch (ignored) {}
  }

  return undefined;
};

export const isArrayValue = (fieldValue: any): boolean => {
  return !!parseIfArrayValue(fieldValue);
};
