import Reflux from 'reflux';

const ArchiveActions = Reflux.createActions({
  searchPaginated: { asyncResult: true },
  createArchive: { asyncResult: true },
  restoreArchive: { asyncResult: true },
  deleteArchive: { asyncResult: true },
  rebuildCatalog: { asyncResult: true },
  availability: { asyncResult: true },
  checkOutputTemplate: { asyncResult: true },
  exportFilenames: { asyncResult: true },
});

export default ArchiveActions;
