import * as React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'components/common/router';
import { Alert } from 'components/bootstrap';
import Routes from 'routing/Routes';
import { isPermitted } from 'util/PermissionsMixin';
import type { UserJSON } from 'logic/users/User';

type Props = {
  currentUser: UserJSON,
  expiredSince: string
}

/**
 * Renders an alert for expired licenses.
 */
const LicenseExpirationNotification = ({ expiredSince, currentUser }: Props) => (
  <Alert bsStyle="danger" className="has-bm">
    <h4 style={{ marginBottom: 5 }}><strong>Graylog Enterprise License Expired</strong></h4>
    <p>
      Your license <strong>expired {expiredSince} ago</strong> and Graylog Enterprise features have been disabled.{' '}
      {isPermitted(currentUser.permissions, 'licenses:create') ? (
        <span>
          Go to the <Link to={Routes.pluginRoute('SYSTEM_LICENSES')}>Licenses page</Link> for
          more information or contact your Graylog account manager.
        </span>
      ) : (
        <span>Contact your Graylog account manager for more information.</span>
      )}
    </p>
  </Alert>
);

LicenseExpirationNotification.propTypes = {
  /** Duration string since a license expired. (e.g. "3 months") */
  expiredSince: PropTypes.string.isRequired,
  currentUser: PropTypes.object.isRequired,
};

export default LicenseExpirationNotification;
