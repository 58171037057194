/*
 * Copyright (C) 2020 Graylog, Inc.
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the Server Side Public License, version 1,
 * as published by MongoDB, Inc.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * Server Side Public License for more details.
 *
 * You should have received a copy of the Server Side Public License
 * along with this program. If not, see
 * <http://www.mongodb.com/licensing/server-side-public-license>.
 */
import * as React from 'react';
import { useState } from 'react';
import styled from 'styled-components';
import AuthenticationDomain from 'domainActions/authentication/AuthenticationDomain';
import { Button } from 'components/bootstrap';
import { Spinner } from 'components/common';
import ConnectionErrors, { NotificationContainer } from 'components/authentication/directoryServices/BackendWizard/ConnectionErrors';
import { formHasErrors } from 'util/FormsUtils';

import { OidcSubmitPayload } from './types';

const StyledHeader = styled.h4`
  margin-bottom: 10px;
`;

type Props = {
  backendId: string;
  prepareSubmitPayload: () => OidcSubmitPayload,
  validateForm: (values?: any) => Promise<any>
};

const ServerConnectionTest = ({ prepareSubmitPayload, validateForm, backendId }: Props) => {
  const [{ loading, success, message, errors }, setConnectionStatus] = useState({ loading: false, success: false, message: undefined, errors: undefined });

  const _connectionCheck = () => {
    const payload = prepareSubmitPayload();
    setConnectionStatus({ loading: true, message: undefined, errors: undefined, success: false });

    AuthenticationDomain.testConnection({ backend_configuration: payload, backend_id: backendId }).then((response) => {
      if (response.success === false) {
        const requestErrors = [response.message, ...response.errors];
        setConnectionStatus({ loading: false, message: undefined, errors: requestErrors, success: false });

        return;
      }

      setConnectionStatus({ loading: false, message: response?.message, success: response?.success, errors: response?.errors });
    });
  };

  const _handleConnectionCheck = () => {
    validateForm().then((e) => {
      if (!formHasErrors(e)) {
        _connectionCheck();
      }
    });
  };

  return (
    <>
      <StyledHeader>Server Connection Check</StyledHeader>
      <p>
        Performs a background connection check with the address and credentials defined in the step &quot;Server Configuration&quot;.
      </p>
      <Button onClick={_handleConnectionCheck} type="button">
        {loading ? <Spinner delay={0} text="Test Server Connection" /> : 'Test Server Connection'}
      </Button>
      {success && (
        <NotificationContainer bsStyle="success">
          <b>{message}</b>
        </NotificationContainer>
      )}
      {(errors && errors.length >= 1) && (
        <ConnectionErrors errors={errors} message={message} />
      )}
    </>
  );
};

export default ServerConnectionTest;
