import * as React from 'react';
import { useState } from 'react';
import styled, { css, DefaultTheme } from 'styled-components';
import { Notification } from 'theme/types';
import SectionComponent from 'components/common/Section/SectionComponent';
import { Button, Label, Table } from 'components/bootstrap';
import { Icon } from 'components/common';
import ConfirmDialog from 'components/common/ConfirmDialog';

import usePublicNotifications from './usePublicNotifications';
import ModalForm from './ModalForm';

const StyledTable = styled(Table)`
  .form-group {
    margin: 0;
    
    .checkbox {
      margin: 0;
    }
  }
  
  tbody > tr > td {
    vertical-align: middle;
  }
`;

const TitleTH = styled.th`
  width: 35%;
`;

const BooleanTH = styled.th`
  width: 8%;
  text-align: center;
`;

const VariantTH = styled.th`
  min-width: 10%;
  text-align: center;
`;

const ActionsTD = styled.td`
  text-align: right;
`;

const OptionsTD = styled.td`
  text-align: center;
`;

const BooleanIcon = styled(Icon).attrs(({ $enabled }: {$enabled: boolean}) => ({
  name: $enabled ? 'check' : 'times',
}))(({ $enabled, theme }: {$enabled: boolean, theme: DefaultTheme}) => css`
  color: ${$enabled ? theme.colors.variant.success : theme.colors.variant.danger};
`);

const PublicNotifications = () => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [deletedData, setDeletedData] = useState<{title:string, id:number}>({ title: undefined, id: undefined });
  const [showConfirmDelete, setShowConfirmDelete] = useState<boolean>(false);
  const [editing, setEditing] = useState<boolean>(false);
  const [editingID, setEditingID] = useState<string>(undefined);
  const { notifications, onCreatePublicNotification, onDeletePublicNotification, onUpdatePublicNotification } = usePublicNotifications();
  const isEmpty = Object.keys(notifications).length === 0;

  const toggleShowConfirmDelete = () => {
    setShowConfirmDelete(!showConfirmDelete);
  };

  const toggleShowModal = () => {
    setShowModal(!showModal);
    setEditing(false);
    setEditingID(undefined);
  };

  const handleCreateNotification = (values): Promise<void | Notification> => {
    return onCreatePublicNotification(values).then(toggleShowModal);
  };

  const handleEditNotification = (values): Promise<void | Notification> => {
    return onUpdatePublicNotification(editingID, values).then(toggleShowModal);
  };

  const showConfirmation = ({ title, id }) => {
    setDeletedData({ title, id });
    toggleShowConfirmDelete();
  };

  const showEditModal = (id) => {
    setEditing(true);
    setEditingID(id);
    setShowModal(!showModal);
  };

  const handleDelete = (id) => {
    return onDeletePublicNotification(id).then(toggleShowConfirmDelete);
  };

  return (
    <SectionComponent title="Public Notifications" headerActions={<Button onClick={toggleShowModal}>Create</Button>}>
      {isEmpty ? (
        <strong>No notifications available.</strong>
      ) : (
        <StyledTable striped condensed>
          <thead>
            <tr>
              <TitleTH>Title</TitleTH>
              <BooleanTH>Active</BooleanTH>
              <BooleanTH>Dismissible</BooleanTH>
              <BooleanTH>At Login</BooleanTH>
              <BooleanTH>Global</BooleanTH>
              <VariantTH>Variant</VariantTH>
              <th>&nbsp;</th>
            </tr>
          </thead>
          <tbody>
            {
            Object.keys(notifications).map((notificationID) => {
              const currentNotification = notifications[notificationID];

              return (
                <tr key={notificationID}>
                  <td>{currentNotification.title}</td>
                  <OptionsTD>
                    <BooleanIcon $enabled={currentNotification.isActive} />
                  </OptionsTD>
                  <OptionsTD>
                    <BooleanIcon $enabled={currentNotification.isDismissible} />
                  </OptionsTD>
                  <OptionsTD>
                    <BooleanIcon $enabled={currentNotification.atLogin} />
                  </OptionsTD>
                  <OptionsTD>
                    <BooleanIcon $enabled={currentNotification.isGlobal} />
                  </OptionsTD>
                  <OptionsTD>
                    <Label bsStyle={currentNotification.variant}>{currentNotification.variant.toUpperCase()}</Label>
                  </OptionsTD>
                  <ActionsTD>
                    <Button onClick={() => showEditModal(notificationID)} bsStyle="link" bsSize="sm">Edit</Button>
                    <Button onClick={() => { showConfirmation({ title: currentNotification.title, id: notificationID }); }} bsStyle="link" bsSize="sm">Delete</Button>
                  </ActionsTD>
                </tr>
              );
            })
          }
          </tbody>
        </StyledTable>
      )}

      <ModalForm show={showModal}
                 toggleShowModal={toggleShowModal}
                 onSubmit={editing ? handleEditNotification : handleCreateNotification}
                 buttonValue={editing ? 'Update' : 'Save'}
                 initialValues={editing ? notifications[editingID] : {
                   title: '',
                   shortMessage: '',
                   longMessage: '',
                   isActive: true,
                   isDismissible: false,
                   atLogin: false,
                   isGlobal: false,
                   variant: 'default',
                   hiddenTitle: false,
                 }} />

      <ConfirmDialog show={showConfirmDelete}
                     onConfirm={() => handleDelete(deletedData.id)}
                     onCancel={toggleShowConfirmDelete}
                     title="Are you sure?">
        <>
          <p>You are about to permanently delete <strong>{deletedData.title}</strong>.</p>
          <p>Do you wish to continue?</p>
        </>
      </ConfirmDialog>
    </SectionComponent>
  );
};

export default PublicNotifications;
