import * as React from 'react';
import PropTypes from 'prop-types';

import StringUtils from 'util/StringUtils';
import Select from 'components/common/Select';

type Props = {
  id: string,
  value?: string,
  disabled?: boolean,
  required?: boolean,
  onChange: (newDay: string) => void,
}

const DayOfWeekSelect = ({ id, value, disabled, required, onChange }: Props) => {
  const dow = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'].map((day) => {
    return { value: day, label: StringUtils.capitalizeFirstLetter(day) };
  });

  return (
    <Select id={id}
            options={dow}
            value={value}
            placeholder="Select day of the week"
            disabled={disabled}
            onChange={onChange}
            required={required} />
  );
};

DayOfWeekSelect.propTypes = {
  id: PropTypes.string.isRequired,
  value: PropTypes.string,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};

DayOfWeekSelect.defaultProps = {
  value: undefined,
  disabled: false,
  required: false,
};

export default DayOfWeekSelect;
