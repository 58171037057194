import React from 'react';
import PropTypes from 'prop-types';

import { ForwarderMetric, ForwarderMetricPropType } from '../Types';
import formatMetric from '../util/formatMetric';

type Props = {
  metric?: ForwarderMetric,
  prefix: string,
  suffix: string,
};

const CounterRate = ({ metric, prefix, suffix }: Props) => {
  if (!metric) {
    return null;
  }

  const { rate: { one_minute: oneMinute } } = metric;

  return (
    <span>
      {prefix ? `${prefix} ` : null}
      {formatMetric(oneMinute)}
      {suffix ? ` ${suffix}` : null}
    </span>
  );
};

CounterRate.propTypes = {
  metric: ForwarderMetricPropType,
  prefix: PropTypes.string,
  suffix: PropTypes.string,
};

CounterRate.defaultProps = {
  metric: null,
  prefix: '',
  suffix: '',
};

export default CounterRate;
