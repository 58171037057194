import React, { useCallback, useContext, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import * as Immutable from 'immutable';
import styled from 'styled-components';
import { ControlLabel, Form } from 'components/bootstrap';
import FlatContentRow from 'components/common/FlatContentRow';
import connect, { useStore } from 'stores/connect';
import { SearchExecutionStateActions } from 'views/stores/SearchExecutionStateStore';
import { getParameterBindingsAsMap } from 'views/logic/search/SearchExecutionState';
import ParameterType from 'views/logic/parameters/Parameter';
import type { ParameterBindings } from 'views/logic/search/SearchExecutionState';
import QueryEditModeContext from 'views/components/contexts/QueryEditModeContext';
import type { QueryEditMode } from 'views/components/contexts/QueryEditModeContext';
import { ViewStore } from 'views/stores/ViewStore';

import { SearchParameterActions, SearchParameterStore } from 'enterprise/parameters/stores/SearchParameterStore';

import Parameter from './Parameter';

type Props = {
  onExecute: () => void,
  parameters: Immutable.Map<string, ParameterType>,
  parameterBindings: ParameterBindings,
};

const handleDelete = (name: string) => SearchParameterActions.remove(name);

const handleEdit = (name: string, parameter: ParameterType) => SearchParameterActions.update(name, parameter);

const Container = styled.div`
  margin-bottom: 10px;
`;

const wrapperForMode = (mode: QueryEditMode) => {
  switch (mode) {
    // eslint-disable-next-line react/prop-types
    case 'query': return ({ children }) => (
      <FlatContentRow>
        {children}
      </FlatContentRow>
    );
    // eslint-disable-next-line react/prop-types
    case 'widget': return ({ children }) => <Container>{children}</Container>;
    default: throw new Error(`Invalid query edit mode: ${mode}`);
  }
};

type Bindings = Immutable.Map<string, string>;

const Parameters = ({ onExecute, parameters, parameterBindings }: Props) => {
  const searchId = useStore(ViewStore, ({ view }) => view?.search?.id);
  const [bindings, setBindings] = useState<Bindings>(getParameterBindingsAsMap(parameterBindings).toMap());

  const updateBindingsAndExecute = useCallback((newBindings: Bindings) => SearchExecutionStateActions.setParameterValues(newBindings).then(() => onExecute()), [onExecute]);
  const handleSubmit = useCallback((e?: React.FormEvent<HTMLFormElement>) => {
    if (e) {
      e.preventDefault();
    }

    return updateBindingsAndExecute(bindings);
  }, [updateBindingsAndExecute, bindings]);

  const onBlur = useCallback(() => SearchExecutionStateActions.setParameterValues(bindings), [bindings]);

  const onChange = useCallback((name, value, submit = false) => {
    const newBindings = bindings.set(name, value);
    setBindings(newBindings);

    if (submit) {
      updateBindingsAndExecute(newBindings);
    }
  }, [bindings, updateBindingsAndExecute]);

  const parameterFields = useMemo(() => parameters
    .sort((p1: ParameterType, p2: ParameterType) => p1.title.localeCompare(p2.title))
    .map((param) => (
      <Parameter key={param.name}
                 parameter={param}
                 binding={bindings.get(param.name)}
                 searchId={searchId}
                 onBlur={onBlur}
                 onChange={onChange}
                 onDelete={handleDelete}
                 onEdit={handleEdit} />

    ))
    .valueSeq()
    .toJS(), [parameters, bindings, onBlur, onChange, searchId]);

  const queryEditMode: QueryEditMode = useContext(QueryEditModeContext);
  const Wrapper = useMemo(() => wrapperForMode(queryEditMode), [queryEditMode]);

  if (parameters.size === 0) {
    return null;
  }

  return (
    <Wrapper>
      <Form inline onSubmit={handleSubmit}>
        <ControlLabel>Parameters</ControlLabel>
        {parameterFields}
        <input type="submit" style={{ display: 'none' }} />
      </Form>
    </Wrapper>
  );
};

Parameters.propTypes = {
  parameterBindings: ImmutablePropTypes.map.isRequired,
  parameters: ImmutablePropTypes.map.isRequired,
  onExecute: PropTypes.func.isRequired,
};

export default connect(Parameters, { parameters: SearchParameterStore });
