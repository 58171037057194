// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".at4VT7nhg92VC9cFTsOB {\n    padding: 0;\n    overflow-y: auto;\n}\n\n.D4ETHwBNfSKjAayZCRru {\n    font-size: 12px;\n    display: table-row;\n    white-space: nowrap;\n}\n\n.PRB3vn3V7JsJypRWlqY6 {\n    display: flex;\n}\n\n.MSDP5nxQDVUvirFL9Rel {\n    flex-grow: 1;\n    margin-right: 5px;\n}", "",{"version":3,"sources":["webpack://./../../graylog2-server/graylog2-web-interface/src/views/components/sidebar/fields/FieldTypeIcon.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,gBAAgB;AACpB;;AAEA;IACI,eAAe;IACf,kBAAkB;IAClB,mBAAmB;AACvB;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,YAAY;IACZ,iBAAiB;AACrB","sourcesContent":[":local(.fieldList) {\n    padding: 0;\n    overflow-y: auto;\n}\n\n:local(.fieldListItem) {\n    font-size: 12px;\n    display: table-row;\n    white-space: nowrap;\n}\n\n:local(.filterContainer) {\n    display: flex;\n}\n\n:local(.filterInputContainer) {\n    flex-grow: 1;\n    margin-right: 5px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"fieldList": "at4VT7nhg92VC9cFTsOB",
	"fieldListItem": "D4ETHwBNfSKjAayZCRru",
	"filterContainer": "PRB3vn3V7JsJypRWlqY6",
	"filterInputContainer": "MSDP5nxQDVUvirFL9Rel"
};
export default ___CSS_LOADER_EXPORT___;
