import CustomizationPage from './CustomizationPage';
import HeaderBadge from './header-badge/HeaderBadge';
import useThemeCustomizer, { generateCustomThemeColors } from './theme-editor/useThemeCustomizer';
import usePublicNotifications from './public-notifications/usePublicNotifications';

export default {
  navigationItems: [{
    key: 'headerBadge',
    component: HeaderBadge,
  }],
  routes: [
    { path: '/customization', component: CustomizationPage, permissions: 'inputs:create' },
  ],
  'customization.theme.customizer': {
    hooks: {
      useThemeCustomizer,
    },
    actions: {
      generateCustomThemeColors,
    },
  },
  'customization.publicNotifications': {
    hooks: {
      usePublicNotifications,
    },
  },
};
