import Reflux from 'reflux';
import { qualifyUrl } from 'util/URLUtils';
import { Builder } from 'logic/rest/FetchProvider';

export type Nonce = {
   nonce: {
    state: string;
    redirectTo: string;
  };
}

export type Configuration = {
  oauth_client_id: string;
  oauth_base_url: string;
  oauth_auth_url: string;
  oauth_callback_url: string;
  oauth_type: string;
  oauth_title: string
}
export type OidcConfiguration = {
  configuration: Configuration;
}
type OidcConfigurationStoreType = {
  getConfig: () => Promise<OidcConfiguration>;
  getInitialState: () => void
};

type OidcConfigurationActionType = {
  getConfig: () => Promise<OidcConfiguration>;
}

export const OidcConfigurationActions = Reflux.createActions<OidcConfigurationActionType>({
  getConfig: { asyncResult: true },
});

export const OidcConfigurationStore = Reflux.createStore<OidcConfigurationStoreType>({
  listenables: [OidcConfigurationActions],
  sourceUrl: '/plugins/org.graylog.plugins.security/oauth/authconfig',
  configuration: undefined,

  getState() {
    return { configuration: this.configuration };
  },

  getInitialState() {
    return this.getState();
  },

  propagateState() {
    this.trigger(this.getState());
  },

  getConfig() {
    const builder = new Builder('GET', qualifyUrl(this.sourceUrl))
      .json(null);

    const promise = builder.build();

    promise.then((response) => {
      this.configuration = response;
      this.propagateState();

      return response;
    });

    OidcConfigurationActions.getConfig.promise(promise);
  },
});
