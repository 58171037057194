import React from 'react';
import PropTypes from 'prop-types';
import { Panel } from 'components/bootstrap';

import { ForwarderPropType, Forwarder } from 'forwarder/Types';

import ForwarderSelectionList from '../ForwarderSelectionList';

type Props = {
  eventKey: string,
  forwarders: Array<Forwarder>,
  onForwarderSelect: () => void,
};

const SelectNewForwarderStep = ({ eventKey, forwarders, onForwarderSelect }: Props) => {
  return (
    <Panel eventKey={eventKey}>
      <Panel.Heading>
        <Panel.Title>
          <Panel.Toggle tabIndex="0">4. Select Forwarder</Panel.Toggle>
        </Panel.Title>
      </Panel.Heading>
      <Panel.Body collapsible>
        <p>
          Once the new Forwarder is configured as stated above and started, it will register with Graylog
          and appear in the list below.
        </p>
        <ForwarderSelectionList forwarders={forwarders} onForwarderSelect={onForwarderSelect} />
      </Panel.Body>
    </Panel>
  );
};

SelectNewForwarderStep.propTypes = {
  eventKey: PropTypes.string.isRequired,
  forwarders: PropTypes.arrayOf(ForwarderPropType).isRequired,
  onForwarderSelect: PropTypes.func.isRequired,
};

export default SelectNewForwarderStep;
