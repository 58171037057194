import Reflux from 'reflux';

import ArchiveActions from 'archive/ArchiveActions';
import UserNotification from 'util/UserNotification';
import { qualifyUrl } from 'util/URLUtils';
import fetch from 'logic/rest/FetchProvider';

import type { Archive, ArchiveContext, BackendContext, DiskStates, FileNames } from './types';

export const FILESYSTEM_TYPE = 'fs-1';
export const S3_TYPE = 's3-1';
export const BACKEND_TYPES = {
  [FILESYSTEM_TYPE]: 'File system',
  [S3_TYPE]: 'S3',
};

type PaginatedCatalogResponse = {
  archives: Array<Archive>,
  archives_context: {
    [key: string]: ArchiveContext,
  },
  backends_context: {
    [key: string]: BackendContext,
  },
  count: number,
  total: number,
  page: number,
  per_page: number,
  query: string,
};

type AvailabilityResponse = {
  available: boolean,
};

const urlPrefix = '/plugins/org.graylog.plugins.archive';

export type ArchiveStoreState = {
  diskStates: DiskStates;
  filenamesExport: FileNames;
};

const ArchiveStore = Reflux.createStore({
  listenables: [ArchiveActions],
  diskStates: {},
  filenamesExport: undefined,

  getInitialState() {
    return this.getState();
  },

  getState() {
    return {
      diskStates: this.diskStates,
      filenamesExport: this.filenamesExport,
    };
  },

  _propagateUpdate() {
    return this.trigger(this.getState());
  },

  _errorHandler(message: string, title: string, cb: (error: any) => void) {
    return (error) => {
      let errorMessage;

      try {
        errorMessage = error.additional.body.message;
      } catch (e) {
        errorMessage = error.message;
      }

      UserNotification.error(`${message}: ${errorMessage}`, title);

      if (cb) {
        cb(error);
      }
    };
  },

  _configUrl() {
    return qualifyUrl(`${urlPrefix}/config`);
  },

  _archivesUrl(path: string) {
    return qualifyUrl(`${urlPrefix}/cluster/archives${path}`);
  },

  _url(path: string) {
    return qualifyUrl(`${urlPrefix}${path}`);
  },

  searchPaginated(page: number, perPage: number, query: string) {
    let url;

    if (query) {
      url = this._archivesUrl(`/catalog?page=${page}&per_page=${perPage}&query=${encodeURIComponent(query)}`);
    } else {
      url = this._archivesUrl(`/catalog?page=${page}&per_page=${perPage}`);
    }

    const promise = fetch('GET', url);

    promise.then((response: PaginatedCatalogResponse) => {
      const pagination = {
        count: response.count,
        total: response.total,
        page: response.page,
        per_page: response.per_page,
        query: response.query,
      };
      this.catalog = { archives: response.archives, archivesContext: response.archives_context, backendsContext: response.backends_context, pagination: pagination };
      this._propagateUpdate();
    }, this._errorHandler('Fetching archive catalog failed', 'Could not retrieve the archive catalog'));

    ArchiveActions.searchPaginated.promise(promise);
  },

  createArchive(indexName: string) {
    const promise = fetch('POST', this._archivesUrl(`/${indexName}`));

    promise.then(() => {
      UserNotification.success(`Archive job for index ${indexName} started`);
    }, this._errorHandler('Archive creation failed', `Could not create archive for index: ${indexName}`));

    ArchiveActions.createArchive.promise(promise);
  },

  restoreArchive(backendId: string, archiveId: string) {
    const promise = fetch('POST', this._archivesUrl(`/backend/${backendId}/${archiveId}/restore`));

    promise.then(() => {
      UserNotification.success(`Archive restore job for archive ${archiveId} started`);
    }, this._errorHandler('Index restore failed', `Could not restore archive: ${archiveId}`));

    ArchiveActions.restoreArchive.promise(promise);
  },

  deleteArchive(backendId: string, archiveId: string) {
    const promise = fetch('DELETE', this._archivesUrl(`/backend/${backendId}/${archiveId}`));

    promise.then(() => {
      UserNotification.success(`Archive ${archiveId} deleted`);
    }, this._errorHandler('Archive deletion failed', `Could not delete archive: ${archiveId}`));

    ArchiveActions.deleteArchive.promise(promise);
  },

  availability(entryId: string) {
    const promise = fetch('GET', this._archivesUrl(`/catalog/${entryId}/available`));
    this.diskStates[entryId] = { loading: true };
    this._propagateUpdate();

    promise.then((response: AvailabilityResponse) => {
      this.diskStates[entryId] = { available: response.available, loading: false };
      this._propagateUpdate();
    }, this._errorHandler('Availability check failed', `Could not check disk status for archive: ${entryId}`));

    ArchiveActions.availability.promise(promise);
  },

  rebuildCatalog() {
    const promise = fetch('POST', this._archivesUrl('/catalog/rebuild'));

    promise.then(() => {
      UserNotification.success('Rebuilding catalog.');
    });

    ArchiveActions.rebuildCatalog.promise(promise);
  },

  checkOutputTemplate(template: string) {
    const url = this._url(`/backends/test-output-template?template=${template || ''}`);
    const promise = fetch('GET', url);

    ArchiveActions.checkOutputTemplate.promise(promise);
  },

  exportFilenames(query: string) {
    let url = this._archivesUrl('/catalog/export/filename');

    if (query) {
      url = `${url}?query=${encodeURIComponent(query)}`;
    }

    const promise = fetch('GET', url);

    promise.then((response: FileNames) => {
      this.filenameExport = response.filenames;
      this._propagateUpdate();
    }, this._errorHandler('Exporting catalog filenames failed', 'Could not export the archive catalog filenames'));

    ArchiveActions.exportFilenames.promise(promise);
  },
});

export default ArchiveStore;
