export type LicenseSubjectType = '/license/enterprise' |
  '/license/enterprise/archive' |
  '/license/enterprise/auditlog' |
  '/license/enterprise/illuminate' |
  '/license/security' |
  '/license/anomaly';

// eslint-disable-next-line import/prefer-default-export
export const LICENSE_TYPES: { [key in LicenseSubjectType]: string } = {
  '/license/enterprise': 'Graylog Enterprise',
  '/license/enterprise/archive': 'Graylog Enterprise (Archiving)',
  '/license/enterprise/auditlog': 'Graylog Enterprise (Audit Log)',
  '/license/enterprise/illuminate': 'Graylog Enterprise (Illuminate)',
  '/license/security': 'Graylog Security',
  '/license/anomaly': 'Graylog Anomaly Detection',
};
