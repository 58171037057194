import { useQuery } from 'react-query';

import type { Jobs } from 'archive/types';
import { SystemJobsActions } from 'stores/systemjobs/SystemJobsStore';
import UserNotification from 'util/UserNotification';

export const SYSTEM_JOBS_KEY = 'systemJobs';

const refetchInterval = 2000;

const fetchSystemJobs = async (): Promise<Jobs> => {
  return SystemJobsActions.list();
};

const useSystemJobsQuery = () => {
  return useQuery(SYSTEM_JOBS_KEY, () => fetchSystemJobs(), {
    onError: (errorThrown) => {
      UserNotification.error(`Loading system jobs failed with status: ${errorThrown}`);
    },
    retry: 0,
    refetchInterval: refetchInterval,
    keepPreviousData: true,
    notifyOnChangeProps: ['data', 'error'],
    initialData: {},
  });
};

export default useSystemJobsQuery;
