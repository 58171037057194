import Reflux from 'reflux';

import UserNotification from 'util/UserNotification';
import { qualifyUrl } from 'util/URLUtils';
import fetch from 'logic/rest/FetchProvider';
import { Store } from 'stores/StoreTypes';

const AWSRegionsUrl = '/plugins/org.graylog.plugins.archive/backends/s3/regions';

export type Region = {
  value: string;
  label: string;
};
type ActionsType = {
  getRegions: () => Promise<Array<Region>>
}
type AWSRegionsStoreState = {
  regions: Array<Region>;
};
export const AWSRegionsActions = Reflux.createActions<ActionsType>({
  getRegions: { asyncResult: true },
});

const AWSRegionsStore: Store<AWSRegionsStoreState> = Reflux.createStore({
  listenables: [AWSRegionsActions],
  regions: undefined,

  getInitialState() {
    return {
      regions: this.regions,
    };
  },
  getRegions() {
    const promise = fetch('GET', qualifyUrl(AWSRegionsUrl));
    const errorHandler = this._errorHandler('Fetching AWS regions failed.', 'Could not retrieve AWS regions.');

    promise.then((response) => {
      this.regions = response.regions;
      this.trigger({ regions: response.regions });

      return response;
    }, (error) => {
      errorHandler(error);
    });

    AWSRegionsActions.getRegions.promise(promise);
  },

  _errorHandler(message, title) {
    return (error) => {
      const errorMessage = error.additional?.body?.message ?? error.message;
      UserNotification.error(`${message}: ${errorMessage}`, title);
    };
  },
});

export default AWSRegionsStore;
