import PropTypes from 'prop-types';

export const ForwarderPropType = PropTypes.shape({
  hostname: PropTypes.string,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
});

export const InputProfilePropType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
});

export const ForwarderInputPropType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  configuration: PropTypes.objectOf(PropTypes.any).isRequired,
  input_profile_id: PropTypes.string.isRequired,
  version: PropTypes.number.isRequired,
  created_at: PropTypes.string.isRequired,
});

export const ForwarderMetricPropType = PropTypes.shape({
  name: PropTypes.string.isRequired,
  rate: PropTypes.shape({
    one_minute: PropTypes.number.isRequired,
    five_minute: PropTypes.number.isRequired,
    fifteen_minute: PropTypes.number.isRequired,
  }),
  type: PropTypes.string.isRequired,
});

export const ForwarderStatePropType = PropTypes.oneOf(['RUNNING', 'FAILED', 'STARTING', 'STOPPED', 'DISCONNECTED', 'CONNECTED']);

export const ForwarderContextPropType = PropTypes.shape({
  state: ForwarderStatePropType,
  metrics: PropTypes.arrayOf(ForwarderMetricPropType).isRequired,
});

export const ForwardersContextPropType = PropTypes.objectOf(ForwarderContextPropType);

export const InputDescriptionPropType = PropTypes.shape({
  type: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  is_exclusive: PropTypes.bool,
  requested_configuration: PropTypes.objectOf(PropTypes.any).isRequired,
  link_to_docs: PropTypes.string.isRequired,
});

const paginationShape = {
  total: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  perPage: PropTypes.number.isRequired,
  count: PropTypes.number.isRequired,
  query: PropTypes.string.isRequired,
};

const sortShape = {
  sortByField: PropTypes.string.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
};

export const PaginationPropType = PropTypes.exact(paginationShape);

export const ForwarderPaginationPropType = PropTypes.exact({
  ...paginationShape,
  ...sortShape,
  stateFilter: PropTypes.oneOf(['any', 'connected', 'disconnected']).isRequired,
});

export type InputFormContent = {
  type: string,
  title: string,
  configuration: {
    [type: string]: any,
  },
};

export type Input = {
  id: string,
  title: string,
  type: string,
  configuration: {
    [type: string]: any,
  },
  input_profile_id: string,
  version: number,
  created_at: string,
  content_pack?: boolean;
};

export type Forwarder = {
  id?: string,
  hostname?: string,
  version?: string,
  title: string,
  description?: string,
  input_profile_id?: string,
  node_id?: string,
  last_seen_at?: string,
  created_at?: string,
};

export type InputProfile = {
  id: string,
  title: string,
  description?: string,
};

export type ForwarderInputTypes = {
  [type: string]: string,
};

export type ForwarderState = 'RUNNING' | 'FAILED' | 'STARTING' | 'STOPPED' | 'DISCONNECTED' | 'CONNECTED';
export type InputState = 'CREATED' | 'INITIALIZED' | 'INVALID_CONFIGURATION' | 'STARTING' | 'RUNNING' |
    'FAILED' | 'STOPPING' | 'STOPPED' | 'TERMINATED';

export type ForwarderInputState = {
  forwarder_id: string,
  input_id: string,
  state: InputState,
  metrics: Array<ForwarderMetric>,
};

export type ConfigurationField = {
  type: 'boolean' | 'text' | 'dropdown' | 'list' | 'number',
  name?: string,
  human_name: string,
  description: string,
  default_value: any,
  is_optional: boolean,
  attributes: Array<string>,
  additional_info: any,
  position: number,
};

export type InputDescription = {
  type: string,
  name: string,
  is_exclusive: boolean,
  requested_configuration: {
    [key: string]: ConfigurationField,
  },
  link_to_docs: string,
};

export type ForwarderInputDescriptions = {
  [type: string]: InputDescription,
};

export type ForwarderMetric = {
  name: string,
  rate: {
    one_minute: number,
    five_minute: number,
    fifteen_minute: number,
  },
  type: string,
};

export type ForwarderContext = {
  state: ForwarderState;
  metrics: Array<ForwarderMetric>,
};

export type ForwardersContext = {
  [id: string]: ForwarderContext;
};

export type SortOrder = 'asc' | 'desc';

export type ForwarderStateFilter = 'any' | 'connected' | 'disconnected';

export type Pagination = {
  total: number,
  page: number,
  perPage: number,
  count: number,
  query: string,
};

export type PaginationWithSort = Pagination & {
  sortByField: string,
  order: SortOrder,
};

export type ForwarderPagination = PaginationWithSort & {
  stateFilter: ForwarderStateFilter,
};

export type RequestedPagination = {
  query?: string,
  page?: number,
  pageSize?: number,
  sortByField?: string,
  order?: SortOrder,
  stateFilter?: ForwarderStateFilter,
};
