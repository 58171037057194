import * as React from 'react';
import styled from 'styled-components';

import { Link } from 'components/common/router';
import { Alert } from 'components/bootstrap';
import Routes from 'routing/Routes';
import { isPermitted } from 'util/PermissionsMixin';
import type { UserJSON } from 'logic/users/User';

type Props = {
  currentUser: UserJSON,
  onDismissNotification: () => void
};

const StyledH4 = styled.h4`
  margin-bottom: 5px,
`;

const LicenseTrafficViolationNotification = ({ currentUser, onDismissNotification }: Props) => (
  <Alert bsStyle="danger" className="has-bm" onDismiss={() => onDismissNotification()}>
    <StyledH4><strong>Graylog Enterprise License Violation Warning</strong></StyledH4>
    <p>
      Your current traffic has exceeded your Graylog Enterprise license traffic limitation.
      If traffic repeatedly exceeds the limit of the license, some features will be turned off.{' '}
      {isPermitted(currentUser?.permissions, 'licenses:create') ? (
        <>
          Go to the <Link to={Routes.pluginRoute('SYSTEM_LICENSES')}>Licenses page</Link> for
          more information or contact your Graylog account manager.
        </>
      ) : (
        <>Contact your Graylog account manager for more information.</>
      )}
    </p>
  </Alert>
);

export default LicenseTrafficViolationNotification;
