import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import connect from 'stores/connect';
import { LinkContainer } from 'components/common/router';
import { Button, Row, Col } from 'components/bootstrap';
import { DocumentTitle, PageHeader, Spinner } from 'components/common';
import history from 'util/History';
import Routes from 'routing/Routes';
import withParams from 'routing/withParams';
import AppConfig from 'util/AppConfig';
import { Store } from 'stores/StoreTypes';

import ArchiveLicenseStatusContainer from 'archive/components/ArchiveLicenseStatusContainer';
import ArchiveActions from 'archive/ArchiveActions';
import ArchiveBackendsStore, { ArchiveBackendsActions, ArchiveBackendsStoreState } from 'archive/ArchiveBackendsStore';
import ArchivePageHeaderSupport from 'archive/components/ArchivePageHeaderSupport';
import ArchiveBackendConfigForm from 'archive/components/ArchiveBackendConfigForm';

import type { Backend } from './types';

const isCloud = AppConfig.isCloud();

const StyledH2 = styled.h2`
  margin-bottom: 10px;
`;

type Props = {
  params: {
    backendId?: string,
  },
  backend?: Backend,
};

const ArchiveBackendConfigurationPage = ({ params, backend }: Props) => {
  const { backendId } = params;

  useEffect(() => {
    if (backendId) {
      ArchiveBackendsActions.getBackend(backendId);
    }
  }, [backendId]);

  if (isCloud) {
    history.push(Routes.pluginRoute('SYSTEM_ARCHIVES_BACKENDS'));
  }

  const _isLoading = backendId && !backend?.id;

  if (_isLoading) {
    return <Spinner />;
  }

  const _checkOutputPath = (path: string) => {
    return ArchiveActions.checkOutputTemplate(path);
  };

  const _updateConfiguration = (backendUpdate: Backend) => {
    return ArchiveBackendsActions.saveBackend(backendUpdate).then(() => {
      history.push(Routes.pluginRoute('SYSTEM_ARCHIVES_BACKENDS'));
    });
  };

  return (
    <DocumentTitle title="Archive backend configuration">
      <div>
        <PageHeader title="Edit archive backend configuration">
          <span>
            The Graylog archive feature allows you to create archives from indices. The generated archives
            are simple flat files that can be moved to cheap storage and re-imported at any time.
          </span>
          <ArchivePageHeaderSupport />
          <span>
            <LinkContainer to={Routes.pluginRoute('SYSTEM_ARCHIVES')}>
              <Button bsStyle="info">Overview</Button>
            </LinkContainer>
              &nbsp;
            <LinkContainer to={Routes.pluginRoute('SYSTEM_ARCHIVES_CONFIGURATION')}>
              <Button bsStyle="info">Configuration</Button>
            </LinkContainer>
              &nbsp;
            <LinkContainer to={Routes.pluginRoute('SYSTEM_ARCHIVES_BACKENDS')}>
              <Button bsStyle="info" className="active">Manage Backends</Button>
            </LinkContainer>
          </span>
        </PageHeader>
        <ArchiveLicenseStatusContainer />
        <Row className="content">
          <Col lg={8}>
            <StyledH2>Backend configuration</StyledH2>
            <ArchiveBackendConfigForm backend={backend}
                                      checkOutputPath={_checkOutputPath}
                                      updateConfiguration={_updateConfiguration}
                                      cancelURL={Routes.pluginRoute('SYSTEM_ARCHIVES_BACKENDS')} />
          </Col>
        </Row>
      </div>
    </DocumentTitle>
  );
};

ArchiveBackendConfigurationPage.propTypes = {
  params: PropTypes.object.isRequired,
  backend: PropTypes.object,
};

ArchiveBackendConfigurationPage.defaultProps = {
  backend: {
    id: undefined,
    title: '',
    description: '',
    settings: {
      type: undefined,
    },
  },
};

// @ts-ignore
export default withParams(connect(ArchiveBackendConfigurationPage,
  { archiveBackends: ArchiveBackendsStore as Store<ArchiveBackendsStoreState> },
  ({ archiveBackends, ...otherProps }) => ({
    ...otherProps,
    backend: archiveBackends.backend,
  })));
