import PropTypes from 'prop-types';
import * as React from 'react';

import { Col, Row } from 'components/bootstrap';
import { DocumentTitle, PageHeader, Spinner } from 'components/common';
import withParams from 'routing/withParams';
import ReportsMainNav from 'report/common/ReportsMainNav';

import ReportConfiguration from './ReportConfiguration';

import ReportSubNav from '../common/ReportSubNav';
import ReportValidations from '../common/ReportValidations';
import useReportAndLogo from '../hooks/useReportAndLogo';

type Props = {
  params: {
    reportId: string,
  },
};

const ReportConfigurationPage = ({ params: { reportId } }: Props) => {
  const { report, reportLogo } = useReportAndLogo(reportId);

  if (!report) {
    return <Spinner text="Loading report data, please wait..." />;
  }

  return (
    <DocumentTitle title={`Report ${report.title} configuration`}>
      <div>
        <PageHeader title={<span>Report <em>{report.title}</em> configuration</span>}
                    subactions={<ReportSubNav reportId={report.id} />}>
          <span>
            Configure the report layout and schedule, adapting it to your needs.
          </span>
          {null}
          <ReportsMainNav />
        </PageHeader>

        <ReportValidations />

        <Row className="content">
          <Col md={12}>
            <ReportConfiguration report={report} reportLogo={reportLogo} />
          </Col>
        </Row>
      </div>
    </DocumentTitle>
  );
};

ReportConfigurationPage.propTypes = {
  params: PropTypes.exact({
    reportId: PropTypes.string.isRequired,
  }).isRequired,
};

export default withParams(ReportConfigurationPage);
