import * as React from 'react';
import { useContext, useEffect } from 'react';
import { Field, useFormikContext } from 'formik';
import moment from 'moment';

import DateTime from 'logic/datetimes/DateTime';
import { HelpBlock, Row } from 'components/bootstrap';
import TimeRangeInput from 'views/components/searchbar/TimeRangeInput';
import ExportSettingsContext from 'views/components/ExportSettingsContext';
import type { AbsoluteTimeRange } from 'views/logic/queries/Query';
import { useStore } from 'stores/connect';
import { SearchConfigStore } from 'views/stores/SearchConfigStore';

declare module 'views/components/ExportSettingsContext' {
  interface ExportSettings {
    timerange?: AbsoluteTimeRange;
  }
}

const formatDatetime = (datetime) => datetime.toString(DateTime.Formats.TIMESTAMP);

const toPickerRange = (timerange: AbsoluteTimeRange) => (timerange ? ({
  type: 'absolute',
  from: formatDatetime(DateTime.parseFromString(timerange.from)),
  to: formatDatetime(DateTime.parseFromString(timerange.to)),
}) : undefined);

const fromPickerRange = (timerange: AbsoluteTimeRange) => (timerange ? ({
  type: 'absolute',
  from: DateTime.parseFromString(timerange.from).toISOString(),
  to: DateTime.parseFromString(timerange.to).toISOString(),
}) : null);

const LogViewExportSettings = () => {
  const { settings } = useContext(ExportSettingsContext);
  const { searchesClusterConfig } = useStore(SearchConfigStore);
  const { setFieldValue } = useFormikContext();
  const limitDuration = moment.duration(searchesClusterConfig?.query_time_range_limit).asSeconds() ?? 0;

  useEffect(() => {
    setFieldValue('customSettings.timerange', settings?.timerange);
  }, [setFieldValue, settings]);

  return (
    <Row>
      <Field name="customSettings.timerange">
        {({ field: { name, value, onChange } }) => {
          const currentTimeRange = toPickerRange(value);
          const setCurrentTimeRange = (newTimeRange) => onChange({ target: { name, value: fromPickerRange(newTimeRange) } });

          return (
            <>
              <label htmlFor={name}>Time Range</label>
              <TimeRangeInput value={currentTimeRange}
                              onChange={setCurrentTimeRange}
                              validTypes={['absolute']}
                              showPresetDropdown={false}
                              limitDuration={limitDuration} />
              <HelpBlock>
                This is the time range which is used for the export. It defaults to the range you are currently seeing
                in the widget.
                If you want it to include more/less messages, you can adjust by clicking on the button/summary.
              </HelpBlock>
            </>
          );
        }}
      </Field>
    </Row>
  );
};

export default LogViewExportSettings;
