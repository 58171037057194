import * as React from 'react';
import { useEffect, useState } from 'react';

import { Col, Row } from 'components/bootstrap';
import { DocumentTitle, PageHeader, Spinner } from 'components/common';
import withParams from 'routing/withParams';
import type { Report } from 'report/types';
import ReportsMainNav from 'report/common/ReportsMainNav';

import ReportHistory from './ReportHistory';

import ReportSubNav from '../common/ReportSubNav';
import ReportValidations from '../common/ReportValidations';
import ReportsActions from '../ReportsActions';

const useReport = (reportId: string) => {
  const [report, setReport] = useState<Report | undefined>();

  useEffect(() => {
    ReportsActions.get(reportId).then(setReport);
  }, [reportId]);

  return report;
};

type Props = {
  params: { reportId: string }
}

const ReportHistoryPage = ({ params: { reportId } }: Props) => {
  const report = useReport(reportId);

  if (!report) {
    return <Spinner text="Loading report data, please wait..." />;
  }

  return (
    <DocumentTitle title={`Report ${report.title} history`}>
      <div>
        <PageHeader title={<span>Report <em>{report.title}</em> history</span>}
                    subactions={<ReportSubNav reportId={report.id} />}>
          <span>
            Review the generation and delivery status of any report.
          </span>
          {null}
          <ReportsMainNav />
        </PageHeader>

        <ReportValidations />

        <Row className="content">
          <Col md={12}>
            <ReportHistory reportId={report.id} reportTitle={report.title} />
          </Col>
        </Row>
      </div>
    </DocumentTitle>
  );
};

export default withParams(ReportHistoryPage);
