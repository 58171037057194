import React, { ReactNode, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import ForwardersContext, { getForwarderContextById } from './ForwardersContext';

import { ForwardersActions, ForwarderListResponse } from '../stores/ForwardersStore';
import {
  Forwarder,
  ForwarderPagination,
  ForwardersContext as ForwardersContextType,
  ForwarderStateFilter,
  RequestedPagination,
  SortOrder,
} from '../Types';

type Props = {
  children: ReactNode,
  initialPagination?: RequestedPagination,
};

const PaginatedForwarderListProvider = ({ initialPagination, children }: Props) => {
  const [forwarders, setForwarders] = useState<Forwarder[]>([]);
  const [forwardersPagination, setForwardersPagination] = useState<ForwarderPagination | undefined>(undefined);
  const [forwardersContext, setForwardersContext] = useState<ForwardersContextType>({});
  const [requestedPagination, setRequestedPagination] = useState<RequestedPagination | undefined>(initialPagination);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const timeout = useRef<number | undefined>();
  const fetchPromise = useRef<Promise<ForwarderListResponse> | void>();

  const changePagination = (page, pageSize, query) => {
    setRequestedPagination((prevRequestedPagination: RequestedPagination = {}): RequestedPagination => {
      return { ...prevRequestedPagination, page, pageSize, query };
    });
  };

  const changeSort = (sortByField: string, order: SortOrder) => {
    setRequestedPagination((prevRequestedPagination: RequestedPagination = {}): RequestedPagination => {
      return { ...prevRequestedPagination, sortByField, order, page: 1 };
    });
  };

  const changeStateFilter = (stateFilter: ForwarderStateFilter) => {
    setRequestedPagination((prevRequestedPagination: RequestedPagination = {}): RequestedPagination => {
      return { ...prevRequestedPagination, stateFilter, page: 1 };
    });
  };

  useEffect(() => {
    const fetchForwarders = () => {
      if (fetchPromise.current) {
        return;
      }

      setIsLoading(true);

      fetchPromise.current = ForwardersActions.list(requestedPagination)
        .then((response: ForwarderListResponse) => {
          setForwarders(response.forwarders);
          setForwardersPagination(response.forwardersPagination);
          setForwardersContext(response.forwardersContext);

          return response;
        })
        .finally(() => {
          fetchPromise.current = undefined;
          setIsLoading(false);
        });
    };

    fetchForwarders();

    timeout.current = window.setInterval(() => {
      fetchForwarders();
    }, 5000);

    return () => {
      fetchPromise.current = undefined;
      setIsLoading(false);

      if (timeout.current) {
        clearInterval(timeout.current);
      }
    };
  }, [requestedPagination]);

  return (
    <ForwardersContext.Provider value={{
      forwarders: forwarders,
      forwardersContext: forwardersContext,
      pagination: forwardersPagination,
      changePagination: changePagination,
      changeSort: changeSort,
      changeStateFilter: changeStateFilter,
      isLoading: isLoading,
      getForwarderContext: getForwarderContextById(forwardersContext),
    }}>
      {children}
    </ForwardersContext.Provider>
  );
};

PaginatedForwarderListProvider.propTypes = {
  children: PropTypes.node.isRequired,
  initialPagination: PropTypes.shape({
    query: PropTypes.string,
    page: PropTypes.number,
    pageSize: PropTypes.number,
    order: PropTypes.oneOf(['asc', 'desc']),
    sortByField: PropTypes.string,
    stateFilter: PropTypes.oneOf(['any', 'connected', 'disconnected']),
  }),
};

PaginatedForwarderListProvider.defaultProps = {
  initialPagination: {},
};

export default PaginatedForwarderListProvider;
