import * as React from 'react';
import PropTypes from 'prop-types';
import { range } from 'lodash';

import { Select } from 'components/common';
import StringUtils from 'util/StringUtils';

const OptionValue = ({ label }: { label: string }) => <span>Day {label}</span>;

type Props = {
  id: string,
  value?: number | string,
  disabled?: boolean,
  required?: boolean,
  onChange: (newDay: number | string) => void,
}

const DayOfMonthSelect = ({ id, value, disabled, required, onChange }: Props) => {
  const dom = range(1, 31 + 1).map((day) => {
    const stringDay = String(day);

    return { value: stringDay, label: stringDay };
  });

  const stringValue = value ? StringUtils.stringify(value) : undefined;

  return (
    <Select id={id}
            options={dom}
            value={stringValue}
            placeholder="Select day of the month"
            disabled={disabled}
            valueRenderer={OptionValue}
            onChange={onChange}
            required={required} />
  );
};

DayOfMonthSelect.propTypes = {
  id: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};

DayOfMonthSelect.defaultProps = {
  value: undefined,
  disabled: false,
  required: false,
};

export default DayOfMonthSelect;
