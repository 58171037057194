import Reflux from 'reflux';
import * as Immutable from 'immutable';
import { $PropertyType } from 'utility-types';
import SharedEntity from 'logic/permissions/SharedEntity';
import type { Store } from 'stores/StoreTypes';
import fetch from 'logic/rest/FetchProvider';
import { qualifyUrl } from 'util/URLUtils';
import { singletonStore } from 'logic/singleton';
import Role from 'logic/roles/Role';
import type { PaginatedListJSON, Pagination } from 'stores/PaginationTypes';
import UserOverview from 'logic/users/UserOverview';
import PaginationURL from 'util/PaginationURL';
import type { PaginatedEntityShares } from 'actions/permissions/EntityShareActions';
import type { PaginatedUsersResponse, PaginatedUsers } from 'stores/users/UsersStore';
import type { PaginatedRolesResponse } from 'stores/roles/AuthzRolesStore';
import type { PaginatedRoles } from 'actions/roles/AuthzRolesActions';

import TeamsActions, { PaginatedTeams } from '../actions/TeamsActions';
import Team from '../logic/Team';
import type { TeamJSON } from '../logic/Team';

export type PaginatedTeamsResponse = PaginatedListJSON & {
  teams: Array<TeamJSON>,
  context: {
    users: {
      [userId: string]: { title: string },
    },
    roles: {
      [roleId: string]: { title: string },
    },
  };
};

const apiPrefix = '/plugins/org.graylog.plugins.security';

const ApiRoutes = {
  TeamsApiController: {
    loadTeamsForUser: (userId: string) => { return { url: `${apiPrefix}/teams/member/${userId}` }; },
    loadTeamsForRole: (roleId: string) => { return { url: `${apiPrefix}/authz/roles/${roleId}/teams` }; },
    loadUsersForTeam: (teamId: string) => { return { url: `${apiPrefix}/teams/${teamId}/members` }; },
    loadRolesForTeam: (teamId: string) => { return { url: `${apiPrefix}/teams/${teamId}/roles` }; },
    loadPaginated: () => { return { url: `${apiPrefix}/teams` }; },
    addMembers: (teamId: string) => { return { url: `${apiPrefix}/teams/${teamId}/members` }; },
    addMemberToTeams: (userId: string) => { return { url: `${apiPrefix}/teams/${userId}/teams` }; },
    addRoleToTeams: (roleId: string) => { return { url: `${apiPrefix}/authz/roles/${roleId}/teams/add` }; },
    removeMember: (teamId: string, userId: string) => { return { url: `${apiPrefix}/teams/${teamId}/members/${userId}` }; },
    removeRole: (roleId: string) => { return { url: `${apiPrefix}/authz/roles/${roleId}/teams/remove` }; },
    create: () => { return { url: `${apiPrefix}/teams` }; },
    delete: (teamId: string) => { return { url: `${apiPrefix}/teams/${teamId}` }; },
    load: (teamId: string) => { return { url: `${apiPrefix}/teams/${teamId}` }; },
    update: (teamId: string) => { return { url: `${apiPrefix}/teams/${teamId}` }; },
    teamSharesPaginated: (teamId: string) => { return { url: `${apiPrefix}/teams/shares/${teamId}` }; },
  },
};

const formatPaginatedSharesResponse = ({
  additional_queries: additionalQueries,
  context,
  count,
  entities,
  page,
  per_page: perPage,
  query,
  total,
}) => ({
  list: Immutable.List(entities.map((se) => SharedEntity.fromJSON(se))),
  context: {
    granteeCapabilities: context.grantee_capabilities,
  },
  pagination: {
    additionalQueries,
    page,
    perPage,
    query,
    count,
    total,
  },
});

const _responseToPaginatedList = ({ count, total, page, per_page, query, teams, context }: PaginatedTeamsResponse) => ({
  context: {
    users: Immutable.Map(context?.users),
    roles: Immutable.Map(context?.roles),
  },
  list: Immutable.List(teams.map(Team.fromJSON)),
  pagination: {
    page,
    perPage: per_page,
    query,
    count,
    total,
  },
});

const _responseToPaginatedUserList = ({ count, total, page, per_page, query, users }: PaginatedUsersResponse) => ({
  list: Immutable.List(users.map((u) => UserOverview.fromJSON(u))),
  pagination: {
    page,
    perPage: per_page,
    query,
    count,
    total,
  },
});

const _responseToPaginatedRolesList = ({ count, total, page, per_page, query, roles }: PaginatedRolesResponse) => ({
  list: Immutable.List(roles.map((r) => Role.fromJSON(r))),
  pagination: {
    page,
    perPage: per_page,
    query,
    count,
    total,
  },
});

const TeamsStore: Store<{}> = singletonStore(
  'enterprise.security.TeamsStore',
  () => Reflux.createStore({
    listenables: [TeamsActions],

    create(team: Team): Promise<Team> {
      const { url } = ApiRoutes.TeamsApiController.create();
      const promise = fetch('POST', qualifyUrl(url), team.toJSON()).then(Team.fromJSON);

      TeamsActions.create.promise(promise);

      return promise;
    },

    load(teamId: $PropertyType<Team, 'id'>): Promise<Team> {
      const { url } = ApiRoutes.TeamsApiController.load(teamId);
      const promise = fetch('GET', qualifyUrl(url)).then(Team.fromJSON);

      TeamsActions.load.promise(promise);

      return promise;
    },

    update(team: Team): Promise<Team> {
      const { url } = ApiRoutes.TeamsApiController.update(team.id);
      const promise = fetch('PUT', qualifyUrl(url), team.toJSON()).then(Team.fromJSON);

      TeamsActions.update.promise(promise);

      return promise;
    },

    delete(team: Team): Promise<Team> {
      const { url } = ApiRoutes.TeamsApiController.delete(team.id);
      const promise = fetch('DELETE', qualifyUrl(url));

      TeamsActions.delete.promise(promise);

      return promise;
    },

    addMembers(teamId: string, userIds: Immutable.Set<string>): Promise<Team> {
      const { url } = ApiRoutes.TeamsApiController.addMembers(teamId);
      const promise = fetch('PUT', qualifyUrl(url), userIds.toArray()).then(Team.fromJSON);

      TeamsActions.addMembers.promise(promise);

      return promise;
    },

    addMemberToTeams(userId: string, teamIds: Immutable.Set<string>): Promise<void> {
      const { url } = ApiRoutes.TeamsApiController.addMemberToTeams(userId);
      const promise = fetch('PUT', qualifyUrl(url), teamIds.toArray());

      TeamsActions.addMemberToTeams.promise(promise);

      return promise;
    },

    addRoleToTeams(roleId: string, teamIds: Immutable.Set<string>): Promise<void> {
      const { url } = ApiRoutes.TeamsApiController.addRoleToTeams(roleId);
      const promise = fetch('PUT', qualifyUrl(url), teamIds.toArray());

      TeamsActions.addRoleToTeams.promise(promise);

      return promise;
    },

    removeMember(teamId: string, userId: string): Promise<Team> {
      const { url } = ApiRoutes.TeamsApiController.removeMember(teamId, userId);
      const promise = fetch('DELETE', qualifyUrl(url)).then(Team.fromJSON);

      TeamsActions.removeMember.promise(promise);

      return promise;
    },

    removeRole(roleId: string, teamId: string): Promise<void> {
      const { url } = ApiRoutes.TeamsApiController.removeRole(roleId);
      const promise = fetch('PUT', qualifyUrl(url), teamId);

      TeamsActions.removeRole.promise(promise);

      return promise;
    },

    loadRolesForTeam(teamId: string, teamName: string, { page, perPage, query }: Pagination): Promise<PaginatedRoles> {
      const url = PaginationURL(ApiRoutes.TeamsApiController.loadRolesForTeam(teamId).url, page, perPage, query);

      const promise = fetch('GET', qualifyUrl(url))
        .then(_responseToPaginatedRolesList);

      TeamsActions.loadRolesForTeam.promise(promise);

      return promise;
    },

    loadUsersForTeam(teamId: string, teamName: string, { page, perPage, query }: Pagination): Promise<PaginatedUsers> {
      const url = PaginationURL(ApiRoutes.TeamsApiController.loadUsersForTeam(teamId).url, page, perPage, query);

      const promise = fetch('GET', qualifyUrl(url))
        .then(_responseToPaginatedUserList);

      TeamsActions.loadUsersForTeam.promise(promise);

      return promise;
    },

    loadTeamsForUser(userId: string, { page, perPage, query }: Pagination): Promise<PaginatedTeams> {
      const url = PaginationURL(ApiRoutes.TeamsApiController.loadTeamsForUser(userId).url, page, perPage, query);

      const promise = fetch('GET', qualifyUrl(url))
        .then(_responseToPaginatedList);

      TeamsActions.loadTeamsForUser.promise(promise);

      return promise;
    },

    loadTeamsForRole(roleId: string, { page, perPage, query }: Pagination): Promise<PaginatedTeams> {
      const url = PaginationURL(ApiRoutes.TeamsApiController.loadTeamsForRole(roleId).url, page, perPage, query);

      const promise = fetch('GET', qualifyUrl(url))
        .then(_responseToPaginatedList);

      TeamsActions.loadTeamsForRole.promise(promise);

      return promise;
    },

    loadTeamsPaginated({ page, perPage, query }: Pagination): Promise<PaginatedTeams> {
      const url = PaginationURL(ApiRoutes.TeamsApiController.loadPaginated().url, page, perPage, query);

      const promise = fetch('GET', qualifyUrl(url))
        .then(_responseToPaginatedList);

      TeamsActions.loadTeamsPaginated.promise(promise);

      return promise;
    },

    loadTeamSharesPaginated(teamId: string, { page, perPage, query, additionalQueries }: Pagination): Promise<PaginatedEntityShares> {
      const url = PaginationURL(ApiRoutes.TeamsApiController.teamSharesPaginated(teamId).url, page, perPage, query, additionalQueries);
      const promise = fetch('GET', qualifyUrl(url)).then(formatPaginatedSharesResponse);

      TeamsActions.loadTeamSharesPaginated.promise(promise);

      return promise;
    },
  }),
);

export { TeamsActions, TeamsStore };
