import { isPermitted } from 'util/PermissionsMixin';
import * as Immutable from 'immutable';
import * as React from 'react';

import WatchlistDomain from 'watchlist/domainActions/WatchlistDomain';
import watchlistFields from 'security-content/mappings/watchlistFields';
import type { WatchlistStatus } from 'watchlist/actions/WatchlistActions';

const fieldIsOnWatchlist = (watchlistStatus: WatchlistStatus, wlFieldType: string, field: string) => {
  return !!watchlistStatus?.[wlFieldType]?.includes(field);
};

const WatchlistAddition = {
  handler: (lookupTableKey: string) => ({ value }: { value?: string }) => {
    return WatchlistDomain.update(lookupTableKey, value);
  },
  isHidden: (
    watchlistFieldType: string,
    field: string,
    currentUserPermissions: Immutable.List<string>,
    watchlistStatus: WatchlistStatus,
  ) => {
    return !isPermitted(currentUserPermissions, 'watchlist:edit')
      || !watchlistFields[watchlistFieldType].includes(field)
      || fieldIsOnWatchlist(watchlistStatus, watchlistFieldType, field);
  },
};

const WatchlistRemoval = {
  handler: (lookupTableKey: string) => ({ value }: { value?: string }) => {
    return WatchlistDomain.delete(lookupTableKey, value);
  },
  isHidden: (
    watchlistFieldType: string,
    field: string,
    currentUserPermissions: Immutable.List<string>,
    watchlistStatus: WatchlistStatus,
  ) => {
    return !isPermitted(currentUserPermissions, 'watchlist:edit')
      || !watchlistFields[watchlistFieldType].includes(field)
      || !fieldIsOnWatchlist(watchlistStatus, watchlistFieldType, field);
  },
};

const WatchlistActionHelp = (isEnabled: boolean) => {
  if (!isEnabled) {
    return ({
      title: 'Action not possible',
      description: (
        <>
          This field value is an array. Removing multiple values from a watchlist is currently not supported.
          If you want to add or remove included values you can manually edit the watchlist lookup table.
        </>
      ),
    });
  }

  return undefined;
};

export { WatchlistAddition, WatchlistRemoval, WatchlistActionHelp };
