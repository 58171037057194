import React, { useState, useEffect } from 'react';

import ArchiveConfig from 'archive/components/ArchiveConfig';
import ArchiveCreationForm from 'archive/components/ArchiveCreationForm';
import ArchiveLicenseStatus from 'archive/components/ArchiveLicenseStatus';
import ArchiveActions from 'archive/ArchiveActions';
import { IndicesPropType, LicenseStatusPropType } from 'archive/propTypes';
import type { Indices } from 'stores/indices/IndicesStore';
import { Spinner, IfPermitted } from 'components/common';
import { Row, Col } from 'components/bootstrap';

import type { IndexStates, LicenseStatus } from '../types';

type Props = {
  indices: Indices,
  licenseStatus: LicenseStatus,
};

const ArchiveCreationSection = ({ indices, licenseStatus }: Props) => {
  const [currentIndices, setCurrentIndices] = useState<IndexStates | typeof undefined>(undefined);

  useEffect(() => {
    const summarizeIndices = (openIndices: Indices) => {
      return Object.keys(openIndices).reduce((obj, name: string) => {
        const update = obj;

        update[name] = {
          documentCount: openIndices[name].primary_shards.documents.count,
          storeSize: openIndices[name].primary_shards.store_size_bytes,
        };

        return update;
      }, {});
    };

    if (indices) {
      const newIndices = summarizeIndices(indices);
      setCurrentIndices(newIndices);
    }
  }, [indices]);

  const _createArchive = (indexName: string) => {
    ArchiveActions.createArchive(indexName);
  };

  const archiveCreation = () => {
    const { loading, status } = licenseStatus;

    if (loading) {
      return <Spinner text="Loading license information" />;
    }

    if (status && status.valid) {
      if (!currentIndices) {
        return <Spinner text="Loading indices" />;
      }

      return <ArchiveCreationForm indices={currentIndices} createArchive={_createArchive} />;
    }

    return <ArchiveLicenseStatus licenseStatus={licenseStatus} summary />;
  };

  return (
    <IfPermitted permissions={['archive:create', 'archiveconfig:read']} anyPermissions>
      <Row className="content">
        <IfPermitted permissions="archive:create">
          <Col md={6}>
            {archiveCreation()}
          </Col>
        </IfPermitted>
        <IfPermitted permissions="archiveconfig:read">
          <Col md={6}>
            <ArchiveConfig />
          </Col>
        </IfPermitted>
      </Row>
    </IfPermitted>
  );
};

ArchiveCreationSection.propTypes = {
  indices: IndicesPropType,
  licenseStatus: LicenseStatusPropType,
};

ArchiveCreationSection.defaultProps = {
  indices: {},
  licenseStatus: {
    status: undefined,
    missing: true,
    loading: true,
  },
};

export default ArchiveCreationSection;
