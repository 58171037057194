import { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Forwarder, Input } from 'forwarder/Types';
import { ForwardersActions, ForwarderListResponse } from 'forwarder/stores/ForwardersStore';
import { ForwarderInputsActions, PaginatedForwarderInputsResponse } from 'forwarder/stores/ForwarderInputsStore';

const getCurrentForwarder = (forwarders: Array<Forwarder>, forwarderNodeId: string) => {
  return forwarders.find((value) => value.node_id === forwarderNodeId);
};

const getCurrentInput = (inputs: Array<Input>, inputId: string) => {
  return inputs.find((value) => value.id === inputId);
};

const useReceivedBy = (inputId: string, forwarderNodeId: string) => {
  const [forwarder, setForwarder] = useState<Forwarder>();
  const [input, setInput] = useState<Input>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const fetchForwarderPromise = useRef<Promise<ForwarderListResponse>>();
  const fetchInputPromise = useRef<Promise<PaginatedForwarderInputsResponse>>();

  useEffect(() => {
    const fetchInputs = (inputProfileId: string) => {
      if (fetchInputPromise.current) {
        return;
      }

      fetchInputPromise.current = ForwarderInputsActions.listAll({ inputProfileId })
        .then((response: PaginatedForwarderInputsResponse) => {
          const currentInput = getCurrentInput(response.forwarder_inputs, inputId);
          setInput(currentInput);

          return response;
        })
        .finally(() => {
          fetchInputPromise.current = undefined;
          setIsLoading(false);
        });
    };

    const fetchForwarders = () => {
      if (fetchForwarderPromise.current) {
        return;
      }

      setIsLoading(true);

      fetchForwarderPromise.current = ForwardersActions.listAll()
        .then((response: ForwarderListResponse) => {
          const currentForwarder = getCurrentForwarder(response.forwarders, forwarderNodeId);
          setForwarder(currentForwarder);

          if (currentForwarder && currentForwarder.input_profile_id) {
            fetchInputs(currentForwarder.input_profile_id);
          }

          return response;
        })
        .finally(() => {
          fetchForwarderPromise.current = undefined;
        });
    };

    fetchForwarders();

    return () => {
      fetchForwarderPromise.current = undefined;
      setIsLoading(false);
    };
  }, [forwarderNodeId, inputId]);

  return { isLoading, forwarder, input };
};

useReceivedBy.propTypes = {
  inputId: PropTypes.string.isRequired,
  forwarderNodeId: PropTypes.string.isRequired,
};

export default useReceivedBy;
