import MongoDBDataAdapterFieldSet from './components/MongoDBDataAdapterFieldSet';
import MongoDBDataAdapterSummary from './components/MongoDBDataAdapterSummary';
import MongoDBDataAdapterDocumentation from './components/MongoDBDataAdapterDocumentation';

const lookupBindings = {
  lookupTableAdapters: [
    {
      type: 'mongodb',
      displayName: 'MongoDB',
      formComponent: MongoDBDataAdapterFieldSet,
      summaryComponent: MongoDBDataAdapterSummary,
      documentationComponent: MongoDBDataAdapterDocumentation,
    },
  ],
};

export default lookupBindings;
