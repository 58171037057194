import * as React from 'react';

import usePluginEntities from 'views/logic/usePluginEntities';
import { OptionSourceEditComponentProps } from 'enterprise/parameters/components/option/types';

const OptionSourceForm = ({ idx, onChange, onValidate, source, validationState }: OptionSourceEditComponentProps) => {
  const sourceTypes = usePluginEntities('views.parameters.option.sources');

  if (!source?.type) {
    return null;
  }

  const { editComponent: EditComponent } = sourceTypes.find(({ type }) => (type === source.type));

  return <EditComponent idx={idx} source={source} validationState={validationState} onChange={onChange} onValidate={onValidate} />;
};

export default OptionSourceForm;
