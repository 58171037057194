import Reflux from 'reflux';
import type * as Immutable from 'immutable';

import URLUtils from 'util/URLUtils';
import fetch from 'logic/rest/FetchProvider';
import type FieldType from 'views/logic/fieldtypes/FieldType';
import ParameterBinding from 'views/logic/parameters/ParameterBinding';
import type WidgetConfig from 'views/logic/widgets/WidgetConfig';
import type { RefluxActions } from 'stores/StoreTypes';
import type { ParameterJson } from 'views/logic/parameters/Parameter';
import type GlobalOverride from 'views/logic/search/GlobalOverride';

type WidgetSummary = {
  id: string,
  title: string,
  type: string,
  config: WidgetConfig,
  eligible: boolean,
  parameters: Array<ParameterJson>,
};
export type DashboardSummary = {
  id: string,
  title: string,
  widgets: { [queryId: string]: Array<WidgetSummary> },
  query_titles: { [queryId: string]: string },
  search_id: string,
};
type WidgetResult = {
  result: Array<any>,
  calculated_at: Date,
  field_types: Array<FieldType>,
};

type AvailableWidgetsActionsType = RefluxActions<{
  list: () => Promise<{ dashboards: Immutable.List<DashboardSummary> }>,
  value: (
    dashboardId: string,
    widgetId: string,
    parameterBindings: { [key: string]: ParameterBinding },
    globalOverride: GlobalOverride | undefined
  ) => Promise<WidgetResult>,
}>;

const AvailableWidgetsActions: AvailableWidgetsActionsType = Reflux.createActions({
  list: { asyncResult: true },
  value: { asyncResult: true },
});

const AvailableWidgetsStore = Reflux.createStore({
  listenables: [AvailableWidgetsActions],
  sourceUrl: '/plugins/org.graylog.plugins.report',

  value(dashboardId: string, widgetId: string, parameterBindings: { [key: string]: ParameterBinding }, globalOverride: GlobalOverride | undefined | {} = {}) {
    const request = {
      parameter_bindings: Object.entries(parameterBindings)
        .map(([key, value]): [string, ParameterBinding] => [key, ParameterBinding.create('value', value)])
        .reduce((prev, [key, binding]) => ({ ...prev, [key]: binding }), {}),
      global_override: globalOverride,
    };
    const promise = fetch('POST', URLUtils.qualifyUrl(`${this.sourceUrl}/widgets/${dashboardId}/${widgetId}/value`), request);
    AvailableWidgetsActions.value.promise(promise);

    return promise;
  },
});

export { AvailableWidgetsActions, AvailableWidgetsStore };
