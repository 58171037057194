import React, { useEffect } from 'react';
import { useTheme } from 'styled-components';

import { Spinner } from 'components/common';
import { LicensesStore, LicensesActions } from 'license/LicensesStore';
import { useStore } from 'stores/connect';
import TrafficGraph from 'components/cluster/TrafficGraph';

type Props = {
  traffic: { [key: string]: number },
  width: number,
};

const getMaxDailyValue = (arr) => {
  return arr.reduce((a, b) => {
    return Math.max(a, b);
  });
};

const EnterpriseTrafficGraph = ({ width, traffic }: Props) => {
  const theme = useTheme();
  const enterpriseLicense = useStore(LicensesStore, ({ licenses }) => licenses?.find((license) => license.subject === '/license/enterprise'));

  useEffect(() => {
    LicensesActions.list();
  }, []);

  if (!traffic) {
    return <Spinner />;
  }

  const getTrafficLimitLineAndAnnotation = () => {
    if (!enterpriseLicense) {
      return {};
    }

    const { enterprise: { traffic_limit: trafficLimit } } = enterpriseLicense;
    const range = getMaxDailyValue(Object.values(traffic));

    return {
      updatemenus: [
        {
          buttons: [
            {
              args: ['yaxis.range', [0, range]],
              args2: [{ 'yaxis.autorange': 'True' }, { 'yaxis.range': null }],
              label: 'Zoom/Reset',
              method: 'relayout',
            },
          ],
          direction: 'right',
          showactive: false,
          bordercolor: theme.colors.global.contentBackground,
          font: {
            color: theme.colors.global.link,
          },
          active: 1,
          type: 'buttons',
          visible: range < trafficLimit,
          xanchor: 'right',
          yanchor: 'top',
          x: 1,
          y: 1.3,
        },
      ],
      annotations: [
        {
          showarrow: false,
          text: '<b>Licensed traffic limit</b>',
          align: 'right',
          x: 1,
          xref: 'paper',
          xanchor: 'right',
          y: trafficLimit,
          yanchor: 'bottom',
          font: {
            color: theme.colors.variant.danger,
          },
        },
      ],
      shapes: [
        {
          type: 'line',
          x0: 0,
          x1: 1,
          y0: trafficLimit,
          y1: trafficLimit,
          name: 'Traffic Limit',
          xref: 'paper',
          yref: 'y',
          line: {
            color: theme.colors.variant.danger,
          },
        },
      ],
    };
  };

  return (
    <TrafficGraph traffic={traffic} width={width} layoutExtension={getTrafficLimitLineAndAnnotation()} />
  );
};

export default EnterpriseTrafficGraph;
