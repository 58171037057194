import Reflux from 'reflux';
import URI from 'urijs';

import fetch from 'logic/rest/FetchProvider';
import { qualifyUrl } from 'util/URLUtils';
import UserNotification from 'util/UserNotification';

import {
  Forwarder,
  ForwarderContext,
  ForwarderPagination,
  ForwardersContext,
  RequestedPagination,
} from '../Types';

export type ForwarderListResponse = {
  forwarders: Array<Forwarder>,
  forwardersPagination: ForwarderPagination,
  forwardersContext: {
    [id: string]: ForwarderContext,
  },
};

export type ForwarderResponse = {
  forwarder: Forwarder,
  forwarderContext: {
    [id: string]: ForwarderContext,
  },
};

type GetForwarderResponse = {
  forwarder: Forwarder,
  context: {
    forwarder: ForwardersContext,
  },
};

type PaginatedForwarderResponse = {
  total: number,
  page: number,
  per_page: number,
  count: number,
  query: string,
  forwarders: Array<Forwarder>,
  grand_total: number,
  context: {
    forwarder: ForwardersContext,
  },
};

export type ForwarderActionsTypes = {
  list: (params: RequestedPagination) => Promise<ForwarderListResponse>,
  listAll: () => Promise<ForwarderListResponse>,
  listPendingConfiguration: (params: RequestedPagination) => Promise<PaginatedForwarderResponse>,
  get: (id: string) => Promise<ForwarderResponse>,
  update: (forwarderId: string, updatedForwarder: Forwarder) => Promise<Forwarder>,
  delete: (forwarderId: string) => Promise<void>
  removeConfiguration: (forwarder: Forwarder) => Promise<Forwarder>,
  updates: (updatedForwarders: Array<Forwarder>) => Promise<void>,
};

export type ForwardersStoreState = {
  all: Array<Forwarder>,
  forwardersPendingConfiguration: Array<Forwarder>,
  forwardersPendingConfigurationPagination: {
    total: number,
    count: number,
    page: number,
    perPage: number,
    query: string,
  }
};

export const ForwardersActions = Reflux.createActions<ForwarderActionsTypes>({
  list: { asyncResult: true },
  listAll: { asyncResult: true },
  listPendingConfiguration: { asyncResult: true },
  get: { asyncResult: true },
  update: { asyncResult: true },
  delete: { asyncResult: true },
  removeConfiguration: { asyncResult: true },
  updates: { asyncResult: true },
});

const ForwardersStore = Reflux.createStore<ForwardersStoreState>({
  listenables: [ForwardersActions],
  sourceUrl: '/plugins/org.graylog.plugins.forwarder/forwarder/forwarders',
  all: undefined,
  forwardersPendingConfiguration: [],
  forwardersPendingConfigurationPagination: {
    total: 0,
    count: 0,
    page: 1,
    perPage: 10,
    query: '',
  },

  getInitialState() {
    return this.getState();
  },

  getState() {
    return {
      all: this.all,
      forwardersPendingConfiguration: this.forwardersPendingConfiguration,
      forwardersPendingConfigurationPagination: this.forwardersPendingConfigurationPagination,
    };
  },

  propagateUpdate() {
    this.trigger(this.getState());
  },

  _forwardersUrl({ segments = [], query = {} }) {
    const uri = new URI(this.sourceUrl);
    const nextSegments = uri.segment().concat(segments);
    uri.segmentCoded(nextSegments);
    uri.query(query);

    return qualifyUrl(uri.resource());
  },

  listPendingConfiguration({ query = '', page = 1, pageSize = 0, order = 'desc', sortByField = 'created_at' }: RequestedPagination) {
    const promise = fetch('GET', this._forwardersUrl({
      segments: ['pending_configuration'],
      query: {
        query: query,
        page: page,
        per_page: pageSize,
        order: order,
        sort: sortByField,
      },
    }));

    promise.then((response: PaginatedForwarderResponse) => {
      this.forwardersPendingConfiguration = response.forwarders;

      this.forwardersPendingConfigurationPagination = {
        total: response.total,
        count: response.count,
        page: response.page,
        perPage: response.per_page,
        query: query,
      };

      this.propagateUpdate();

      return response;
    },
    (error) => {
      UserNotification.error(`Retrieving List of Forwarders that are pending configuration failed with error : ${error},
        Could not get Forwarder list.`);
    });

    ForwardersActions.listPendingConfiguration.promise(promise);
  },

  list({ query = '', page = 1, pageSize = 10, order = 'asc', sortByField = 'state', stateFilter = 'any' }: RequestedPagination) {
    const promise = fetch('GET', this._forwardersUrl({
      query: {
        query: query,
        page: page,
        per_page: pageSize,
        order: order,
        sort: sortByField,
        state: stateFilter,
      },
    })).then(
      (response: PaginatedForwarderResponse): ForwarderListResponse => {
        return {
          forwarders: response.forwarders,
          forwardersPagination: {
            total: response.total,
            count: response.count,
            page: response.page,
            perPage: response.per_page,
            query: query,
            order: order,
            sortByField: sortByField,
            stateFilter: stateFilter,
          },
          forwardersContext: response.context.forwarder,
        };
      },
      (error) => {
        UserNotification.error(`Retrieving List of Forwarders failed with error : ${error},
          Could not get Forwarder list.`);
      },
    );

    ForwardersActions.list.promise(promise);
  },

  listAll() {
    const promise = fetch('GET', this._forwardersUrl({
      query: {
        query: '',
        page: 1,
        per_page: 0,
      },
    }));

    promise.then((response: PaginatedForwarderResponse) => {
      this.all = response.forwarders;
      this.propagateUpdate();

      return response;
    },
    (error) => {
      UserNotification.error(`Retrieving List of Forwarders failed with error : ${error},
        Could not get Forwarder list.`);
    });

    ForwardersActions.listAll.promise(promise);
  },

  get(id: string) {
    const url = qualifyUrl(`${this.sourceUrl}/${id}`);
    const promise = fetch('GET', url).then(
      (response: GetForwarderResponse): ForwarderResponse => {
        return { forwarder: response.forwarder, forwarderContext: response.context.forwarder };
      },
      (error) => {
        UserNotification.error(`Fetching forwarder failed with status: ${error.message},
        Could not retrieve forwarder with id:${id}`);
      },
    );

    ForwardersActions.get.promise(promise);
  },

  update(forwarderId: string, updatedForwarder: Forwarder) {
    const update = {
      title: updatedForwarder.title,
      description: updatedForwarder.description,
      hostname: updatedForwarder.hostname,
      input_profile_id: updatedForwarder.input_profile_id,
    };
    const promise = fetch('PUT', this._forwardersUrl({ segments: [forwarderId] }), update);

    promise.then((response) => {
      UserNotification.success('Forwarder details updated successfully');
      this.get(forwarderId);
      this.list({});

      return response;
    },
    (error) => {
      UserNotification.error(`Forwarder update failed with status: ${error.message},
        Could not update forwarder ${updatedForwarder.title}`);
    });

    ForwardersActions.update.promise(promise);
  },

  updates(updatedForwarders: Array<Forwarder>) {
    const promises = updatedForwarders.map((uf) => {
      return fetch('PUT', qualifyUrl(`${this.sourceUrl}/${uf.id}`),
        {
          title: uf.title,
          description: uf.description,
          input_profile_id: uf.input_profile_id,
        });
    });

    Promise.all(promises)
      .then(() => {
        this.listAll();
        UserNotification.success('Updated all forwarders successfully');
      })
      .catch((error) => {
        UserNotification.error(`Updating forwarders failed with status: ${error.message},
       Could not update forwarders`);
      });
  },

  delete(forwarderId: string) {
    const promise = fetch('DELETE', this._forwardersUrl({ segments: [forwarderId] }));

    promise.then((response) => {
      UserNotification.success('Forwarder successfully deleted.');
      this.list({});

      return response;
    },
    (error) => {
      UserNotification.error(`Deleting Forwarder failed with status: ${error.message},
        Could not delete forwarder with id: ${forwarderId}`);
    });

    ForwardersActions.delete.promise(promise);
  },

  removeConfiguration(forwarder: Forwarder) {
    const { title, description, hostname } = forwarder;

    const update = {
      title,
      description,
      hostname,
    };

    const promise = fetch('PUT', this._forwardersUrl({ segments: [forwarder.id] }), update);

    promise.then((response) => {
      UserNotification.success('Forwarder configuration successfully removed.');
      this.list({});

      return response;
    },
    (error) => {
      UserNotification.error(`Removing Forwarder configuration failed with status: ${error.message},
      Could not remove forwarder configuration with id: ${forwarder.id}`);
    });

    ForwardersActions.removeConfiguration.promise(promise);
  },
});

export default ForwardersStore;
