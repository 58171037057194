import * as React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';

import { LinkContainer } from 'components/common/router';
import { Button, Col, DropdownButton, MenuItem } from 'components/bootstrap';
import { EntityListItem } from 'components/common';
import Routes from 'routing/Routes';
import type { LicenseStatus } from 'archive/types';
import type { Report as ReportType } from 'report/types';
import ReportsActions from 'report/ReportsActions';

import ReportsStore from '../ReportsStore';
import { isValidLicense } from '../logic/license';

const _reportHasRecipients = (scheduling: ReportType['scheduling']) => {
  return scheduling && (
    (Array.isArray(scheduling.user_receivers) && scheduling.user_receivers.length > 0)
    || (Array.isArray(scheduling.email_receivers) && scheduling.email_receivers.length > 0)
  );
};

const sendReportText = (licenseStatus: LicenseStatus, reportHasRecipients: boolean) => {
  if (!isValidLicense(licenseStatus)) {
    return 'Sending report disabled, no valid license found';
  }

  if (!reportHasRecipients) {
    return 'Sending report disabled, add recipients first';
  }

  return 'Send report now';
};

const Scheduling = ({ scheduling }:{scheduling: ReportType['scheduling']}) => {
  if (!scheduling || isEmpty(scheduling) || !scheduling.active) {
    return <p>This report is not scheduled to be sent automatically.</p>;
  }

  return <p>This report is scheduled to be sent automatically on a {scheduling.frequency} basis.</p>;
};

const ReportActions = ({ report, licenseStatus }: { report: ReportType, licenseStatus: LicenseStatus}) => {
  const reportHasRecipients = _reportHasRecipients(report.scheduling);
  const isSendReportEnabled = reportHasRecipients && isValidLicense(licenseStatus);

  const onDeleteReport = () => {
    // eslint-disable-next-line no-alert
    if (window.confirm(`Do you really want to delete report "${report.title}"? This action cannot be undone.`)) {
      ReportsActions.delete(report.id, report.title);
    }
  };

  const onSendReport = () => {
    ReportsActions.sendEmail(report.id, report.title);
  };

  // TODO: Check permissions here
  return (
    <>
      <LinkContainer key="contents-button" to={Routes.pluginRoute('REPORTS_REPORTID_CONTENTS')(report.id)}>
        <Button bsStyle="info">Contents</Button>
      </LinkContainer>
      <LinkContainer key="configuration-button" to={Routes.pluginRoute('REPORTS_REPORTID_CONFIGURATION')(report.id)}>
        <Button bsStyle="info">Configuration</Button>
      </LinkContainer>
      <DropdownButton key="more-actions-button"
                      title="More actions"
                      pullRight
                      id={`more-actions-dropdown-${report.id}`}>
        <LinkContainer to={Routes.pluginRoute('REPORTS_REPORTID_HISTORY')(report.id)}>
          <MenuItem>Report history</MenuItem>
        </LinkContainer>
        <MenuItem href={ReportsStore.getDownloadURL(report.id)} target="_blank">Download report now</MenuItem>
        <MenuItem disabled={!isSendReportEnabled} onSelect={onSendReport}>
          {sendReportText(licenseStatus, reportHasRecipients)}
        </MenuItem>
        <MenuItem divider />
        <MenuItem onSelect={onDeleteReport}>Delete</MenuItem>
      </DropdownButton>
    </>
  );
};

const Report = ({ report, licenseStatus }:{ report: ReportType, licenseStatus: LicenseStatus }) => (
  <EntityListItem key={`entry-list-${report.id}`}
                  title={report.title}
                  description={report.description}
                  actions={<ReportActions report={report} licenseStatus={licenseStatus} />}
                  contentRow={<Col md={12}><Scheduling scheduling={report.scheduling} /></Col>} />
);

Report.propTypes = {
  report: PropTypes.object.isRequired,
  licenseStatus: PropTypes.object.isRequired,
};

export default Report;
