import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import URI from 'urijs';
import OktaSignIn from '@okta/okta-signin-widget';
import { Spinner } from 'components/common';
import { FormGroup } from 'components/bootstrap';

import CustomChosenStyles from 'authentication/components/oidc/okta/external-assets/CustomChosenStyles';
import OktaContainerStyles from 'authentication/components/oidc/okta/OktaContainerStyles';

type OktaLoginFormProps = {
  configuration: {
    oauth_client_id: string;
    oauth_auth_url:string;
    oauth_base_url: string;
    oauth_callback_url: string;
  };
  nonce: {
    state: string;
    redirectTo: string;
  };
  checkSession?: boolean;
};

const OktaLoginForm = ({ configuration, nonce, checkSession = false }: OktaLoginFormProps) => {
  const widget = useRef<OktaSignIn>();
  const [sessionState, setSessionState] = useState<'INITIAL' | 'CHECKING' | 'DONE'>(
    checkSession ? 'INITIAL' : 'DONE',
  );
  const widgetReady = useRef(false);
  const {
    oauth_base_url: baseUrl,
    oauth_auth_url: authorizeUrl,
    oauth_client_id: oauthClientId,
    oauth_callback_url: callbackUrl,
  } = configuration;

  useEffect(() => {
    let shouldCleanUp = false;

    const cleanUp = () => {
      widget.current.off();
      widget.current.remove();

      widgetReady.current = false;
    };

    // eslint-disable-next-line no-console
    const onError = (err) => console.error(err);

    widget.current = new OktaSignIn({
      baseUrl,
      clientId: oauthClientId,
      redirectUri: callbackUrl,
      useClassicEngine: true,
      authParams: {
        issuer: baseUrl,
        authorizeUrl,
        scopes: ['openid', 'profile', 'email'],
        state: nonce.state,
        responseType: 'code',
        pkce: false,
        // we don't use nonce on the backend so set to `null`
        nonce: null,
      },
      language: 'en',
      i18n: {
        en: {
          'primaryauth.username.placeholder': 'Email',
          'primaryauth.username.tooltip': 'Email',
          'password.forgot.email.or.username.placeholder': 'Email',
          'password.forgot.email.or.username.tooltip': 'Email',
          'error.username.required': 'Please enter an email address',
          'errors.E0000004': 'Invalid email or password',
          'mfa.factors.dropdown.sr.text': 'Using {0}, verify another way?',
          'mfa.factors.dropdown.title': 'Select a verification method',
          'email.code.not.received': 'Haven\'t received an email? ',
        },
      },
      features: {
        hideSignOutLinkInMFA: true,
        webauthn: true,
        multiOptionalFactorEnroll: false,
      },
    });

    widget.current.on('ready', () => {
      if (shouldCleanUp) {
        cleanUp();

        return;
      }

      widgetReady.current = true;
    });

    widget.current.showSignInAndRedirect({ el: '#okta-root' }).catch(onError);

    if (checkSession) {
      setSessionState('CHECKING');

      widget.current.authClient?.session.exists()
        .then((exists) => {
          if (exists) {
            const loginUri = new URI(authorizeUrl);

            loginUri.query({
              client_id: oauthClientId,
              response_type: 'code',
              scope: 'openid profile email',
              redirect_uri: callbackUrl,
              state: nonce.state,
            });

            window.location.assign(loginUri.href());
          } else {
            setSessionState('DONE');
          }
        });
    }

    return () => {
      if (widget.current && widgetReady.current) {
        cleanUp();

        return;
      }

      // Could not clean up at this stage, ready callback will take care of it
      shouldCleanUp = true;
    };
  }, [checkSession, oauthClientId, baseUrl, nonce.state, callbackUrl, authorizeUrl]);

  const isBusy = sessionState !== 'DONE';

  return (
    <FormGroup>
      {isBusy && <Spinner />}
      <CustomChosenStyles />
      {/* This DOM element needs to exist at any time because of Okta's bindings to the original DOM elements */}
      <OktaContainerStyles key="okta-root" id="okta-root" style={{ display: isBusy ? 'none' : 'block' }} />
    </FormGroup>
  );
};

OktaLoginForm.defaultProps = {
  checkSession: false,
};

OktaLoginForm.propTypes = {
  configuration: PropTypes.shape({
    oauth_base_url: PropTypes.string.isRequired,
    oauth_client_id: PropTypes.string.isRequired,
    oauth_auth_url: PropTypes.string.isRequired,
    oauth_callback_url: PropTypes.string.isRequired,
  }).isRequired,
  nonce: PropTypes.shape({
    state: PropTypes.string.isRequired,
    redirectTo: PropTypes.string.isRequired,
  }).isRequired,
  checkSession: PropTypes.bool,
};

export default OktaLoginForm;
