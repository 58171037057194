import React from 'react';
import PropTypes from 'prop-types';
import { Button, Panel } from 'components/bootstrap';

type Props = {
  eventKey: string,
  onStepComplete: () => void,
};

const InstallForwarderStep = ({ eventKey, onStepComplete }: Props) => {
  return (
    <Panel eventKey={eventKey}>
      <Panel.Heading>
        <Panel.Title>
          <Panel.Toggle tabIndex="0">1. Install Forwarder</Panel.Toggle>
        </Panel.Title>
      </Panel.Heading>
      <Panel.Body collapsible>
        <p>
          First download and install{' '}
          <a href="https://www.graylog.org/downloads/" target="_blank" rel="noopener noreferrer">
            Graylog Forwarder
          </a>{' '}
          in your infrastructure.
        </p>
        <Button bsStyle="primary" bsSize="small" onClick={onStepComplete}>Continue</Button>
      </Panel.Body>
    </Panel>
  );
};

InstallForwarderStep.propTypes = {
  eventKey: PropTypes.string.isRequired,
  onStepComplete: PropTypes.func.isRequired,
};

export default InstallForwarderStep;
