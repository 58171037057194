import React from 'react';
import PropTypes from 'prop-types';

import ProductLink from 'components/enterprise/ProductLink';

import LicenseCheck from './LicenseCheck';
import type { LicenseSubjectType } from './constants';

type Props = {
  children: React.ReactNode,
  href: string,
  clusterId: string,
  licenseSubject: LicenseSubjectType,
}

const EnterpriseProductLink = ({ children, clusterId, href, licenseSubject }: Props) => {
  return (
    <LicenseCheck licenseSubject={licenseSubject} displayLicenseWarning={false}>
      {({ licenseIsValid }) => {
        if (licenseIsValid) {
          return null;
        }

        return (
          <ProductLink href={href} clusterId={clusterId}>
            {children}
          </ProductLink>
        );
      }}
    </LicenseCheck>

  );
};

EnterpriseProductLink.propTypes = {
  children: PropTypes.node,
  href: PropTypes.string,
  clusterId: PropTypes.string,
  licenseSubject: PropTypes.string,
};

EnterpriseProductLink.defaultProps = {
  children: null,
  href: '',
  clusterId: null,
  licenseSubject: '/license/enterprise',
};

export default EnterpriseProductLink;
