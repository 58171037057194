import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import naturalSort from 'javascript-natural-sort';
import styled from 'styled-components';

import { IndexStatesPropType } from 'archive/propTypes';
import { Button } from 'components/bootstrap';
import { Select } from 'components/common';
import NumberUtils from 'util/NumberUtils';

import type { IndexStates } from '../types';

const StyledForm = styled.form`
  margin-top: 10px;
  margin-bottom: 15px;

  .form-group {
    width: 400px;
  }
`;

const StyledP = styled.p`
  margin-top: 5px;
`;

const _getDefaultIndex = (indices: IndexStates) => {
  return Object.keys(indices)
    .filter((indexName) => !indexName.endsWith('restored_archive'))
    .sort((a, b) => naturalSort(b.toLowerCase(), a.toLowerCase()))[0];
};

type Props = {
  indices: IndexStates,
  createArchive: (archiveName: string) => void,
};

const ArchiveCreationForm = ({ indices, createArchive }: Props) => {
  const [selectedIndex, setSelectedIndex] = useState<string | typeof undefined>();

  useEffect(() => {
    if (indices && !selectedIndex) {
      setSelectedIndex(_getDefaultIndex(indices));
    }
  }, [indices, selectedIndex]);

  const _formatSelectOptions = () => {
    const indexNames = Object.keys(indices).filter((indexName) => !indexName.endsWith('restored_archive'));
    let options = [];

    if (indexNames.length > 0) {
      options = indexNames.map((indexName) => {
        const docs = indices[indexName].documentCount;
        const size = NumberUtils.formatBytes(indices[indexName].storeSize);

        return {
          value: indexName,
          label: `${indexName} (${NumberUtils.formatNumber(docs)} documents / ${size})`,
          disabled: docs < 1,
        };
      });

      options.sort((a, b) => naturalSort(b.value.toLowerCase(), a.value.toLowerCase()));
    } else {
      options.push({ value: 'none', label: 'No indices available', disabled: true });
    }

    return options;
  };

  const _onIndexSelect = (selection) => {
    if (selection === '') {
      setSelectedIndex(_getDefaultIndex(indices));
    }

    setSelectedIndex(selection);
  };

  const _onSubmit = (e) => {
    e.preventDefault();

    if (selectedIndex) {
      createArchive(selectedIndex);
    }
  };

  const _isButtonDisabled = () => {
    return !selectedIndex || indices[selectedIndex]?.documentCount < 1;
  };

  return (
    <span>
      <h2>Create Archive for Index</h2>
      <StyledP>Select an index to be archived to disk. This action will not close or delete the original index.</StyledP>
      <StyledForm className="form-inline" onSubmit={_onSubmit}>
        <div className="form-group">
          <Select placeholder="Select index" options={_formatSelectOptions()} matchProp="value" onChange={_onIndexSelect} value={selectedIndex} />
        </div>
          &nbsp;
        <Button bsStyle="success" type="submit" disabled={_isButtonDisabled()}>Archive index</Button>
      </StyledForm>
    </span>
  );
};

ArchiveCreationForm.propTypes = {
  indices: IndexStatesPropType.isRequired,
  createArchive: PropTypes.func,
};

ArchiveCreationForm.defaultProps = {
  createArchive: () => {},
};

export default ArchiveCreationForm;
