import * as React from 'react';
import PropTypes from 'prop-types';
import { range } from 'lodash';
import moment from 'moment';

import Select from 'components/common/Select';

type Props = {
  disabled?: boolean,
  id: string,
  onChange: (value: string) => void,
  required?: boolean,
  value: string,
}

const TimeSelect = ({ id, value, disabled, required, onChange }: Props) => {
  const time = moment().minutes(0);
  const hours = range(0, 24).map((hour) => {
    const formattedTime = time.hours(hour).format('HH:mm');

    return { value: formattedTime, label: formattedTime };
  });

  return (
    <Select id={id}
            options={hours}
            value={value}
            placeholder="Select time of the day"
            disabled={disabled}
            onChange={onChange}
            required={required} />
  );
};

TimeSelect.propTypes = {
  id: PropTypes.string.isRequired,
  value: PropTypes.string,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};

TimeSelect.defaultProps = {
  value: undefined,
  disabled: false,
  required: false,
};

export default TimeSelect;
