import styled, { css } from 'styled-components';

import securityContentThemeVariables from 'security-app/theme/constants';

function renderPadding(fullscreen: boolean, sideNavIsOpen: boolean): string {
  if (fullscreen) return '0';

  if (sideNavIsOpen) return `${securityContentThemeVariables.measures.SIDE_NAV_WIDTH}px`;

  return '20px';
}

const ContentArea = styled.div<{ sideNavIsOpen: boolean; fullScreen: boolean; }>(({ sideNavIsOpen, fullScreen, theme }) => css`
  display: flex;
  flex-direction: column;
  padding-left: ${renderPadding(fullScreen, sideNavIsOpen)};
  padding-top: ${fullScreen ? '10px' : '20px'};
  transition: all 0.33s ease-in-out;
  position: ${fullScreen ? 'fixed' : 'relative'};
  width: 100%;
  height: 100%;
  overflow-y: auto;
  z-index: ${fullScreen ? '99999' : '10'};
  top: 0;
  background: ${theme.colors.global.background};
`);

export default ContentArea;
