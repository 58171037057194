import * as React from 'react';
import PropTypes from 'prop-types';
import { useQuery } from 'react-query';
import { useEffect } from 'react';

import fetch from 'logic/rest/FetchProvider';
import { qualifyUrl } from 'util/URLUtils';
import { LicensesActions } from 'license/LicensesStore';

import LicenseCheckContext from './LicenseCheckContext';

const useCheckLicenseAfterLicenseUpdate = (refetchLicenseChecks) => {
  useEffect(() => {
    const listeners = refetchLicenseChecks.flatMap((refetch) => ([
      LicensesActions.save.completed.listen(refetch),
      LicensesActions.remove.completed.listen(refetch),
    ]));

    return () => {
      listeners.forEach((unsubscribe) => unsubscribe());
    };
  }, [refetchLicenseChecks]);
};

const checkLicenseForSubject = (subject: string) => fetch(
  'GET',
  qualifyUrl(`/plugins/org.graylog.plugins.license/licenses/status/for-subject?subject=/license/${subject}`),
).then((response) => !!response?.status?.valid).catch(() => false);

const useLicenseCheckForSubjects = () => {
  const queryOptions = { refetchInterval: 600000, retry: 0 }; // 10 min refetch interval
  const { data: isValidSecurityLicense = false, isLoading: isLoadingSecurityLicense, refetch: refetchSecurityLicenseCheck } = useQuery(
    'securityLicenseCheck',
    () => checkLicenseForSubject('security'),
    queryOptions,
  );
  const { data: isValidIlluminateLicense = false, isLoading: isLoadingIlluminateLicense, refetch: refetchIlluminateLicenseCheck } = useQuery(
    'illuminateLicenseCheck',
    () => checkLicenseForSubject('enterprise/illuminate'),
    queryOptions,
  );

  useCheckLicenseAfterLicenseUpdate([refetchSecurityLicenseCheck, refetchIlluminateLicenseCheck]);

  return {
    subjects: {
      security: {
        isValid: isValidSecurityLicense,
        isLoading: isLoadingSecurityLicense,
      },
      illuminate: {
        isValid: isValidIlluminateLicense,
        isLoading: isLoadingIlluminateLicense,
      },
    },
  };
};

type Props = {
  children: React.ReactNode,
}

const LicenseCheckProvider = ({ children }: Props) => {
  const { subjects } = useLicenseCheckForSubjects();

  return (
    <LicenseCheckContext.Provider value={subjects}>
      {children}
    </LicenseCheckContext.Provider>
  );
};

LicenseCheckProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default LicenseCheckProvider;
