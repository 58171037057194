import React from 'react';
import styled from 'styled-components';

import { Icon } from 'components/common';
import { Warning } from 'license/LicenseCheck';

const Description = styled.div`
  max-width: 700px;
`;

const ErrorList = styled.ul`
  padding: 0;
`;

const Row = styled.div`
  margin-bottom: 5px;

  :last-child {
    margin-bottom: 0;
  }
`;

const SpinnerContainer = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledIcon = styled(Icon)`
  margin-right: 15px;
  margin: 3px 15px 0 0;
`;

const StyledWarning = styled(Warning)`
  padding: 0;
`;

const InvalidLicenseContent = () => (
  <SpinnerContainer>
    <StyledIcon name="crown" size="3x" />
    <Description>
      <Row>
        <strong>No valid license found</strong>
      </Row>
      <ErrorList>
        <StyledWarning featureName="Log View" />
      </ErrorList>
    </Description>
  </SpinnerContainer>
);

export default InvalidLicenseContent;
