import * as React from 'react';
import * as Immutable from 'immutable';
import { useState } from 'react';
import { Formik, Form } from 'formik';
import styled from 'styled-components';
import Routes from 'routing/Routes';
import history from 'util/History';
import { Alert, Col, Row, Button, ButtonToolbar, Input } from 'components/bootstrap';
import { Headline } from 'components/common/Section/SectionComponent';
import PaginatedItem from 'components/common/PaginatedItemOverview/PaginatedItem';
import RolesSelector from 'components/permissions/RolesSelector';
import type FetchError from 'logic/errors/FetchError';
import type UserOverview from 'logic/users/UserOverview';
import type Role from 'logic/roles/Role';

import LicenseCheck from '../../../license/LicenseCheck';
import NameFormGroup from '../form/NameFormGroup';
import DescriptionFormGroup from '../form/DescriptionFormGroup';
import Team from '../../logic/Team';
import TeamsDomain from '../../domainActions/TeamsDomain';
import UsersSelector from '../team-edit/UsersSelector';

const NoSelectedItem = styled.div`
  padding-top: 7px;
`;

const _onSubmit = ({ name, description }, users, roles, setSubmitError) => {
  setSubmitError(null);

  const newTeam = Team.builder()
    .name(name)
    .users(users)
    .roles(roles)
    .description(description)
    .build();

  return TeamsDomain.create(newTeam).then(() => {
    history.push(Routes.pluginRoute('SYSTEM_TEAMS'));
  },
  (error) => setSubmitError(error));
};

const TeamCreate = () => {
  const [submitError, setSubmitError] = useState<FetchError | undefined>();
  const [team, setTeam] = useState(Team.empty());
  const [selectedUsers, setSelectedUsers] = useState<Immutable.Set<UserOverview>>(Immutable.Set());
  const [selectedRoles, setSelectedRoles] = useState<Immutable.Set<Role>>(Immutable.Set());

  const _handleCancel = () => history.push(Routes.getPluginRoute('SYSTEM_TEAMS'));

  const _onAssignUser = (users) => {
    setSelectedUsers(selectedUsers.union(users));

    return Promise.resolve(
      setTeam(team.toBuilder().users(team.users.union(users.map((u) => u.id))).build()),
    );
  };

  const _onAssignRole = (roles) => {
    setSelectedRoles(selectedRoles.union(roles));

    return Promise.resolve(
      setTeam(team.toBuilder().roles(team.roles.union(roles.map((r) => r.id))).build()),
    );
  };

  const _onUnassignUser = (user) => {
    setSelectedUsers(selectedUsers.remove(user));
    setTeam(team.toBuilder().users(team.users.remove(user.name)).build());
  };

  const _onUnassignRole = (role) => {
    setSelectedRoles(selectedRoles.remove(role));
    setTeam(team.toBuilder().roles(team.roles.remove(role.id)).build());
  };

  return (
    <LicenseCheck featureName="teams" displayWarningContainer>
      {({ licenseIsValid }) => {
        if (!licenseIsValid) {
          return null;
        }

        return (
          <Row className="content">
            <Col lg={8}>
              <Formik onSubmit={(data) => _onSubmit(data, team.users, team.roles, setSubmitError)}
                      initialValues={{ name: undefined, description: undefined }}>
                {({ isSubmitting, isValid }) => (
                  <Form className="form form-horizontal">
                    <div>
                      <Headline>Profile</Headline>
                      <NameFormGroup />
                      <DescriptionFormGroup />
                    </div>
                    <div>
                      <Headline>Users</Headline>
                      <Input id="users-selector-input"
                             labelClassName="col-sm-3"
                             wrapperClassName="col-sm-9"
                             label="Assign Users">
                        <UsersSelector onSubmit={_onAssignUser} team={team} />
                      </Input>

                      <Input id="selected-users-overview"
                             labelClassName="col-sm-3"
                             wrapperClassName="col-sm-9"
                             label="Selected Users">
                        <>
                          {selectedUsers.map((user) => (
                            <PaginatedItem item={user}
                                           onDeleteItem={(data) => _onUnassignUser(data)}
                                           key={user.name} />
                          ))}
                          {selectedUsers.size <= 0 && <NoSelectedItem>No selected users</NoSelectedItem>}
                        </>
                      </Input>
                    </div>
                    <div>
                      <Headline>Roles</Headline>
                      <Input id="roles-selector-input"
                             labelClassName="col-sm-3"
                             wrapperClassName="col-sm-9"
                             label="Assign Roles">
                        <RolesSelector onSubmit={_onAssignRole} assignedRolesIds={team.roles} />
                      </Input>

                      <Input id="selected-users-overview"
                             labelClassName="col-sm-3"
                             wrapperClassName="col-sm-9"
                             label="Selected Roles">
                        <>
                          {selectedRoles.map((role) => (
                            <PaginatedItem item={role}
                                           onDeleteItem={(data) => _onUnassignRole(data)}
                                           key={role.name} />
                          ))}
                          {selectedRoles.size <= 0 && <NoSelectedItem>No selected roles</NoSelectedItem>}
                        </>
                      </Input>
                    </div>
                    {submitError && (
                      <Row>
                        <Col xs={9} xsOffset={3}>
                          <Alert bsStyle="danger">
                            <b>Failed to create team</b>
                            <br />
                            {submitError?.additional?.res?.text}
                          </Alert>
                        </Col>
                      </Row>
                    )}
                    <Row>
                      <Col md={9} mdOffset={3}>
                        <ButtonToolbar>
                          <Button bsStyle="success"
                                  disabled={isSubmitting || !isValid}
                                  title="Create Team"
                                  type="submit">
                            Create Team
                          </Button>
                          <Button type="button" onClick={_handleCancel}>Cancel</Button>
                        </ButtonToolbar>
                      </Col>
                    </Row>
                  </Form>
                )}
              </Formik>
            </Col>
          </Row>
        );
      }}
    </LicenseCheck>
  );
};

export default TeamCreate;
