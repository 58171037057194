import * as React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';

import Heading from 'report/common/Heading';
import { VisualizationContainer, Container } from 'report/common/ReportingWidget';
import type { BackendReportWidget } from 'report/types';

type Props = {
  widget: BackendReportWidget,
  error: Error,
}

const ReportingFallbackWidget = ({ widget, error }: Props) => (
  <Container>
    <Heading title={get(widget, 'description', 'Unknown widget')} />
    <VisualizationContainer>
      There was an error loading this widget. Please ensure the widget supports Reporting and it loads on its
      Dashboard.<br />
      <code>{error.toString()}</code>
    </VisualizationContainer>
  </Container>
);

ReportingFallbackWidget.propTypes = {
  widget: PropTypes.object.isRequired,
  error: PropTypes.instanceOf(Error).isRequired,
};

export default ReportingFallbackWidget;
