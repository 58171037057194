import { useQuery } from 'react-query';

import ArchiveActions from 'archive/ArchiveActions';
import UserNotification from 'util/UserNotification';

import type { Query } from '../types';

export const PAGE_SIZES = [20, 50, 100, 200, 500];
export const DEFAULT_PAGE_SIZE = 20;
export const DEFAULT_QUERY = {
  query: '',
  per_page: DEFAULT_PAGE_SIZE,
  page: 1,
};
export const DEFAULT_PAGINATION = {
  ...DEFAULT_QUERY,
  count: 0,
  total: 0,
};

export const urlPrefix = '/plugins/org.graylog.plugins.archive';
export const urlSuffix = '/cluster/archives/catalog';
export const ARCHIVE_QUERY_KEY = 'archives';

const refetchInterval = 5000;

const useArchivesQuery = (queryParams: Query) => {
  const { page, per_page: perPage, query } = queryParams;
  // console.log(ArchiveActions.searchPaginated);

  return useQuery([ARCHIVE_QUERY_KEY, { queryParams }], () => ArchiveActions.searchPaginated(page, perPage, query), {
    onError: (errorThrown) => {
      UserNotification.error(`Loading archives failed with status: ${errorThrown}`);
    },
    retry: 0,
    refetchInterval: refetchInterval,
    keepPreviousData: true,
    notifyOnChangeProps: ['data', 'error'],
    initialData: {
      archives: [],
      archives_context: {},
      backends_context: {},
      ...DEFAULT_PAGINATION,
    },
  });
};

export default useArchivesQuery;
