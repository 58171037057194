import React, { useContext, useEffect } from 'react';
import PageHeader from 'components/common/PageHeader';
import DocumentTitle from 'components/common/DocumentTitle';
import CustomizationContext from 'contexts/CustomizationContext';
import UserNotification from 'util/UserNotification';
import SectionGrid from 'components/common/Section/SectionGrid';
import SectionComponent from 'components/common/Section/SectionComponent';
import { useStore } from 'stores/connect';
import CustomizeHeaderBadgeActions from 'actions/customizations/CustomizationActions';

import CustomizationLicenseStatus from 'customization/CustomizationLicenseStatus';

import { CustomizationStore, CustomizationActions } from './stores/CustomizationStore';
import HeaderBadgeConfiguration, { BadgeConfig } from './header-badge/HeaderBadgeConfiguration';
import ThemeEditor from './theme-editor/ThemeEditor';
import PublicNotifications from './public-notifications/PublicNotifications';

const CUSTOM_HEADER_BADGE = 'org.graylog.plugins.customization.HeaderBadge';

const updateHeaderConfiguration = (config) => CustomizeHeaderBadgeActions.update(CUSTOM_HEADER_BADGE, config).then(() => {
  UserNotification.success('Successfully updated.');
});

const CustomizationPage = () => {
  const licenseStatus = useStore(CustomizationStore);
  const badgeConfig = useContext(CustomizationContext)[CUSTOM_HEADER_BADGE] as BadgeConfig;

  useEffect(() => {
    CustomizeHeaderBadgeActions.get(CUSTOM_HEADER_BADGE);
    CustomizationActions.getLicenseStatus();
  }, []);

  if (licenseStatus.missing || licenseStatus?.status?.expired) {
    return <CustomizationLicenseStatus licenseStatus={licenseStatus} />;
  }

  return (
    <DocumentTitle title="Customization">
      <div>
        <PageHeader title="Customization">
          <span>
            Here you can customize your Graylog experience.
          </span>
        </PageHeader>

        <SectionGrid>
          <div>
            <SectionComponent title="Modify Global Theme">
              <p>Any changes made & saved will affect all users.</p>
              <ThemeEditor />
            </SectionComponent>
          </div>

          <div>
            <SectionComponent title="Header Badge">
              <HeaderBadgeConfiguration config={badgeConfig} updateConfig={updateHeaderConfiguration} />
            </SectionComponent>

            <PublicNotifications />
          </div>
        </SectionGrid>

      </div>
    </DocumentTitle>
  );
};

export default CustomizationPage;
