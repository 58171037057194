import React from 'react';
import PropTypes from 'prop-types';

import connect from 'stores/connect';

import { MongoDBDataAdapterStore, MongoDBDataAdapterActions } from '../stores/MongoDBDataAdapterStore';

class MongoDBDataAdapterSummary extends React.Component {
  static propTypes = {
    statsData: PropTypes.shape({
      entriesCount: PropTypes.number,
    }),
    dataAdapter: PropTypes.object,
  };

  static defaultProps = {
    statsData: {
      entriesCount: 0,
    },
    dataAdapter: {},
  };

  constructor(props) {
    super(props);
    this.dataAdapterId = props.dataAdapter.id;
  }

  componentDidMount() {
    MongoDBDataAdapterActions.stats(this.dataAdapterId);
  }

  render() {
    const { statsData } = this.props;

    return (
      <dl>
        <dt>Database Entries</dt>
        <dd>{statsData.entriesCount || 0}</dd>
      </dl>
    );
  }
}

export default connect(MongoDBDataAdapterSummary, { adapter: MongoDBDataAdapterStore }, (props) => ({

  ...props,
  statsData: props.adapter.statsData || {},
}));
