import * as React from 'react';
import { useEffect, useState } from 'react';
import * as Immutable from 'immutable';
import SectionComponent from 'components/common/Section/SectionComponent';
import { Backend } from 'logic/authentication/okta/types';
import { LinkContainer } from 'components/common/router';
import Routes from 'routing/Routes';
import { Button, Alert, ButtonToolbar } from 'components/bootstrap';
import { ReadOnlyFormGroup, Spinner } from 'components/common';
import Role from 'logic/roles/Role';

import GroupSyncBackend from 'authentication/logic/directoryServices/GroupSyncBackend';
import { TriggerTeamSyncResult } from 'authentication/actions/AuthenticationActions';
import { GroupSyncActions } from 'authentication/stores/directoryServices/GroupSyncStore';
import TriggerTeamSyncButton from 'authentication/components/TriggerTeamSyncButton';
import { OKTA_TYPE_KEY } from 'authentication/components/oidc/constants';
import TriggerTeamSyncResponse from 'authentication/components/directoryServices/TriggerTeamSyncResponse';
import LicenseCheck from 'license/LicenseCheck';

type Props = {
  authenticationBackend: Backend,
  roles: Immutable.List<Role>,
};

const getRolesList = (roles: Immutable.List<Role>, defaultRolesIds: Immutable.Set<string>) => {
  if (!defaultRolesIds || defaultRolesIds.size === 0) {
    return null;
  }

  const defaultRolesNames = defaultRolesIds.map((roleId) => roles.find((role) => role.id === roleId)?.name);

  return defaultRolesNames.join(', ');
};

const _readableSelectionType = (selectionType) => {
  switch (selectionType) {
    case 'all':
      return 'All matching groups';
    case 'include':
      return 'Include selected groups';
    case 'exclude':
      return 'Exclude selected groups';
    default:
      return selectionType;
  }
};

const GroupSyncSection = ({ authenticationBackend, roles }: Props) => {
  const { id, title: backendTitle, config: { type } } = authenticationBackend;
  const [groupSyncBackend, setGroupSyncBackend] = useState<GroupSyncBackend | undefined>();
  const [finishedLoading, setFinishedLoading] = useState<boolean>(false);
  const [triggerSyncResponse, setTriggerSyncResponse] = useState<TriggerTeamSyncResult | null | undefined>();

  useEffect(() => {
    GroupSyncActions.load(id).then((result) => {
      setGroupSyncBackend(result?.backend);
    }).catch(() => {
      setGroupSyncBackend(undefined);
    }).finally(() => {
      setFinishedLoading(true);
    });
  }, [id]);

  if (type !== OKTA_TYPE_KEY) {
    return (
      <SectionComponent title="Group Synchronization">
        <Alert>
          This authentication service does not support group synchronization.
        </Alert>
      </SectionComponent>
    );
  }

  if (!finishedLoading) {
    return <Spinner />;
  }

  const {
    defaultRoles,
    selectionType,
    selection = Immutable.Set(),
  } = groupSyncBackend || {};

  const rolesList = (defaultRoles && defaultRoles.size >= 1) ? getRolesList(roles, defaultRoles) : null;

  return (
    <SectionComponent title="Group Synchronization"
                      headerActions={(
                        <ButtonToolbar>
                          {groupSyncBackend && (
                            <TriggerTeamSyncButton backendId={id}
                                                   backendTitle={backendTitle}
                                                   setResponse={setTriggerSyncResponse} />
                          )}
                          <LinkContainer to={Routes.SYSTEM.AUTHENTICATION.BACKENDS.edit(id, 'group_sync')}>
                            <Button bsStyle="success" bsSize="small">Edit</Button>
                          </LinkContainer>
                        </ButtonToolbar>
                      )}>
      {triggerSyncResponse && <TriggerTeamSyncResponse response={triggerSyncResponse} />}
      {groupSyncBackend ? (
        <LicenseCheck>
          <ReadOnlyFormGroup label="Team Default Roles" value={rolesList} />
          <ReadOnlyFormGroup label="Okta API Token" value="************" />
          <ReadOnlyFormGroup label="Selection Type" value={_readableSelectionType(selectionType)} />
          {selectionType !== 'all' && (
            <ReadOnlyFormGroup label="Selected Groups" value={`${selection.size} group(s)`} />
          )}
        </LicenseCheck>
      ) : (
        <Alert>
          Okta Group synchronization not configured for this authentication service.
        </Alert>
      )}
    </SectionComponent>
  );
};

export default GroupSyncSection;
