import { useEffect, useRef, useState } from 'react';
import type { Notification, Notifications } from 'theme/types';

import notifyingAction from 'domainActions/notifyingAction';
import Store from 'logic/local-storage/Store';

import { CustomizationActions } from '../stores/CustomizationStore';

const LOCAL_STORAGE = 'gl-notifications';
const AUTOLOAD_TIME_MS = 5000;

const handleCreatePublicNotifications = notifyingAction({
  action: CustomizationActions.createPublicNotification,
  success: () => ({
    message: 'Public Notification successfully created!',
  }),
  error: (error) => ({
    message: `Error Creating: ${error}`,
  }),
});

const handleDeletePublicNotifications = notifyingAction({
  action: CustomizationActions.deletePublicNotification,
  success: () => ({
    message: 'Public Notification successfully deleted!',
  }),
  error: (error) => ({
    message: `Error Deleting: ${error}`,
  }),
});

const handleUpdatePublicNotifications = notifyingAction({
  action: CustomizationActions.updatePublicNotification,
  success: () => ({
    message: 'Public Notification successfully edited!',
  }),
  error: (error) => ({
    message: `Error Editing: ${error}`,
  }),
});

const usePublicNotifications = () => {
  const autoLoadNotificationsTimer = useRef<ReturnType<typeof setTimeout>>();
  const [loaded, setLoaded] = useState<boolean>(false);
  const [notifications, setNotifications] = useState<Notifications>({});
  const dismissedNotifications = new Set(Store.get(LOCAL_STORAGE) || []);

  const _reload = (response) => {
    clearTimeout(autoLoadNotificationsTimer.current);
    setLoaded(false);

    return response;
  };

  const loadNotifications = () => {
    CustomizationActions.loadAllPublicNotifications().then((allNotifications) => {
      setNotifications(allNotifications);
    });
  };

  useEffect(() => {
    if (!loaded) {
      setLoaded(true);
      loadNotifications();
    } else {
      autoLoadNotificationsTimer.current = setTimeout(loadNotifications, AUTOLOAD_TIME_MS);
    }

    return () => clearTimeout(autoLoadNotificationsTimer.current);
  }, [loaded, notifications]);

  const onCreatePublicNotification = (newNotification: Notification): Promise<Notification> => {
    return handleCreatePublicNotifications(newNotification).then(_reload);
  };

  const onDeletePublicNotification = (id: string): Promise<0 | 1> => {
    return handleDeletePublicNotifications(id).then(_reload);
  };

  const onUpdatePublicNotification = (id: string, notification: Notification): Promise<Notification> => {
    return handleUpdatePublicNotifications(id, notification).then(_reload);
  };

  const onDismissPublicNotification = (id: string) => {
    const dismissed = Array.from(dismissedNotifications.add(id));
    Store.set(LOCAL_STORAGE, dismissed);

    _reload(dismissed);
  };

  return {
    notifications,
    dismissedNotifications,
    onCreatePublicNotification,
    onDeletePublicNotification,
    onDismissPublicNotification,
    onUpdatePublicNotification,
  };
};

export default usePublicNotifications;
