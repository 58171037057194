import * as React from 'react';
import { useState, useEffect, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import SecurityPage from 'pages/SecurityPage';

import Spinner from 'components/common/Spinner';
import { DocumentTitle, Icon } from 'components/common';
import { ContentArea, ContentHeader, Container, MenuButton, SideNav } from 'security-app/components/Layout/index';
import { FabButton, StyledLink } from 'security-app/components/common';
import useLicenseCheck from 'license/useLicenseCheck';
import StringUtils from 'util/StringUtils';
import { securityRoutes, anomalyDetectionRoutes } from 'security-app/routing/routes';
import PageContentLayout from 'components/layout/PageContentLayout';
import useSearchVersionCheck from 'hooks/useSearchVersionCheck';
import withIsPermitted from 'forwarder/util/withIsPermitted';
import CurrentUserContext from 'contexts/CurrentUserContext';
import { isPermitted } from 'util/PermissionsMixin';

type Props = {
  children: React.ReactNode,
};

const REQUIRED_PERMISSIONS = ['graylog_security:read'];

const SecurityLayout = ({ children }: Props) => {
  const [showSideBar, setShowSideBar] = useState(true);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [title, setTitle] = useState('security');
  const { security: { isValid: isValidSecurityLicense, isLoading: isLoadingSecurityLicense } } = useLicenseCheck();
  const location = useLocation();
  const { data: usingOpenSearch, isLoading: isLoadingSearchVersion } = useSearchVersionCheck('opensearch');
  const currentUser = useContext(CurrentUserContext);
  const anomalyDetectionAccess: boolean = isPermitted(currentUser.permissions, ['anomaly_configuration:read']);

  useEffect(() => {
    const searchPattern = /\/security\//;
    const newLocation: string = location.pathname.replace(searchPattern, '');

    if (newLocation === '/security') {
      setTitle('Security');
    } else {
      setTitle(StringUtils.toTitleCase(newLocation, '-'));
    }
  }, [location]);

  // no full screen option on configuration page nor anomalies dashboard if not using OpenSearch
  const onPageWithoutFullscreen = () => {
    return title.indexOf('Configuration') > -1
      || (title === 'Anomalies' && !usingOpenSearch.satisfied);
  };

  if (isLoadingSecurityLicense || isLoadingSearchVersion) {
    return (
      <Spinner />
    );
  }

  if (!isValidSecurityLicense) {
    return (
      <PageContentLayout>
        <SecurityPage />
      </PageContentLayout>
    );
  }

  // TODO: Add PageContentLayout, you can override the styles for it.
  return (
    <DocumentTitle title={title}>
      <Container>
        {!isFullScreen && (
          <SideNav isOpen={showSideBar}>
            <ul>
              {securityRoutes.map((route) => (
                <li key={route.id}>
                  <StyledLink exact to={route.path}>
                    <h2><Icon name={route.iconName} /></h2>
                    <span>{route.title}</span>
                  </StyledLink>
                </li>
              ))}
              {anomalyDetectionAccess
                && anomalyDetectionRoutes.map((route) => (
                  <li key={route.id}>
                    <StyledLink to={route.path}>
                      <h2><Icon name={route.iconName} /></h2>
                      <span>{route.title}</span>
                    </StyledLink>
                  </li>
                ))}
            </ul>

            <MenuButton sideNavIsOpen={showSideBar} onClick={() => setShowSideBar(!showSideBar)}>
              <Icon name={showSideBar ? 'arrow-left' : 'th'} />
            </MenuButton>
          </SideNav>
        )}
        <ContentArea fullScreen={!onPageWithoutFullscreen() && isFullScreen}
                     sideNavIsOpen={showSideBar}>
          <ContentHeader>
            <span />
            {!onPageWithoutFullscreen()
              && (
                <FabButton onClick={() => setIsFullScreen(!isFullScreen)}>
                  <Icon name={isFullScreen ? 'compress' : 'expand'} />
                </FabButton>
              )}
          </ContentHeader>
          {children}
        </ContentArea>
      </Container>
    </DocumentTitle>
  );
};

export default withIsPermitted(SecurityLayout, REQUIRED_PERMISSIONS);
