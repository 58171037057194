import * as React from 'react';
import { useState, useEffect } from 'react';
import * as Immutable from 'immutable';

import { SearchActions } from 'views/stores/SearchStore';
import { BackendMessage } from 'views/components/messagelist/Types';
import ExportTimerangeContextProvider from 'logview/components/contexts/ExportTimerangeContextProvider';

import ListStateProvider from './ListStateProvider';
import ScrollPositionProvider from './ScrollPositionProvider';

import type { LogViewSearchTypeData, TableRef, PageRefs } from '../LogViewWidget';

export const orderMessages = (messages: Array<BackendMessage>) => [...messages].reverse();

export type LogViewState = {
  status: 'initial' | 'reset' | 'update',
  listState: {
    after: LogViewSearchTypeData['after'],
    loadedPages: { [pageIndex: string]: Array<BackendMessage> },
    loadedMessagesCount: number,
    visiblePagesIds: Immutable.Set<number>,
  },
  scrollPositionUpdate: {
    direction: 'up' | 'down' | undefined,
    targetPage: number | undefined,
    targetPageOffsetTop: number | undefined,
    loadedAllPrevMessages: boolean,
  },
}

const _getInitialState = ({ after, messages, status }): LogViewState => ({
  status,
  listState: {
    after,
    loadedPages: { 1: messages },
    loadedMessagesCount: messages.length,
    visiblePagesIds: Immutable.Set([1]),
  },
  scrollPositionUpdate: {
    direction: undefined,
    targetPage: undefined,
    targetPageOffsetTop: undefined,
    loadedAllPrevMessages: false,
  },
});

const useResetOnSearchExecution = (resetListState: () => void) => {
  useEffect(() => {
    const unlistenSearchExecution = SearchActions.execute.completed.listen(() => {
      resetListState();
    });

    return () => {
      unlistenSearchExecution();
    };
  }, [resetListState]);
};

type Props = {
  children: React.ReactNode,
  pageRefs: PageRefs,
  tableRef: TableRef,
  queryId: string,
  searchTypeData: LogViewSearchTypeData,
}

const LogViewStateProvider = ({ searchTypeData, tableRef, pageRefs, children, queryId }: Props) => {
  const [logViewState, setLogViewState] = useState(_getInitialState({
    after: searchTypeData.after,
    messages: orderMessages(searchTypeData.messages),
    status: 'initial',
  }));

  // We need to change the type on every reset to ensure the component always rerenders.
  const resetListState = () => setLogViewState(_getInitialState({
    after: searchTypeData.after,
    messages: orderMessages(searchTypeData.messages),
    status: logViewState.status === 'initial' ? 'reset' : 'initial',
  }));

  const updateLogViewState = (newLogViewState: Omit<LogViewState, 'status'>) => {
    setLogViewState({
      ...newLogViewState,
      status: 'update',
    });
  };

  useResetOnSearchExecution(resetListState);

  return (
    <ScrollPositionProvider listStateStatus={logViewState.status}
                            pageRefs={pageRefs}
                            scrollPositionUpdate={logViewState.scrollPositionUpdate}
                            tableRef={tableRef}>
      <ListStateProvider tableRef={tableRef}
                         pageRefs={pageRefs}
                         searchTypeData={searchTypeData}
                         queryId={queryId}
                         listState={logViewState.listState}
                         updateLogViewState={updateLogViewState}>
        <ExportTimerangeContextProvider pages={logViewState.listState.loadedPages}>
          {children}
        </ExportTimerangeContextProvider>
      </ListStateProvider>
    </ScrollPositionProvider>
  );
};

export default LogViewStateProvider;
