const summaryTemplate = {
    authBasic: {
        summary: '[{gim_event_type}] {user_name} ({event_outcome})'
    },
    authPrimary: {
        summary: '[{gim_event_type}] {application_name} - {source_reference} - {user_name}'
    },
    authExtended: {
        summary: '[{gim_event_type}] {application_name} - {source_reference} - {user_domain} {user_name} ({event_outcome})'
    },
    networkConnection: {
        summary: '[{gim_event_type}] {source_ip}:{source_port} {destination_ip}:{destination_port} [{network_transport}]'
    },
    Alert: {
        summary: '[{gim_event_type}] {event_source_product} alert:{alert_signature} severity:{alert_severity}'
    },
    default: {
        summary: '[{gim_event_type}] {source}'
    }
};
const indicatorTemplate = {
    eventOutcome: {
        success: ['success'],
        danger: ['failure'],
        indicator: 'event_outcome',
        defaultIndicator: 'warning'
    },
    alertPrimary: {
        danger: ['critical'],
        indicator: 'alert_severity',
        defaultIndicator: 'info'
    }
};
const eventTypeCategories = {
    authentication: {
        logon: {
            summary: '[{gim_event_type}] {application_name} - {source_reference} - {user_domain}\\{user_name} - {destination_reference} ({event_outcome})',
            indicatorTemplate: indicatorTemplate.eventOutcome
        },
        'credential validation': {
            summaryTemplate: summaryTemplate.authExtended,
            indicatorTemplate: indicatorTemplate.eventOutcome
        },
        'kerberos request': {
            summaryTemplate: summaryTemplate.authPrimary,
            indicatorTemplate: indicatorTemplate.eventOutcome
        },
        'access notice': {
            summaryTemplate: summaryTemplate.authPrimary
        },
        'access policy': {
            summaryTemplate: summaryTemplate.authPrimary
        },
        logoff: {
            summaryTemplate: summaryTemplate.authPrimary
        },
        default: {
            summaryTemplate: summaryTemplate.authBasic,
            indicatorTemplate: indicatorTemplate.eventOutcome,
            defaultIndicator: 'info'
        }
    },
    iam: {
        'object create': {
            summary: '[{gim_event_type}] {source_user_name} created {user_name}. event_source:{event_source} event_source_product:{event_source_product}'
        },
        'object delete': {
            summary: '[{gim_event_type}] {source_user_name} deleted {user_name}. event_source:{event_source} event_source_product:{event_source_product}'
        },
        'object modify': {
            summary: '[{gim_event_type}] {source_user_name} modified {user_name}. event_source:{event_source} event_source_product:{event_source_product}'
        },
        'object disable': {
            summary: '[{gim_event_type}] Account object {user_name} was disabled by {source_user_name}. event_source:{event_source} event_source_product:{event_source_product}'
        },
        'object enable': {
            summary: '[{gim_event_type}] Account object {user_name} was enabled by {source_user_name}. event_source:{event_source} event_source_product:{event_source_product}'
        },
        information: {
            summary: '[{gim_event_type}] Account object {event_source} was accessed. event_source:{event_source} event_source_product:{event_source_product}'
        },
        default: {
            summaryTemplate: summaryTemplate.default
        }
    },
    network: {
        'network connection': {
            summaryTemplate: summaryTemplate.networkConnection
        },
        'network routing': {
            summaryTemplate: summaryTemplate.default
        },
        flow: {
            summaryTemplate: summaryTemplate.networkConnection
        },
        default: {
            summary: '[{gim_event_type}] {source_ip} - {destination_ip}'
        }
    },
    messaging: {
        email: {
            summaryTemplate: summaryTemplate.default
        },
        default: {
            summaryTemplate: summaryTemplate.default
        }
    },
    alert: {
        'network alert': {
            summary: '[{gim_event_type}] {event_source_product} alert:{alert_signature} severity:{alert_severity} from {source_reference} to {destination_reference}',
            indicatorTemplate: indicatorTemplate.alertPrimary
        },
        'host alert': {
            summary: '[{gim_event_type}] {event_source_product} alert:{alert_signature} severity:{alert_severity} on {host_reference}',
            indicatorTemplate: indicatorTemplate.alertPrimary
        },
        default: {
            summaryTemplate: summaryTemplate.Alert,
            indicatorTemplate: indicatorTemplate.alertPrimary
        }
    },
    'name resolution': {
        'dns request': {
            summaryTemplate: summaryTemplate.default
        },
        'dns transaction': {
            summary: '[{gim_event_type}] request:{query_request} result:{query_result}'
        },
        'dns answer': {
            summaryTemplate: summaryTemplate.default
        },
        'ddns update': {
            summaryTemplate: summaryTemplate.default
        },
        error: {
            summaryTemplate: summaryTemplate.default,
            defaultIndicator: 'warning'
        },
        default: {
            summaryTemplate: summaryTemplate.default
        }
    },
    database: {
        query: {
            summaryTemplate: summaryTemplate.default
        },
        update: {
            summaryTemplate: summaryTemplate.default
        },
        add: {
            summaryTemplate: summaryTemplate.default
        },
        delete: {
            summaryTemplate: summaryTemplate.default
        },
        default: {
            summaryTemplate: summaryTemplate.default
        }
    },
    endpoint: {
        process: {
            summary: '[{gim_event_type}] {user_name} created process {process_path} on {event_source} [{event_source_product}]'
        },
        ports: {
            summaryTemplate: summaryTemplate.default
        },
        filesystem: {
            summary: '[{gim_event_type}] {file_path}'
        },
        service: {
            summary: '[{gim_event_type}] {service_name}'
        },
        configuration: {
            summaryTemplate: summaryTemplate.default
        },
        audit: {
            summaryTemplate: summaryTemplate.default
        },
        pipe: {
            summary: '[{gim_event_type}] {event_type} by process {process_path} on {event_source}'
        },
        wmi: {
            summary: '[{gim_event_type}] {event_type} on {event_source} by {user_domain}\\{user_name}'
        },
        'agent activity': {
            summary: '[{gim_event_type}] Agent action:{event_action} by {user_name} returned {event_outcome} on {event_source} [{application_name}]',
            indicatorTemplate: indicatorTemplate.eventOutcome
        },
        'agent update': {
            summary: '[{gim_event_type}] Agent update action:{event_action} returned {event_outcome} on {event_source} [{application_name}]',
            indicatorTemplate: indicatorTemplate.eventOutcome
        },
        'agent status': {
            summary: '[{gim_event_type}] Agent Status event:{event_action} Event:{vendor_event_description} on {event_source} [{application_name}]'
        },
        performance: {
            summaryTemplate: summaryTemplate.default
        },
        default: {
            summaryTemplate: summaryTemplate.default
        }
    }
};
export { summaryTemplate, indicatorTemplate, eventTypeCategories };
