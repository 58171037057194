import React, { useEffect } from 'react';

import history from 'util/History';
import Store from 'logic/local-storage/Store';

const OidcCallbackPage = () => {
  const nonce = Store.sessionGet('nonce');

  useEffect(() => {
    history.replace(nonce.redirectTo || '/');
  }, [nonce]);

  useEffect(() => {
    return () => {
      Store.sessionDelete('nonce');
    };
  }, []);

  return <div>You have been successfully logged in. Please wait while we redirect your browser to the right page.</div>;
};

export default OidcCallbackPage;
