import validLicensePresent from 'license/ValidLicensePresent';

const addNotificationToTitle = (title) => (validLicensePresent() ? title : `${title} (Enterprise license is missing or invalid.)`);

export default {
  'views.export.formats': [
    {
      type: 'json',
      displayName: () => addNotificationToTitle('JSON'),
      mimeType: 'application/json',
      fileExtension: 'json',
      disabled: () => !validLicensePresent(),
    },
    {
      type: 'ndjson',
      displayName: () => addNotificationToTitle('NDJSON (Newline-delimited JSON)'),
      mimeType: 'application/x-ndjson',
      fileExtension: 'ndjson',
      disabled: () => !validLicensePresent(),
    },
    {
      type: 'log',
      displayName: () => addNotificationToTitle('Log File/Plain Text'),
      mimeType: 'text/plain',
      fileExtension: 'log',
      disabled: () => !validLicensePresent(),
    },
  ],
};
