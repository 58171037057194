import styled, { css } from 'styled-components';

import { FabButton } from 'security-app/components/common';
import securityContentThemeVariables from 'security-app/theme/constants';

function leftPosition(isOpen: boolean) {
  if (isOpen) {
    return '20px';
  }

  return `${securityContentThemeVariables.measures.CLOSED_NAV_LEFT}px`;
}

const MenuButton = styled(FabButton)(({ sideNavIsOpen }: { sideNavIsOpen: boolean}) => css`
  position: absolute;
  left: ${leftPosition(sideNavIsOpen)};
  bottom: 20px;
  z-index: 11;
`);
export default MenuButton;
