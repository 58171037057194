import styled, { css } from 'styled-components';
import type { DefaultTheme } from 'styled-components';

import securityContentThemeVariables from 'security-app/theme/constants';

const SideNav = styled.div(({ isOpen, theme }: { isOpen: boolean, theme: DefaultTheme }) => css`
  color: ${theme.utils.contrastingColor(theme.colors.global.navigationBackground, 'AA')};
  height: 100%;
  padding: 20px;
  width: ${securityContentThemeVariables.measures.SIDE_NAV_WIDTH}px;
  transition: all 0.33s ease-in-out;
  border-radius: 4px;
  position: absolute;
  left: ${isOpen ? '10px' : `-${securityContentThemeVariables.measures.CLOSED_NAV_LEFT}px`};
  z-index: 11;

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: grid;
    grid-template-columns: 1fr;
    gap: 10px;
  }
`);

export default SideNav;
