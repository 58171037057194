import * as React from 'react';
import { useEffect } from 'react';

import { useStore } from 'stores/connect';
import type { UserJSON } from 'logic/users/User';

import LicenseTrafficViolationNotification from './LicenseTrafficViolationNotification';

import { LicenseTrafficViolationActions, LicenseTrafficViolationStore } from '../LicenseTrafficViolationStore';

type Props = {
  currentUser: UserJSON,
};

const _loadTrafficViolation = () => {
  LicenseTrafficViolationActions.getTrafficViolation();
};

const LicenseTrafficViolationNotificationContainer = ({ currentUser }: Props) => {
  const { acknowledged } = useStore(LicenseTrafficViolationStore);

  useEffect(() => _loadTrafficViolation(), []);

  const acknowledgeViolationNotification = () => {
    LicenseTrafficViolationActions.acknowledgeTrafficViolation();
  };

  const shouldShowNotification = acknowledged !== undefined && !acknowledged;

  return (
    <div>
      {shouldShowNotification && (
        <LicenseTrafficViolationNotification currentUser={currentUser}
                                             onDismissNotification={acknowledgeViolationNotification} />
      )}
    </div>
  );
};

export default LicenseTrafficViolationNotificationContainer;
