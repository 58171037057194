import View from 'views/logic/views/View';

const CopyWidgetToDashboard = (widgetId: string, searchView: View, dashboard: View): View | undefined | null => {
  const { parameters } = searchView.search;

  if (!parameters || parameters.isEmpty()) {
    return dashboard;
  }

  const parametersToCopy = parameters.filter((searchParameter) => !dashboard.search.parameters.find((dashboardParameter) => searchParameter.name === dashboardParameter.name));
  const newParameters = dashboard.search.parameters.union(parametersToCopy);
  const newSearch = dashboard.search.toBuilder().parameters(newParameters.toJS()).newId().build();

  return dashboard.toBuilder().search(newSearch).build();
};

export default CopyWidgetToDashboard;
