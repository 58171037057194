import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'components/common/router';
import { Icon, PaginatedList } from 'components/common';
import { Table, Alert } from 'components/bootstrap';
import Routes from 'routing/Routes';

import { Forwarder, ForwarderPropType, ForwarderPagination, ForwarderPaginationPropType } from '../Types';
import ForwarderStateBadge from '../states/ForwarderStateBadge';

type Props = {
  forwarders: Array<Forwarder>,
  pagination: ForwarderPagination,
  onPageChange: (page?: number, perPage?: number, query?: string) => void,
};

const StyledBadge = styled('div')`
  margin-right: 20px
`;

const ForwardersTable = styled(Table)`
  .searchHelpButton {
      cursor: help;
      padding: 0 0 0 2px;
      margin-left: 5px;
    }
    .popoverWide {
      max-width: 500px;
      min-width: 350px;
    }
    tr > th {
      &.rowName {
        width: 50%;
      }
      &.rowActions {
        width: 200px;
      }
    }
`;

const InputProfilesForwarderList = ({ forwarders, pagination, onPageChange }: Props) => {
  const _getForwardersTable = () => {
    return forwarders.map((forwarder) => {
      return (
        <tr key={forwarder.id}>
          <td><Link to={Routes.pluginRoute('SYSTEM_FORWARDERS_FORWARDERID')(forwarder.id)}>{forwarder.title}</Link></td>
          <td>
            <StyledBadge>
              <ForwarderStateBadge forwarderId={forwarder.id} />
            </StyledBadge>
          </td>
          <td>
            <Link to={Routes.pluginRoute('SYSTEM_FORWARDERS_FORWARDERID')(forwarder.id)}>Open in Forwarder details</Link>
          </td>
        </tr>
      );
    });
  };

  const _handlePageChange = (page: number, pageSize: number) => {
    onPageChange(page, pageSize, pagination.query);
  };

  if (forwarders.length === 0) {
    return (
      <Alert bsStyle="info">
        <Icon name="info-circle" />&nbsp;
        There are no Forwarders using this Input Profile.
      </Alert>
    );
  }

  return (
    <PaginatedList onChange={_handlePageChange}
                   totalItems={pagination.total}
                   showPageSizeSelect={false}
                   activePage={pagination.page}
                   pageSize={pagination.perPage}>
      <ForwardersTable hover>
        <thead>
          <tr>
            <th className="rowName">Name</th>
            <th className="rowStatus">Status</th>
            <th className="rowActions">Actions</th>
          </tr>
        </thead>
        <tbody>
          {_getForwardersTable()}
        </tbody>
      </ForwardersTable>
    </PaginatedList>
  );
};

InputProfilesForwarderList.propTypes = {
  forwarders: PropTypes.arrayOf(ForwarderPropType).isRequired,
  pagination: ForwarderPaginationPropType.isRequired,
  onPageChange: PropTypes.func.isRequired,
};

export default InputProfilesForwarderList;
