import * as React from 'react';
import { useEffect, useState } from 'react';

import LookupTableParameter from 'views/logic/parameters/LookupTableParameter';
import { ValidationResult } from 'enterprise/parameters/components/ParameterDeclarationForm';
import { LookupTablesActions } from 'stores/lookup-tables/LookupTablesStore';
import LookupTableParameterEdit from 'components/lookup-table-parameters/LookupTableParameterEdit';

import { ParameterDeclarationComponentProps } from '../ParameterTypes';

const useLoadLookupTables = () => {
  const [lookupTables, setLookupTables] = useState<Array<any>>();

  useEffect(() => {
    LookupTablesActions.searchPaginated(1, 0, undefined, false).then(({ lookup_tables }) => setLookupTables(lookup_tables));
  }, []);

  return lookupTables;
};

const useValidation = ({ onValidate, tableKey, lookupTable, idx }) => {
  useEffect(() => {
    const _validationState: ValidationResult = (tableKey !== undefined && tableKey !== '')
      ? ['success']
      : ['error', 'Lookup table key is required'];
    onValidate(idx, { key: _validationState });

    return () => onValidate(idx, { key: ['success'] });
  }, [tableKey, idx, onValidate]);

  useEffect(() => {
    const _validationState: ValidationResult = (lookupTable !== undefined && lookupTable !== '')
      ? ['success']
      : ['error', 'Please select a lookup table'];
    onValidate(idx, { lookupTable: _validationState });

    return () => onValidate(idx, { lookupTable: ['success'] });
  }, [lookupTable, idx, onValidate]);
};

const LookupTableParameterForm = ({ idx, onChange, validationState, parameter, onValidate }: ParameterDeclarationComponentProps<LookupTableParameter>) => {
  const { key: tableKey, lookupTable } = parameter;
  const lookupTables = useLoadLookupTables();

  useValidation({ onValidate, tableKey, lookupTable, idx });

  return (
    <LookupTableParameterEdit onChange={onChange}
                              lookupTables={lookupTables}
                              identifier={idx}
                              parameter={parameter}
                              defaultExpandHelp={false}
                              validationState={validationState} />
  );
};

export default LookupTableParameterForm;
