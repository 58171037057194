import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import styled, { css, DefaultTheme } from 'styled-components';

import formatMetric from '../util/formatMetric';
import ForwardersContext from '../forwarders/ForwardersContext';

type Props = {
 forwarderId: string;
};

const StyledThroughput = styled.dl(({ theme } : {theme: DefaultTheme}) => css`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;

  @media all and (max-width: ${theme.breakpoints.min.lg}) {
    flex-direction: column;
  }

  > dd {
    min-width: 220px;
  }
`);

const ForwarderThroughput = ({ forwarderId }: Props) => {
  const { getForwarderContext } = useContext(ForwardersContext);
  const { metrics: forwarderMetrics } = getForwarderContext(forwarderId);

  if (!forwarderMetrics || forwarderMetrics.length === 0) {
    return <div>No metrics available</div>;
  }

  const { rate: { one_minute: oneMinute, five_minute: fiveMinute, fifteen_minute: fifteenMinute } } = forwarderMetrics[0];

  return (
    <StyledThroughput>
      <dd> {!Number.isNaN(oneMinute) && `1 minute average: ${formatMetric(oneMinute)} msg/s`} </dd>
      <dd> {!Number.isNaN(fiveMinute) && `5 minute average: ${formatMetric(fiveMinute)} msg/s`} </dd>
      <dd> {!Number.isNaN(fifteenMinute) && `15 minute average: ${formatMetric(fifteenMinute)} msg/s`} </dd>
    </StyledThroughput>
  );
};

ForwarderThroughput.propTypes = {
  forwarderId: PropTypes.string.isRequired,
};

export default ForwarderThroughput;
