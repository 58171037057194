import * as React from 'react';
import PropTypes from 'prop-types';

import { LinkContainer } from 'components/common/router';
import { Button, ButtonToolbar } from 'components/bootstrap';
import Routes from 'routing/Routes';

type Props = {
  action: 'create' | 'edit',
  disabled?: boolean,
  formElementId: string,
  isLoading: boolean,
  onCancel: () => void,
};

const ReportContentsToolbar = ({ action, formElementId, isLoading, onCancel, disabled }: Props) => {
  let submitText;

  if (action === 'create') {
    submitText = isLoading ? 'Creating report...' : 'Create report';
  } else {
    submitText = isLoading ? 'Updating report...' : 'Update report';
  }

  return (
    <ButtonToolbar>
      <Button type="submit" form={formElementId} bsStyle="success" disabled={isLoading || disabled}>
        {submitText}
      </Button>
      <LinkContainer to={Routes.pluginRoute('REPORTS')} onClick={onCancel}>
        <Button className="cancel">Cancel</Button>
      </LinkContainer>
    </ButtonToolbar>
  );
};

ReportContentsToolbar.propTypes = {
  action: PropTypes.oneOf(['create', 'edit']).isRequired,
  formElementId: PropTypes.string.isRequired,
  isLoading: PropTypes.bool,
  onCancel: PropTypes.func.isRequired,
};

ReportContentsToolbar.defaultProps = {
  disabled: false,
  isLoading: false,
};

export default ReportContentsToolbar;
