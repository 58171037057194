import AppConfig from 'util/AppConfig';
import { SecurityLayout } from 'security-app/components/Layout';
import {
  SecurityAnomaly,
  SecurityConfiguration,
  SecurityHostActivity,
  SecurityNetworkActivity,
  SecurityUserActivity,
  SecurityOverview,
  AnomalyDetectionEdit,
} from 'security-app/pages';

const allRoutes = [
  { path: '/security', component: SecurityOverview, permissions: '*', parentComponent: SecurityLayout },
  { path: '/security/user-activity', component: SecurityUserActivity, permissions: '*', parentComponent: SecurityLayout },
  { path: '/security/host-activity', component: SecurityHostActivity, permissions: '*', parentComponent: SecurityLayout },
  { path: '/security/network-activity', component: SecurityNetworkActivity, permissions: '*', parentComponent: SecurityLayout },
  { path: '/security/anomalies', component: SecurityAnomaly, permissions: '*', parentComponent: SecurityLayout },
  { path: '/security/configuration', component: SecurityConfiguration, permissions: '*', parentComponent: SecurityLayout },
];

if (AppConfig.isFeatureEnabled('anomaly_detection_customization')) {
  allRoutes.push(
    { path: '/security/configuration/detector/create', component: AnomalyDetectionEdit, permissions: '*', parentComponent: SecurityLayout },
    { path: '/security/configuration/detector/edit/:id', component: AnomalyDetectionEdit, permissions: '*', parentComponent: SecurityLayout },
  );
}

export default {
  routes: allRoutes,
};
