import * as React from 'react';

import Icon from 'components/common/Icon';
import MessagesWidget from 'views/logic/widgets/MessagesWidget';
import { WidgetActions } from 'views/stores/WidgetStore';
import Widget from 'views/logic/widgets/Widget';
import LogViewWidget from 'logview/logic/LogViewWidget';
import { WidgetActionType } from 'views/components/widgets/Types';

import validLicensePresent from '../license/ValidLicensePresent';

const CreateLogViewFromMessageTable: WidgetActionType = {
  type: 'toggle-log-view',
  action: (messageTable: MessagesWidget, { widgetFocusContext: { focusedWidget, setWidgetFocusing } }) => {
    if (focusedWidget?.id !== messageTable.id) {
      setWidgetFocusing(messageTable.id);
    }

    const logViewWidget = LogViewWidget.fromMessageTable(messageTable);

    return WidgetActions.update(messageTable.id, logViewWidget);
  },
  isHidden: (w: Widget) => (w.type !== MessagesWidget.type),
  title: () => <><Icon name="expand-arrows-alt" /> Switch to Log View</>,
  disabled: () => !validLicensePresent(),
};

export default CreateLogViewFromMessageTable;
