import * as React from 'react';
import { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';

import { SearchActions } from 'views/stores/SearchStore';
import { BackendMessage } from 'views/components/messagelist/Types';

import MessageDetailsContext from './MessageDetailsContext';
import ListStateContext from './ListStateContext';

const _nextMessageDetails = (visibleMessages, setActiveMessageDetailsId, activeMessageDetailsId) => {
  const index = visibleMessages.findIndex((message) => message.message._id === activeMessageDetailsId);

  if (index >= 0 && index < (visibleMessages.length - 1)) {
    return () => setActiveMessageDetailsId(visibleMessages[index + 1].message._id);
  }

  return undefined;
};

const _prevMessageDetails = (visibleMessages, setActiveMessageDetailsId, activeMessageDetailsId) => {
  const index = visibleMessages.findIndex((message) => message.message._id === activeMessageDetailsId);

  if (index > 0) {
    return () => setActiveMessageDetailsId(visibleMessages[index - 1].message._id);
  }

  return undefined;
};

type Props = {
  children: React.ReactNode,
}

const MessageDetailsProvider = ({ children }: Props) => {
  const { pages } = useContext(ListStateContext);
  const [activeMessageDetailsId, setActiveMessageDetailsId] = useState(null);
  const visibleMessages = pages.flatMap(([, messages]) => messages).toJS();
  const activeMessageDetails: BackendMessage = visibleMessages.find((message) => message.message._id === activeMessageDetailsId);
  const onSelectNextMessage = _nextMessageDetails(visibleMessages, setActiveMessageDetailsId, activeMessageDetailsId);
  const onSelectPreviousMessage = _prevMessageDetails(visibleMessages, setActiveMessageDetailsId, activeMessageDetailsId);

  const contextValue = {
    activeMessageDetails,
    selectNextMessage: onSelectNextMessage,
    selectPrevMessage: onSelectPreviousMessage,
    setActiveMessageDetailsId,
  };

  useEffect(() => SearchActions.execute.completed.listen(() => setActiveMessageDetailsId(undefined)), []);

  return (
    <MessageDetailsContext.Provider value={contextValue}>
      {children}
    </MessageDetailsContext.Provider>
  );
};

MessageDetailsProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default MessageDetailsProvider;
