/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import PropTypes from 'prop-types';

import MongoDBDataAdapterTable from './MongoDBDataAdapterTable';

const MongoDBDataAdapterDocumentation = (props) => {
  const { dataAdapterId } = props;

  return <MongoDBDataAdapterTable dataAdapterId={dataAdapterId} />;
};

MongoDBDataAdapterDocumentation.propTypes = {
  dataAdapterId: PropTypes.string,
};

MongoDBDataAdapterDocumentation.defaultProps = {
  dataAdapterId: '',
};

export default MongoDBDataAdapterDocumentation;
