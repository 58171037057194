import * as React from 'react';
import { useState } from 'react';
import { $PropertyType } from 'utility-types';
import AuthenticationBackend from 'logic/authentication/AuthenticationBackend';
import { Button } from 'components/bootstrap';
import { Spinner } from 'components/common';

import type { TriggerTeamSyncResult } from '../actions/AuthenticationActions';
import AuthenticationDomain from '../domainActions/AuthenticationDomain';

type Props = {
  backendId: $PropertyType<AuthenticationBackend, 'id'>,
  backendTitle: $PropertyType<AuthenticationBackend, 'title'>,
  setResponse: (response: TriggerTeamSyncResult | null | undefined) => void,
};

const TriggerTeamSyncButton = ({ backendId, backendTitle, setResponse }: Props) => {
  const [loading, setLoading] = useState(false);

  const _triggerTeamSync = () => {
    // eslint-disable-next-line no-alert
    if (window.confirm('Do you really want to trigger the group synchronization? Already synchronized teams will may get deleted if the group sync config changed. You can test the configuration on the edit page.')) {
      setResponse(undefined);
      setLoading(true);

      AuthenticationDomain.triggerTeamSync(backendId, backendTitle).then((response) => {
        setResponse(response);
        setLoading(false);
      }).catch(() => {
        setLoading(false);
      });
    }
  };

  return (
    <Button onClick={_triggerTeamSync} type="button" bsStyle="info" bsSize="small">
      {loading && <Spinner text=" " />}Trigger Synchronization
    </Button>
  );
};

export default TriggerTeamSyncButton;
