import * as Immutable from 'immutable';

import { SearchExecutionStateActions } from 'views/stores/SearchExecutionStateStore';
import View from 'views/logic/views/View';
import type { ViewHook } from 'views/logic/hooks/ViewHook';

type Arguments = {
  query: { [key: string]: any },
  view: View,
};

const bindParametersFromQuery: ViewHook = ({ query, view }: Arguments) => {
  // Get parameter values from query, filtered by parameter presence
  const queryParameterValues = Immutable.fromJS(query).filter((_, key) => view.search.parameters.find((p) => p.name === key));

  // Set filtered parameter values from query in search execution state.
  return SearchExecutionStateActions.setParameterValues(queryParameterValues).then(() => true);
};

export default bindParametersFromQuery;
