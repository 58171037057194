import * as React from 'react';
import { useState } from 'react';
import { Form, Formik, FieldArray, Field } from 'formik';
import styled, { css } from 'styled-components';
import type { DefaultTheme } from 'styled-components';
import lodash from 'lodash';

import { IfPermitted, Icon } from 'components/common';
import { Button, Input, Modal, Table } from 'components/bootstrap';

import type { TrafficLimitViolationConfigType } from './TrafficLimitViolationConfigStore';

const LabelSpan = styled.span(({ theme }: { theme: DefaultTheme }) => css`
  margin-left: ${theme.spacings.sm};
  font-weight: bold;
`);
const StyledTable = styled(Table)`
  margin-top: 10px;
`;

type Props = {
  saveConfig: (config: TrafficLimitViolationConfigType) => void,
  recipients: Array<string>,
}

const TrafficLimitViolationConfigForm = ({ saveConfig, recipients }: Props) => {
  const [showModal, setShowModal] = useState<boolean>(false);

  const _resetConfig = () => {
    setShowModal(false);
  };

  const _saveConfig = (config) => {
    const updatedConfig = { email_recipients: lodash.uniq(config.email_recipients) };
    saveConfig(updatedConfig);
    setShowModal(false);
  };

  return (
    <>
      <IfPermitted permissions="clusterconfigentry:edit">
        <Button type="button"
                bsSize="xs"
                bsStyle="info"
                onClick={() => {
                  setShowModal(true);
                }}>Configure
        </Button>
      </IfPermitted>
      <Modal show={showModal} onHide={_resetConfig} aria-modal="true" aria-labelledby="dialog_label">
        <Formik onSubmit={_saveConfig} initialValues={{ email_recipients: recipients }}>

          {({ isSubmitting, values }) => {
            return (
              <Form>
                <Modal.Header closeButton>
                  <Modal.Title id="dialog_label">Configure Traffic Limit Violation</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                  <FieldArray name="email_recipients"
                              render={(arrayHelpers) => (
                                <>
                                  <StyledTable striped bordered>
                                    <thead>
                                      <tr>
                                        <th>#</th>
                                        <th>Email</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {values.email_recipients && values.email_recipients.map((email, idx) => (
                                        // eslint-disable-next-line react/no-array-index-key
                                        <tr key={idx}>
                                          <td style={{ verticalAlign: 'middle', textAlign: 'center' }}>{idx + 1}</td>
                                          <td>
                                            <Field name={`email_recipients[${idx}]`}
                                                   value={email}>
                                              {({ field, meta }) => (
                                                <>
                                                  <Input id={`email-${idx}`}
                                                         name={field.name}
                                                         value={field.value}
                                                         onChange={field.onChange}
                                                         bsStyle={meta.touched && meta.error ? 'danger' : null}
                                                         onBlur={field.onBlur}
                                                         type="email"
                                                         required />
                                                  {meta.touched && meta.error && (<LabelSpan>{`${field.value} is not a valid email.`}</LabelSpan>)}
                                                </>
                                              )}
                                            </Field>
                                          </td>
                                          <td>
                                            <Button bsStyle="danger" onClick={() => arrayHelpers.remove(idx)}>
                                              <Icon name="trash-alt" />
                                            </Button>
                                          </td>
                                        </tr>
                                      ))}
                                    </tbody>
                                  </StyledTable>
                                  <Button type="button" onClick={() => arrayHelpers.push('')}>Add Recipient</Button>
                                </>
                              )} />
                </Modal.Body>

                <Modal.Footer>
                  <Button type="button" bsStyle="link" onClick={_resetConfig}>Close</Button>
                  <Button type="submit" bsStyle="success" disabled={isSubmitting}>{isSubmitting ? 'Saving' : 'Save'}</Button>
                </Modal.Footer>
              </Form>
            );
          }}
        </Formik>
      </Modal>
    </>
  );
};

export default TrafficLimitViolationConfigForm;
