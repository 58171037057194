import * as React from 'react';
import * as Immutable from 'immutable';
import { Link } from 'components/common/router';
import Routes from 'routing/Routes';
import { IfPermitted, CountBadge } from 'components/common';

import Team from '../../logic/Team';

type Props = {
  team: Team,
  usersContext: Immutable.Map<string, { title: string }>,
};

const MAX_USERS = 5;

const UsersCell = ({ team, usersContext }: Props) => {
  const users = team.users.take(MAX_USERS);
  const usersLength = users.size;
  const usersComponents = users.toArray().map((userId, index) => {
    const username = usersContext.get(userId)?.title;

    if (!username) {
      return `${userId} (Name not found)`;
    }

    return (
      <IfPermitted permissions={[`users:edit:${username}`]} key={userId}>
        <Link to={Routes.SYSTEM.USERS.show(userId)}>{username}</Link>{index < (usersLength - 1) && ',  '}
      </IfPermitted>
    );
  });

  if (team.users.size > MAX_USERS) {
    usersComponents.push(<span key="dots">...</span>);
  }

  return (
    <td className="limited">
      <CountBadge>{team.users.size}</CountBadge>
      {' '}
      {usersComponents}
    </td>
  );
};

export default UsersCell;
