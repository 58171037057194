import * as Immutable from 'immutable';

import type { ViewHook } from 'views/logic/hooks/ViewHook';
import Parameter from 'views/logic/parameters/Parameter';
import { SearchExecutionStateActions } from 'views/stores/SearchExecutionStateStore';

import { SearchParameterActions } from '../stores/SearchParameterStore';

const loadParametersFromView: ViewHook = ({ view }) => {
  const { search } = view;
  const searchParameters: Immutable.Set<Parameter> = search.parameters || Immutable.Set();
  const parametersWithDefault = searchParameters.filter((p) => {
    return p.defaultValue !== '' && p.defaultValue !== undefined && p.defaultValue !== null;
  });
  const parameterBindings = Immutable.Map<string, Parameter>(parametersWithDefault.map((parameter: Parameter) => [
    parameter.name,
    parameter.defaultValue,
  ]));

  return Promise.all([SearchParameterActions.load(searchParameters), SearchExecutionStateActions.setParameterValues(parameterBindings)])
    .then(() => true);
};

export default loadParametersFromView;
