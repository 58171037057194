import connect from 'stores/connect';
import { SearchExecutionStateStore } from 'views/stores/SearchExecutionStateStore';

import Parameters from './Parameters';

const ParametersWithParameterBindings = connect(
  Parameters,
  { executionState: SearchExecutionStateStore },
  ({ executionState }) => ({ parameterBindings: executionState.parameterBindings }),
);

export default ParametersWithParameterBindings;
