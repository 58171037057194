import type { IconName } from 'components/common/Icon';

type RouteObject = {
  id: string;
  path: string;
  iconName: IconName;
  title: string;
  exact: boolean;
}

export const securityRoutes: RouteObject[] = [
  {
    id: 'security-main',
    path: '/security',
    iconName: 'poll',
    title: 'Overview',
    exact: true,
  },
  {
    id: 'security-user-activity',
    path: '/security/user-activity',
    iconName: 'user',
    title: 'User Activity',
    exact: false,
  },
  {
    id: 'security-host-activity',
    path: '/security/host-activity',
    iconName: 'tv',
    title: 'Host Activity',
    exact: false,
  },
  {
    id: 'security-network-activity',
    path: '/security/network-activity',
    iconName: 'wifi',
    title: 'Network Activity',
    exact: false,
  },
  {
    id: 'security-anomalies',
    path: '/security/anomalies',
    iconName: 'search-plus',
    title: 'Anomalies',
    exact: false,
  },
];

export const anomalyDetectionRoutes: RouteObject[] = [
  {
    id: 'security-configuration',
    path: '/security/configuration',
    iconName: 'wrench',
    title: 'Configuration',
    exact: false,
  },
];
