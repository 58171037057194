import styled from 'styled-components';

const ContentHeader = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0 10px;

  span {
    flex: 1;
  }
`;

export default ContentHeader;
