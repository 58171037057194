import * as React from 'react';
import PropTypes from 'prop-types';
import { useCallback, useMemo } from 'react';
import lodash from 'lodash';
import moment from 'moment';

import { Col, FormControl, Row, Input } from 'components/bootstrap';
import { Icon, Select } from 'components/common';
import DateTime from 'logic/datetimes/DateTime';
import TimezoneSelect from 'components/common/TimezoneSelect';
import type { ReportScheduling } from 'report/types';

import MinuteOfHourInput, { fromFormValue as hourFormFormValue } from './MinuteOfHourInput';
import TimeSelect from './TimeSelect';
import DayOfWeekSelect from './DayOfWeekSelect';
import DayOfMonthSelect from './DayOfMonthSelect';

export const FREQUENCY_OPTIONS: {
  [key: string ]: { value: ReportScheduling['frequency'], label: string }
} = {
  hourly: {
    value: 'hourly',
    label: 'Hourly',
  },
  daily: {
    value: 'daily',
    label: 'Daily',
  },
  weekly: {
    value: 'weekly',
    label: 'Weekly',
  },
  monthly: {
    value: 'monthly',
    label: 'Monthly',
  },
};

const frequencyOptions = Object.values(FREQUENCY_OPTIONS);

const frequencyHelpText = (frequency: ReportScheduling['frequency']) => {
  switch (frequency) {
    case FREQUENCY_OPTIONS.hourly.value:
      return 'Select the minute of the hour when the report should be sent out.';
    case FREQUENCY_OPTIONS.daily.value:
      return 'Select the time of the day when the report should be sent out.';
    case FREQUENCY_OPTIONS.weekly.value:
      return 'Select the day of the week and time of the day when the report should be sent out.';
    case FREQUENCY_OPTIONS.monthly.value:
      return 'Select the day of the month and time of the day when the report should be sent out.';
    default:
      return <div />;
  }
};

const frequencySummary = (frequency: ReportScheduling['frequency'], frequencyConfiguration: Partial<ReportScheduling['frequency_configuration']>) => {
  const placeholder = 'No options selected.';

  if (!frequency || !frequencyConfiguration) {
    return placeholder;
  }

  const getUTCTime = (time: string, timezone: string) => {
    if (!time) {
      return time;
    }

    const [hours, minutes] = time.split(':');
    const dateTime = new DateTime(new Date())
      .toTimeZone(timezone ?? 'UTC')
      .toMoment()
      .hours(Number(hours))
      .minutes(Number(minutes));

    return dateTime.utc().format('HH:mm');
  };

  const formatTime = (time: string, timezone: string) => {
    return `${time} local time (${getUTCTime(time, timezone)} UTC)`;
  };

  switch (frequency) {
    case FREQUENCY_OPTIONS.hourly.value:
      if (frequencyConfiguration.time) {
        return `Report will be sent on minute ${hourFormFormValue(frequencyConfiguration.time)} of every hour`;
      }

      break;
    case FREQUENCY_OPTIONS.daily.value:
      if (frequencyConfiguration.time) {
        return `Report will be sent every day at ${formatTime(frequencyConfiguration.time, frequencyConfiguration.timezone)}.`;
      }

      break;
    case FREQUENCY_OPTIONS.weekly.value:
      if (frequencyConfiguration.day_of_week && frequencyConfiguration.time) {
        return `Report will be sent every ${lodash.upperFirst(frequencyConfiguration.day_of_week)} at ${formatTime(frequencyConfiguration.time, frequencyConfiguration.timezone)}.`;
      }

      break;
    case FREQUENCY_OPTIONS.monthly.value:
      if (frequencyConfiguration.day_of_month && frequencyConfiguration.time) {
        const ordinalDay = moment.localeData().ordinal(lodash.upperFirst(frequencyConfiguration.day_of_month));

        return `Report will be sent the ${ordinalDay} day of every month at ${formatTime(frequencyConfiguration.time, frequencyConfiguration.timezone)}.`;
      }

      break;
    default:
      break;
  }

  return placeholder;
};

const FrequencyControls = ({
  frequency,
  children,
  frequencyConfiguration,
  disabled,
  handleFrequencyConfigurationChange,
}: {
  frequency: ReportScheduling['frequency'],
  children: React.ReactNode,
  frequencyConfiguration: Partial<ReportScheduling['frequency_configuration']> | undefined,
  disabled: boolean,
  handleFrequencyConfigurationChange: (name: keyof ReportScheduling['frequency_configuration']) => (value: string) => void,
}) => {
  switch (frequency) {
    case FREQUENCY_OPTIONS.hourly.value:
      return (
        <Row>
          <Col sm={4}>
            <MinuteOfHourInput id="minute_of_hour"
                               value={frequencyConfiguration?.time}
                               disabled={disabled}
                               required
                               onChange={handleFrequencyConfigurationChange('time')} />
          </Col>
        </Row>
      );
    case FREQUENCY_OPTIONS.daily.value:
      return (
        <Row>
          {children}
        </Row>
      );
    case FREQUENCY_OPTIONS.weekly.value:
      return (
        <Row>
          <Col sm={4}>
            <DayOfWeekSelect id="day_of_week"
                             value={frequencyConfiguration?.day_of_week}
                             disabled={disabled}
                             required
                             onChange={handleFrequencyConfigurationChange('day_of_week')} />
          </Col>
          {children}
        </Row>
      );
    case FREQUENCY_OPTIONS.monthly.value:
      return (
        <Row>
          <Col sm={4}>
            <DayOfMonthSelect id="day_of_month"
                              value={frequencyConfiguration?.day_of_month}
                              disabled={disabled}
                              required
                              onChange={handleFrequencyConfigurationChange('day_of_month')} />
          </Col>
          {children}
        </Row>
      );
    default:
      return <div />;
  }
};

type Props = {
  disabled: boolean,
  frequency: ReportScheduling['frequency'] | undefined,
  frequency_configuration: Partial<ReportScheduling['frequency_configuration']> | undefined,
  onChange: (newFrequency: {
    frequency: ReportScheduling['frequency'],
    frequency_configuration: ReportScheduling['frequency_configuration']
  }) => void
}

const RecurringEventControl = ({ disabled, frequency, frequency_configuration: frequencyConfiguration, onChange }: Props) => {
  const propagateChanges = useCallback((newFrequency, configuration) => {
    const update = {
      frequency: newFrequency,
      frequency_configuration: configuration,
    };

    onChange(update);
  }, [onChange]);

  const handleFrequencyChange = useCallback((value) => {
    propagateChanges(value, { timezone: DateTime.getUserTimezone() });
  }, [propagateChanges]);

  const handleFrequencyConfigurationChange = useCallback((name: keyof ReportScheduling['frequency_configuration']) => {
    return (value: unknown) => {
      const updatedConfiguration = { ...(frequencyConfiguration ?? {}), [name]: value };

      propagateChanges(frequency, updatedConfiguration);
    };
  }, [frequency, frequencyConfiguration, propagateChanges]);

  const helpText = useMemo(() => frequencyHelpText(frequency), [frequency]);
  const summary = useMemo(() => frequencySummary(frequency, frequencyConfiguration), [frequency, frequencyConfiguration]);

  return (
    <div>
      <Input id="frequency-select" label="Frequency" help="Select how often this report should be sent out.">
        <Select id="frequency"
                options={frequencyOptions}
                value={frequency}
                placeholder="Select a frequency"
                disabled={disabled}
                required
                onChange={handleFrequencyChange} />
      </Input>
      {frequency && (
        <Input id="frequency-options-preview"
               label={`${FREQUENCY_OPTIONS[frequency].label} frequency options`}
               help={helpText}>
          <div>
            <FormControl.Static className="text-info">
              <Icon name="info-circle" /> {summary}
            </FormControl.Static>
            <FrequencyControls frequency={frequency}
                               frequencyConfiguration={frequencyConfiguration}
                               disabled={disabled}
                               handleFrequencyConfigurationChange={handleFrequencyConfigurationChange}>

              <>
                <Col sm={4}>
                  <TimeSelect id="time"
                              value={frequencyConfiguration?.time}
                              disabled={disabled}
                              required
                              onChange={handleFrequencyConfigurationChange('time')} />
                </Col>
                <Col sm={4}>
                  <TimezoneSelect value={frequencyConfiguration?.timezone ?? 'UTC'}
                                  disabled={disabled}
                                  clearable={false}
                                  onChange={handleFrequencyConfigurationChange('timezone')} />
                </Col>
              </>
            </FrequencyControls>
          </div>
        </Input>
      )}
    </div>
  );
};

RecurringEventControl.propTypes = {
  disabled: PropTypes.bool,
  frequency: PropTypes.string,
  frequency_configuration: PropTypes.object,
  onChange: PropTypes.func.isRequired,
};

RecurringEventControl.defaultProps = {
  disabled: false,
  frequency: undefined,
  frequency_configuration: undefined,
};

export default RecurringEventControl;
