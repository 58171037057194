import PropTypes from 'prop-types';

const paginationShape = {
  total: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  per_page: PropTypes.number.isRequired,
  count: PropTypes.number.isRequired,
  query: PropTypes.string,
};

export const PaginationPropType = PropTypes.exact(paginationShape);

export const QueryParamsPropType = PropTypes.exact({
  page: PropTypes.number.isRequired,
  per_page: PropTypes.number.isRequired,
  query: PropTypes.string,
});

export const SegmentPropType = PropTypes.exact({
  path: PropTypes.string.isRequired,
  size: PropTypes.number.isRequired,
  raw_size: PropTypes.number.isRequired,
  compression_type: PropTypes.string.isRequired,
  checksum: PropTypes.string.isRequired,
  checksum_type: PropTypes.string.isRequired,
});

export const ArchivePropType = PropTypes.exact({
  id: PropTypes.string.isRequired,
  archive_id: PropTypes.string.isRequired,
  index_name: PropTypes.string.isRequired,
  document_count: PropTypes.number.isRequired,
  index_size: PropTypes.number.isRequired,
  index_shard_count: PropTypes.number.isRequired,
  created_at: PropTypes.string.isRequired,
  creation_duration: PropTypes.number.isRequired,
  timestamp_min: PropTypes.string.isRequired,
  timestamp_max: PropTypes.string.isRequired,
  id_mappings: PropTypes.objectOf(PropTypes.objectOf(PropTypes.string)).isRequired,
  histogram_bucket_size: PropTypes.number.isRequired,
  source_histogram: PropTypes.objectOf(PropTypes.objectOf(PropTypes.number)).isRequired,
  stream_histogram: PropTypes.objectOf(PropTypes.objectOf(PropTypes.number)).isRequired,
  segments: PropTypes.arrayOf(SegmentPropType).isRequired,
  backend_id: PropTypes.string.isRequired,
  stream_names: PropTypes.arrayOf(PropTypes.string).isRequired,
  source_names: PropTypes.arrayOf(PropTypes.string).isRequired,
  node_names: PropTypes.arrayOf(PropTypes.string).isRequired,
});

export const ArchiveContextPropType = PropTypes.exact({
  path: PropTypes.string.isRequired,
  archive_id: PropTypes.string.isRequired,
  index_name: PropTypes.string.isRequired,
  restored: PropTypes.bool.isRequired,
  restored_index_name: PropTypes.string.isRequired,
  backend_id: PropTypes.string.isRequired,
});

export const BackendContextPropType = PropTypes.exact({
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
});

export const IndexTimeAndTotalStatsPropType = PropTypes.exact({
  total: PropTypes.number.isRequired,
  time_seconds: PropTypes.number.isRequired,
});

export const IndexShardRoutingPropType = PropTypes.exact({
  id: PropTypes.number.isRequired,
  state: PropTypes.string.isRequired,
  active: PropTypes.bool.isRequired,
  primary: PropTypes.bool.isRequired,
  node_id: PropTypes.string.isRequired,
  node_name: PropTypes.string.isRequired,
  node_hostname: PropTypes.string.isRequired,
  relocating_to: PropTypes.string,
});

export const IndexInfoPropType = PropTypes.exact({
  primary_shards: PropTypes.exact({
    flush: IndexTimeAndTotalStatsPropType,
    get: IndexTimeAndTotalStatsPropType,
    index: IndexTimeAndTotalStatsPropType,
    merge: IndexTimeAndTotalStatsPropType,
    refresh: IndexTimeAndTotalStatsPropType,
    search_query: IndexTimeAndTotalStatsPropType,
    search_fetch: IndexTimeAndTotalStatsPropType,
    open_search_contexts: PropTypes.number.isRequired,
    store_size_bytes: PropTypes.number.isRequired,
    segments: PropTypes.number.isRequired,
    documents: PropTypes.exact({
      count: PropTypes.number.isRequired,
      deleted: PropTypes.number.isRequired,
    }),
  }),
  all_shards: PropTypes.exact({
    flush: IndexTimeAndTotalStatsPropType,
    get: IndexTimeAndTotalStatsPropType,
    index: IndexTimeAndTotalStatsPropType,
    merge: IndexTimeAndTotalStatsPropType,
    refresh: IndexTimeAndTotalStatsPropType,
    search_query: IndexTimeAndTotalStatsPropType,
    search_fetch: IndexTimeAndTotalStatsPropType,
    open_search_contexts: PropTypes.number.isRequired,
    store_size_bytes: PropTypes.number.isRequired,
    segments: PropTypes.number.isRequired,
    documents: PropTypes.exact({
      count: PropTypes.number.isRequired,
      deleted: PropTypes.number.isRequired,
    }),
  }),
  routing: PropTypes.arrayOf(IndexShardRoutingPropType).isRequired,
  reopened: PropTypes.bool.isRequired,
});

export const IndicesPropType = PropTypes.objectOf(IndexInfoPropType);

export const AudiencePropType = PropTypes.exact({
  company: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
});

export const EnterprisePropType = PropTypes.exact({
  cluster_ids: PropTypes.arrayOf(PropTypes.string).isRequired,
  number_of_nodes: PropTypes.number.isRequired,
  require_remote_check: PropTypes.bool.isRequired,
  allowed_remote_check_failures: PropTypes.number.isRequired,
  traffic_limit: PropTypes.number.isRequired,
  traffic_check_range: PropTypes.string.isRequired,
  allowed_traffic_violations: PropTypes.number.isRequired,
  expiration_warning_range: PropTypes.string.isRequired,
});

export const LicensePropType = PropTypes.exact({
  version: PropTypes.number.isRequired,
  id: PropTypes.string.isRequired,
  issuer: PropTypes.string.isRequired,
  subject: PropTypes.string.isRequired,
  audience: AudiencePropType.isRequired,
  issue_date: PropTypes.string.isRequired,
  expiration_date: PropTypes.string.isRequired,
  not_before_date: PropTypes.string.isRequired,
  trial: PropTypes.bool.isRequired,
  enterprise: EnterprisePropType.isRequired,
  expired: PropTypes.bool.isRequired,
});

export const StatusPropType = PropTypes.exact({
  violated: PropTypes.bool.isRequired,
  expired: PropTypes.bool.isRequired,
  expiration_upcoming: PropTypes.bool.isRequired,
  expired_since: PropTypes.string.isRequired,
  expires_in: PropTypes.string.isRequired,
  trial: PropTypes.bool.isRequired,
  license: LicensePropType.isRequired,
  traffic_exceeded: PropTypes.bool.isRequired,
  cluster_not_covered: PropTypes.bool.isRequired,
  nodes_exceeded: PropTypes.bool.isRequired,
  remote_checks_failed: PropTypes.bool.isRequired,
  valid: PropTypes.bool.isRequired,
  traffic_limit_violations: PropTypes.arrayOf(PropTypes.string),
});

export const LicenseStatusPropType = PropTypes.exact({
  status: StatusPropType,
  loading: PropTypes.bool.isRequired,
  missing: PropTypes.bool.isRequired,
});
export const S3BackendSettingsPropType = PropTypes.shape({
  aws_access_key_id: PropTypes.string,
  aws_assume_role_arn: PropTypes.string,
  aws_region: PropTypes.string,
  aws_secret_access_key: PropTypes.string,
  bucket: PropTypes.string,
  endpoint_uri: PropTypes.string,
  output_path: PropTypes.string,
  spool_directory: PropTypes.string,
  type: PropTypes.string,
});
export const FileSystemBackendSettingsPropType = PropTypes.shape({
  output_path: PropTypes.string,
  type: PropTypes.string,
});

export const BackendPropType = PropTypes.exact({
  id: PropTypes.string,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  settings: PropTypes.oneOfType([FileSystemBackendSettingsPropType, S3BackendSettingsPropType]).isRequired,
});

export const BackendsPropType = PropTypes.exact({
  backends: PropTypes.arrayOf(BackendPropType).isRequired,
  pagination: PaginationPropType.isRequired,
});

export const IndexStateItemPropType = PropTypes.exact({
  documentCount: PropTypes.number.isRequired,
  storeSize: PropTypes.number.isRequired,
});

export const IndexStatesPropType = PropTypes.objectOf(IndexStateItemPropType);
