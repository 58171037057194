import { WidgetActions } from 'views/stores/WidgetStore';
import LogViewWidget from 'logview/logic/LogViewWidget';

import LogViewWidgetConfig from './logic/LogViewWidgetConfig';

export default () => {
  const widgetConfig = LogViewWidgetConfig.createDefault();
  const widget = LogViewWidget.builder()
    .newId()
    .config(widgetConfig)
    .build();

  return WidgetActions.create(widget);
};
