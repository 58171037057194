import * as React from 'react';
import { useCallback, useEffect, useMemo } from 'react';

import Input from 'components/bootstrap/Input';
import Select from 'components/common/Select';
import OptionParameter from 'enterprise/parameters/components/option/OptionParameter';
import usePluginEntities from 'views/logic/usePluginEntities';
import OptionSource from 'enterprise/parameters/components/option/OptionSource';
import { ValidationResult } from 'enterprise/parameters/components/ParameterDeclarationForm';

import OptionSourceForm from './OptionSourceForm';

import { ParameterDeclarationComponentProps } from '../ParameterTypes';

const OptionParameterForm = ({ idx, onChange, validationState, parameter: { source }, onValidate }: ParameterDeclarationComponentProps<OptionParameter>) => {
  const sourceTypes = usePluginEntities('views.parameters.option.sources');

  const options = useMemo(() => sourceTypes.map(({ type, title }) => ({ key: title, value: type })), [sourceTypes]);

  const { type } = source ?? {};

  const _onChange = useCallback((newSourceType: string) => {
    const { factory } = sourceTypes.find(({ type: typeName }) => (typeName === newSourceType));
    const newOptionSource = factory();

    return onChange('source', newOptionSource);
  }, [onChange, sourceTypes]);

  const _onSourceChange = useCallback((newOptionSource: OptionSource) => onChange('source', newOptionSource), [onChange]);

  useEffect(() => {
    const _validationState: ValidationResult = (type !== undefined && type !== '')
      ? ['success']
      : ['error', 'Please select option source'];
    onValidate(idx, { source: _validationState });

    return () => onValidate(idx, { source: ['success'] });
  }, [type, idx, onValidate]);

  return (
    <>
      <Input id={`source-${idx}`}
             name="source"
             label="Source"
             bsStyle={validationState?.source?.[0]}
             help={validationState?.source?.[1]}>
        <Select placeholder="Select value source"
                inputProps={{ 'aria-label': 'Select value source' }}
                displayKey="key"
                inputId="parameter-source-input"
                onChange={_onChange}
                value={type}
                options={options} />
      </Input>

      <OptionSourceForm idx={idx} onChange={_onSourceChange} source={source} validationState={validationState} onValidate={onValidate} />
    </>
  );
};

export default OptionParameterForm;
