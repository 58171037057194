import * as React from 'react';
import { useState } from 'react';
import styled from 'styled-components';

import ArchiveCatalogTableEntry from 'archive/components/ArchiveCatalogTableEntry';
import ArchiveCatalogExportModal from 'archive/components/ArchiveCatalogExportModal';
import ArchiveActions from 'archive/ArchiveActions';
import { DEFAULT_PAGE_SIZE, DEFAULT_QUERY, PAGE_SIZES } from 'archive/hooks/useArchivesQuery';
import { useArchivesQuery } from 'archive/hooks';
import { Icon, PaginatedList, SearchForm, OverlayTrigger, ConfirmDialog, IfPermitted, Spinner } from 'components/common';
import { Table, Button, DropdownButton, Popover } from 'components/bootstrap';
import { IndicesActions } from 'stores/indices/IndicesStore';

import { StyledCatalogWrapperComponent } from './StyledCatalogComponents';

import type { Archive } from '../types';

const StyledDropDownbutton = styled(DropdownButton)`
  margin-left: 5px;
`;

const StyledPopover = styled(Popover)`
  max-width: 500px;
`;

const ArchiveCatalog = () => {
  const [queryParams, setQueryParams] = useState(DEFAULT_QUERY);
  const [markedArchives, setMarkedArchives] = useState([]);
  const [showConfirmDelete, setShowConfirmDelete] = useState<boolean>(false);

  const {
    isLoading,
    data: {
      archives,
      archives_context: archivesContext,
      backends_context: backendsContext,
      total,
      count,
    },
    refetch,
  } = useArchivesQuery(queryParams);

  const pagination = { ...queryParams, total, count };

  const onPaginationChange = (newPage: number, pageSize: number) => {
    const { query } = pagination;

    setMarkedArchives([]);

    if (archives) {
      setQueryParams({ page: newPage, per_page: pageSize, query });
    }
  };

  const onSearch = (query: string, resetLoadingStateCb) => {
    const { per_page: perPage } = pagination;

    setQueryParams({ page: 1, per_page: perPage, query });
    resetLoadingStateCb();
  };

  const onSearchReset = () => {
    const { per_page: perPage } = pagination;
    setQueryParams({ page: 1, per_page: perPage, query: '' });
  };

  const onMarkArchive = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked, name } = event.target;

    if (checked) {
      setMarkedArchives([...markedArchives, name]);
    } else {
      setMarkedArchives(markedArchives.filter((archiveId) => archiveId !== name));
    }
  };

  const checkedMarkedArchive = (archiveId: string): boolean => {
    return markedArchives.includes(archiveId);
  };

  const markedArchiveObjects = (): Archive[] => {
    return markedArchives
      .map((archiveId) => { return archives.find((archive) => archive.id === archiveId); })
      .filter((archive?: Archive) => archive !== undefined);
  };

  const deleteArchive = (archive: Archive): void => {
    ArchiveActions.deleteArchive(archive.backend_id, archive.archive_id).then(() => {
      const archiveContext = archivesContext[archive.backend_id][archive.archive_id];
      refetch();

      if (archiveContext.restored) {
        IndicesActions.delete(archiveContext.restored_index_name);
      }
    });
  };

  const deleteSelectedArchives = (): void => {
    setShowConfirmDelete(true);
  };

  const confirmDeleteSelectedArchives = (): void => {
    setShowConfirmDelete(false);

    markedArchiveObjects().forEach((archive: Archive) => {
      deleteArchive(archive);
    });

    setMarkedArchives([]);
  };

  const cancelDeleteSelectedArchives = (): void => {
    setShowConfirmDelete(false);
  };

  const { query, page } = pagination;
  const archivesRows = archives.map((archive) => {
    return (
      <ArchiveCatalogTableEntry key={`archive-entry-${archive.id}`}
                                archive={archive}
                                archiveContext={archivesContext[archive.backend_id][archive.archive_id]}
                                backendContext={backendsContext[archive.backend_id]}
                                onMarkArchive={onMarkArchive}
                                checkedMarkedArchive={checkedMarkedArchive} />
    );
  });
  const archivesToDelete = markedArchiveObjects();
  const popover = (
    <StyledPopover id="search-query-help" className="popover-wide" title="Search Syntax Help">
      <p><strong>Available search fields</strong></p>
      <Table condensed>
        <thead>
          <tr>
            <th>Field</th>
            <th>Description</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>index</td>
            <td>Index name of an archive</td>
          </tr>
          <tr>
            <td>id</td>
            <td>Archive ID</td>
          </tr>
          <tr>
            <td>nodes</td>
            <td>Server node names for archived messages</td>
          </tr>
          <tr>
            <td>streams</td>
            <td>Stream names for archived messages</td>
          </tr>
          <tr>
            <td>sources</td>
            <td>Source names in archived messages</td>
          </tr>
          <tr>
            <td>created</td>
            <td>Archive creation date</td>
          </tr>
          <tr>
            <td>from</td>
            <td>Minimum message timestamp in archive</td>
          </tr>
          <tr>
            <td>to</td>
            <td>Maximum message timestamp in archive</td>
          </tr>
        </tbody>
      </Table>
      <p><strong>Examples</strong></p>
      <p>
        Find archives which contain messages in a date range:<br />
        <code>{'from:>=2017-03-23 to:<=2017-04-01'}</code><br />
        <code>{'from:">=2016-02-18 18:10:37.055" to:"<=2016-02-18 18:13:16.368"'}</code>
      </p>
      <p>
        Find archives which contain messages from the <var>errors</var> stream:<br />
        <code>streams:errors</code>
      </p>
      <p>
        Find archives which contain messages from the <var>errors</var> and <var>warnings</var> streams:<br />
        <code>streams:errors streams:warnings</code><br />
        <code>streams:errors,warnings</code>
      </p>
      <p>
        Find archives which contain messages from the <var>foo</var> index and the <var>errors</var> stream:<br />
        <code>index:foo streams:errors</code><br />
      </p>
    </StyledPopover>
  );

  return (
    (isLoading) ? <Spinner text="Loading archive catalog..." />
      : (
        <StyledCatalogWrapperComponent>
          <ConfirmDialog show={showConfirmDelete} onConfirm={confirmDeleteSelectedArchives} onCancel={cancelDeleteSelectedArchives} title="Are you sure?">
            <>
              <p>
                You are about to delete {archivesToDelete.length} archives, including their restored indices.
                This action cannot be undone.
              </p>
              <p>These are the archives that will be deleted:</p>
              <ul className="pill-list">
                {archivesToDelete.map((archive: Archive) => {
                  return <li key={`${archive.archive_id}`}>{archive.archive_id} ({archive.index_name})</li>;
                })}
              </ul>
              <p />
              <p>Are you sure you want to continue?</p>
            </>
          </ConfirmDialog>

          <h2>
            Archive Catalog
            <span>&nbsp;<small>{total} total ({archivesToDelete.length} marked)</small></span>
          </h2>
          <div>
            <PaginatedList totalItems={total}
                           pageSize={DEFAULT_PAGE_SIZE}
                           pageSizes={PAGE_SIZES}
                           onChange={onPaginationChange}
                           activePage={page}>
              <SearchForm onSearch={onSearch} searchBsStyle="success" onReset={onSearchReset} queryWidth={500} useLoadingState>
                <StyledDropDownbutton id="export-results-dropdown" title="Export Results">
                  <ArchiveCatalogExportModal query={query || ''} />
                </StyledDropDownbutton>
                <IfPermitted permissions="archive:delete">
                  <Button disabled={archivesToDelete.length === 0} bsStyle="danger" onClick={deleteSelectedArchives}>
                    Delete archive(s)
                  </Button>
                </IfPermitted>
                <OverlayTrigger trigger="click" rootClose placement="right" overlay={popover}>
                  <Button bsStyle="link" className="archive-search-help-button">
                    <Icon name="question-circle" fixedWidth />
                  </Button>
                </OverlayTrigger>
              </SearchForm>
              <Table condensed hover>
                <thead>
                  <tr>
                    <IfPermitted permissions="archive:delete">
                      <th>Mark to delete</th>
                    </IfPermitted>
                    <th>Index</th>
                    <th>Backend</th>
                    <th>Created</th>
                    <th>Range</th>
                    <th>Content</th>
                    <th>Streams</th>
                    <th className="restored">Restored</th>
                  </tr>
                </thead>
                {archivesRows}
              </Table>
            </PaginatedList>
          </div>
        </StyledCatalogWrapperComponent>
      )
  );
};

export default ArchiveCatalog;
