import * as React from 'react';
import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';
import { Input, Button, ButtonToolbar } from 'components/bootstrap';

import { InputProfile } from '../Types';

const defaultInputProfile = { id: '', title: '', description: '' };

type Props = {
  inputProfile: InputProfile,
  buttonToolbarClassName?: string,
  buttonText?: string,
  onCancel: () => void,
  onSubmit: (inputProfile: InputProfile | { title: string, description: string }) => void,
  InputsWrapper?: React.ComponentType<any>,
  ActionsWrapper?: React.ComponentType<any>,
};

const InputProfileForm = (props: Props) => {
  const { inputProfile, onCancel, onSubmit, buttonToolbarClassName, buttonText, InputsWrapper, ActionsWrapper } = props;
  const { title, description } = inputProfile || defaultInputProfile;
  const EffectiveInputsWrapper = InputsWrapper || React.Fragment;
  const EffectiveActionsWrapper = ActionsWrapper || React.Fragment;

  return (
    <Formik initialValues={{ title, description }} onSubmit={onSubmit}>
      {({
        values,
        handleChange,
        isSubmitting,
      }) => {
        return (
          <Form role="form">
            <EffectiveInputsWrapper>
              <Input id="title"
                     name="title"
                     label="Title"
                     type="text"
                     value={values.title}
                     onChange={handleChange}
                     help="Meaningful name used to identify this Input Profile."
                     required />
              <Input id="description"
                     name="description"
                     label="Description"
                     type="text"
                     value={values.description}
                     onChange={handleChange}
                     help="Description for this Input Profile." />
            </EffectiveInputsWrapper>
            <EffectiveActionsWrapper>
              <ButtonToolbar className={buttonToolbarClassName}>
                <Button onClick={onCancel}>
                  Cancel
                </Button>
                <Button type="submit" bsStyle="primary" disabled={isSubmitting}>
                  {isSubmitting ? 'Saving your changes...' : buttonText}
                </Button>
              </ButtonToolbar>
            </EffectiveActionsWrapper>
          </Form>
        );
      }}
    </Formik>
  );
};

InputProfileForm.propTypes = {
  inputProfile: PropTypes.object,
  buttonToolbarClassName: PropTypes.string,
  buttonText: PropTypes.string,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  InputsWrapper: PropTypes.elementType,
  ActionsWrapper: PropTypes.elementType,
};

InputProfileForm.defaultProps = {
  inputProfile: defaultInputProfile,
  buttonToolbarClassName: undefined,
  buttonText: 'Save',
  InputsWrapper: React.Fragment,
  ActionsWrapper: React.Fragment,
};

export default InputProfileForm;
