import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import ArchiveLicenseActions from 'archive/ArchiveLicenseActions';
import ArchiveLicenseStore from 'archive/ArchiveLicenseStore';
import ArchiveLicenseStatus from 'archive/components/ArchiveLicenseStatus';
import connect from 'stores/connect';
import { LicenseStatusPropType } from 'archive/propTypes';
import { Store } from 'stores/StoreTypes';

import type { LicenseStatus } from '../types';

type Props = {
  summary?: boolean,
  licenseStatus: LicenseStatus,
};

const ArchiveLicenseStatusContainer = ({ summary, licenseStatus }: Props) => {
  useEffect(() => {
    ArchiveLicenseActions.getLicenseStatus();
  }, []);

  if (licenseStatus.loading) {
    return null;
  }

  return <ArchiveLicenseStatus licenseStatus={licenseStatus} summary={summary} />;
};

ArchiveLicenseStatusContainer.propTypes = {
  summary: PropTypes.bool,
  licenseStatus: LicenseStatusPropType.isRequired,
};

ArchiveLicenseStatusContainer.defaultProps = {
  summary: false,
};

export default connect(ArchiveLicenseStatusContainer,
  { licenseStatus: ArchiveLicenseStore as Store<LicenseStatus> });
