import * as React from 'react';
import styled, { css } from 'styled-components';

import { useStore } from 'stores/connect';
import { ViewMetadataStore } from 'views/stores/ViewMetadataStore';
import Value from 'views/components/Value';
import CustomHighlighting from 'views/components/messagelist/CustomHighlighting';
import Field from 'views/components/Field';
import FieldTypeIcon from 'views/components/sidebar/fields/FieldTypeIcon';
import FieldTypeMapping from 'views/logic/fieldtypes/FieldTypeMapping';
import DecoratedValue from 'views/components/messagelist/decoration/DecoratedValue';
import FieldType from 'views/logic/fieldtypes/FieldType';

const Row = styled.div(({ theme }) => css`
  display: flex;
  border-bottom: 1px solid ${theme.colors.gray[80]};
  min-width: fit-content;
  word-break: break-word;
  overflow-wrap: break-word;

  :last-child {
    border-bottom: 0;
  }

  :hover {
    background-color: ${theme.colors.table.backgroundHover};
    color: ${theme.utils.contrastingColor(theme.colors.table.backgroundHover)};
  }
`);

const Type = styled.div`
  padding: 3px 5px;
  flex: 0.2;
  min-width: 35px;
`;

const FieldContainer = styled.div(({ theme }) => css`
  font-family: ${theme.fonts.family.body};
  padding: 3px 5px;
  flex: 1;
  min-width: 140px;
`);

const ValueContainer = styled.div(({ theme }) => css`
  padding: 3px 5px;
  flex: 4;
  font-family: ${theme.fonts.family.monospace};
  min-width: 140px;
  white-space: pre-wrap;
`);

const Selection = styled.div`
  padding: 3px 5px;
  flex: 0.1;
  min-width: 25px;
`;

const HeadRow = styled.div(({ theme }) => css`
  font-family: ${theme.fonts.family.body};
  display: flex;
  min-width: fit-content;
`);

const HeadValue = styled.div`
  padding: 3px 5px;
  flex: 4;
  min-width: 140px;
`;

type Props = {
  field: string,
  value: string,
  type: FieldTypeMapping,
  onSelect: () => void,
  selected: boolean,
  hideCheckbox: boolean,
};

type HeadRowProps = {
  onSelectAll: () => void,
  allSelected: () => boolean,
  hideCheckbox: boolean,
};

export const LogViewMessageDetailsHeadRow = ({ onSelectAll, allSelected, hideCheckbox }: HeadRowProps) => (
  <HeadRow>
    {!hideCheckbox && (
    <Selection>
      <input title="Select all" type="checkbox" onChange={onSelectAll} checked={allSelected()} />
    </Selection>
    )}
    <Type>Type</Type>
    <FieldContainer>Field</FieldContainer>
    <HeadValue>Value</HeadValue>
  </HeadRow>
);

const LogViewMessageDetailsRow = ({ field, value, type, onSelect, selected, hideCheckbox }: Props) => {
  const { activeQuery } = useStore(ViewMetadataStore);
  const fieldType = type?.type ?? FieldType.Unknown;

  return (
    <Row key={field}>
      {!hideCheckbox && (
        <Selection><input title={`${selected ? 'Remove from' : 'Add to'} table`}
                          onChange={onSelect}
                          type="checkbox"
                          checked={selected} />
        </Selection>
      )}
      <Type><FieldTypeIcon type={fieldType} /></Type>
      <FieldContainer>
        <Field name={field} queryId={activeQuery} type={fieldType} />
      </FieldContainer>
      <ValueContainer>
        <CustomHighlighting field={field} value={value}>
          <Value field={field} value={value} queryId={activeQuery} type={fieldType} render={DecoratedValue} />
        </CustomHighlighting>
      </ValueContainer>
    </Row>
  );
};

export default LogViewMessageDetailsRow;
