import styled, { css } from 'styled-components';
import type { DefaultTheme } from 'styled-components';
import {
  NavLink,
} from 'react-router-dom';

import securityContentThemeVariables from 'security-app/theme/constants';

const StyledLink = styled(NavLink)(({ theme }: { theme:DefaultTheme }) => css`
  height: 100px;
  width: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: ${theme.colors.global.navigationBackground};
  border-radius: 4px;
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.23);
  color: ${theme.colors.brand.tertiary};
  transition: all 0.33s ease-in-out;
  
  h2 {
    color: inherit;
  }
  
  &:hover {
    box-shadow: 10px 5px 5px ${securityContentThemeVariables.colors.HIGHLIGHT_COLOR_SHADOW};
    color: ${securityContentThemeVariables.colors.HIGHLIGHT_COLOR_RGB};
    text-decoration: none;
  }
  
  &.active {
    box-shadow: 5px 5px 5px ${securityContentThemeVariables.colors.HIGHLIGHT_COLOR_SHADOW};
    color: ${securityContentThemeVariables.colors.HIGHLIGHT_COLOR_RGB};
  }
  
  span {
    text-align: center;
  }
  
  svg {
    margin-bottom: 10px;
  }
`);

export default StyledLink;
