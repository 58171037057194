import * as React from 'react';

import { LinkContainer } from 'components/common/router';
import { Button, ButtonToolbar } from 'components/bootstrap';
import Routes from 'routing/Routes';

const ReportsMainNav = () => (
  <ButtonToolbar>
    <LinkContainer to={Routes.pluginRoute('REPORTS')}>
      <Button bsStyle="info">Reports</Button>
    </LinkContainer>
  </ButtonToolbar>
);

export default ReportsMainNav;
