import loadAsync from 'routing/loadAsync';

const SecurityAnomaly = loadAsync(() => import('security-app/pages/SecurityAnomalies'));
const SecurityConfiguration = loadAsync(() => import('security-app/pages/SecurityConfiguration'));
const SecurityHostActivity = loadAsync(() => import('security-app/pages/SecurityHostActivity'));
const SecurityNetworkActivity = loadAsync(() => import('security-app/pages/SecurityNetworkActivity'));
const SecurityOverview = loadAsync(() => import('security-app/pages/SecurityOverview'));
const SecurityUserActivity = loadAsync(() => import('security-app/pages/SecurityUserActivity'));
const AnomalyDetectionEdit = loadAsync(() => import('security-app/pages/AnomalyDetectionEdit'));
export {
  SecurityAnomaly,
  SecurityConfiguration,
  SecurityHostActivity,
  SecurityNetworkActivity,
  SecurityOverview,
  SecurityUserActivity,
  AnomalyDetectionEdit,
};
