import * as React from 'react';
import { SyntheticEvent } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'components/common/router';
import { Alert, Button } from 'components/bootstrap';
import { Icon } from 'components/common';
import Routes from 'routing/Routes';
import { isPermitted } from 'util/PermissionsMixin';
import type { UserJSON } from 'logic/users/User';

type Props = {
  currentUser: UserJSON,
  expiresIn: string,
  onMuteClick: (event: SyntheticEvent<HTMLButtonElement>) => void

}

const LicenseTrialNotification = ({ expiresIn, currentUser, onMuteClick }: Props) => (
  <Alert bsStyle="info" className="has-bm">
    <h4 style={{ marginBottom: 5 }}><strong>Graylog Enterprise Trial License</strong></h4>
    <p>
      Your trial license will expire in <strong>{expiresIn}</strong>.{' '}
      {isPermitted(currentUser.permissions, 'licenses:create') ? (
        <span>
          Go to the <Link to={Routes.pluginRoute('SYSTEM_LICENSES')}>Licenses page</Link> for
          more information or contact your Graylog account manager.
        </span>
      ) : (
        <span>Contact your Graylog account manager for more information.</span>
      )}
      <Button bsSize="xsmall" className="pull-right" onClick={onMuteClick}>
        <Icon name="times" /> Remind me later
      </Button>
    </p>
  </Alert>
);

LicenseTrialNotification.propTypes = {
  expiresIn: PropTypes.string.isRequired,
  currentUser: PropTypes.object.isRequired,
  onMuteClick: PropTypes.func.isRequired,
};

export default LicenseTrialNotification;
