import React, { useEffect } from 'react';

import ArchiveActions from 'archive/ArchiveActions';
import ArchiveLicenseActions from 'archive/ArchiveLicenseActions';
import ArchiveLicenseStore from 'archive/ArchiveLicenseStore';
import ArchiveCatalog from 'archive/components/ArchiveCatalog';
import ArchiveCreationSection from 'archive/components/ArchiveCreationSection';
import ArchiveSystemJobs from 'archive/components/ArchiveSystemJobs';
import type { LicenseStatus } from 'archive/types';
import ArchivePageHeaderSupport from 'archive/components/ArchivePageHeaderSupport';
import { IndicesPropType, LicenseStatusPropType } from 'archive/propTypes';
import DocsHelper from 'util/DocsHelper';
import { IndicesActions, IndicesStore } from 'stores/indices/IndicesStore';
import type { Store } from 'stores/StoreTypes';
import type { Indices } from 'stores/indices/IndicesStore';
import connect from 'stores/connect';
import { DocumentTitle, ExternalLinkButton, PageHeader, IfPermitted } from 'components/common';
import { Button, Row, Col } from 'components/bootstrap';
import Routes from 'routing/Routes';
import { LinkContainer } from 'components/common/router';

type Props = {
  indices: Indices,
  licenseStatus: LicenseStatus,
};

const ArchivePage = ({ indices, licenseStatus }: Props) => {
  useEffect(() => {
    ArchiveLicenseActions.getLicenseStatus();
    IndicesActions.listAll();
  }, []);

  return (
    <DocumentTitle title="Archives">
      <span>
        <PageHeader title="Archives">
          <span>
            The Graylog archive feature allows you to create archives from indices. The generated archives
            are simple flat files that can be moved to cheap storage and re-imported at any time.
          </span>
          <ArchivePageHeaderSupport />
          <span>
            <LinkContainer to={Routes.pluginRoute('SYSTEM_ARCHIVES')}>
              <Button bsStyle="info" className="active">Overview</Button>
            </LinkContainer>
              &nbsp;
            <IfPermitted permissions="archiveconfig:update">
              <LinkContainer to={Routes.pluginRoute('SYSTEM_ARCHIVES_CONFIGURATION')}>
                <Button bsStyle="info">Configuration</Button>
              </LinkContainer>
                &nbsp;
              <LinkContainer to={Routes.pluginRoute('SYSTEM_ARCHIVES_BACKENDS')}>
                <Button bsStyle="info">Manage Backends</Button>
              </LinkContainer>
                &nbsp;
            </IfPermitted>
          </span>
        </PageHeader>
        <ArchiveCreationSection indices={indices} licenseStatus={licenseStatus} />
        <ArchiveSystemJobs />
        <Row className="content">
          <Col md={12}>
            <div className="pull-right">
              <IfPermitted permissions="archivecatalog:rebuild">
                <Button bsStyle="info" onClick={() => { ArchiveActions.rebuildCatalog(); }}>Rebuild Catalog</Button>
                &nbsp;
              </IfPermitted>
              <ExternalLinkButton bsStyle="info" href={DocsHelper.toString('archiving')}>
                Archive documentation
              </ExternalLinkButton>
            </div>
            <ArchiveCatalog />
          </Col>
        </Row>
      </span>
    </DocumentTitle>
  );
};

ArchivePage.propTypes = {
  indices: IndicesPropType,
  licenseStatus: LicenseStatusPropType,
};

ArchivePage.defaultProps = {
  indices: {},
  licenseStatus: {
    status: undefined,
    missing: true,
    loading: true,
  },
};

export default connect(
  ArchivePage,
  {
    licenseStatus: ArchiveLicenseStore,
    indices: IndicesStore as Store<{ indices: Indices }>,
  },
  ({ indices, ...otherProps }) => ({
    indices: indices.indices,
    ...otherProps,
  }),
);
