import * as React from 'react';
import { useContext } from 'react';
import * as Immutable from 'immutable';
import { useFormikContext } from 'formik';
import styled from 'styled-components';
import { ListingWithCount, EmptyResult, PaginatedDataTable } from 'components/common';
import { Alert, Input } from 'components/bootstrap';
import ConnectionErrors from 'components/authentication/directoryServices/BackendWizard/ConnectionErrors';
import type { WizardFormValues } from 'components/authentication/directoryServices/BackendWizard/BackendWizardContext';

import { ConfigFormValues, OidcSubmitPayload } from 'authentication/components/oidc/config/types';

import OktaLoadMatchingGroupsButton from './OktaLoadMatchingGroupsButton';
import OktaMatchingGroupsContext from './OktaMatchingGroupsContext';

const TABLE_HEADER = ['Selected', 'Title', 'Name', 'Members'];

const ListWrapper = styled.div`
  position: relative;
`;

const TableRow = styled.tr<{ clickable: boolean }>(({ clickable }) => `
  cursor: ${clickable ? 'pointer' : 'initial'};
`);

const Checkbox = styled(Input)`
  && {
    min-height: auto;
    padding-top: 0;
  }
`;

const _tableRow = (group, selectionType, selectedGroupsIds, onGroupSelect) => {
  const { id, title, name, membersTotal, membersExcerpt } = group;
  const isSelected = selectionType === 'all' || selectedGroupsIds.includes(id);
  const clickable = selectionType !== 'all';

  return (
    <TableRow onClick={() => clickable && onGroupSelect(id)} clickable={clickable}>
      <td>
        <Checkbox type="checkbox"
                  id={`select-group-${id}`}
                  onChange={() => {}}
                  checked={isSelected}
                  disabled={selectionType === 'all'}
                  formGroupClassName="" />
      </td>
      <td className="limited">{title}</td>
      <td className="limited">{name}</td>
      <td className="limited">
        <ListingWithCount count={membersTotal} listing={membersExcerpt.join(', ')} />
      </td>
    </TableRow>
  );
};

type Props = {
  onGroupSelect: any,
  prepareSubmitPayload: (fromValues: ConfigFormValues | null | undefined) => OidcSubmitPayload,
  disabled: boolean,
};

const OktaMatchingGroupsOverview = ({ prepareSubmitPayload, onGroupSelect, disabled }: Props) => {
  const { result: loadedGroupsResult, finishedLoading } = useContext(OktaMatchingGroupsContext);
  const loadedGroups = loadedGroupsResult?.list || Immutable.List();
  const { values: { teamSelectionType, teamSelection } } = useFormikContext<WizardFormValues>();

  return (
    <Input id="matchingGroups"
           label="Matching Groups"
           labelClassName="col-sm-3"
           wrapperClassName="col-sm-9">
      <>
        <OktaLoadMatchingGroupsButton prepareSubmitPayload={prepareSubmitPayload}
                                      disabled={disabled} />
        {(finishedLoading && loadedGroupsResult) && (
          <>
            <hr />
            {loadedGroupsResult.success && (
              <ListWrapper>
                {loadedGroupsResult?.list?.size >= 1 && (
                  <PaginatedDataTable id="matching-groups-list"
                                      className="table-hover"
                                      headers={TABLE_HEADER}
                                      headerCellFormatter={(header) => <th>{header}</th>}
                                      filterBy="title"
                                      filterKeys={['title']}
                                      noDataText={<EmptyResult>Filter does not match any groups.</EmptyResult>}
                                      rows={loadedGroups.toJS()}
                                      dataRowFormatter={(group) => _tableRow(group, teamSelectionType, teamSelection, onGroupSelect)} />
                )}
              </ListWrapper>
            )}
            {loadedGroupsResult?.errors?.size === 0 && loadedGroupsResult?.list?.size === 0 && (
              <Alert bsStyle="warning">
                <b>{loadedGroupsResult.message}</b>
              </Alert>
            )}
            {loadedGroupsResult?.errors?.size >= 1 && <ConnectionErrors errors={loadedGroupsResult.errors.toJS()} message={loadedGroupsResult.message} />}
          </>
        )}
      </>
    </Input>
  );
};

export default OktaMatchingGroupsOverview;
